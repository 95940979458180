import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import HorizontalSeparator from "../separator/horizontal.separator.component";

const CollapsibleSidebarMenu = forwardRef(({ attributes }, ref) => {

    const [selectedKey, setSelectedKey] = useState("");

    const componentObserver = useRef()

    useImperativeHandle(attributes?.observer, () => ({
        getSelectedMenuItem() {
            const path = window.location.hash.split("/").pop().toLowerCase();
            let selectedItem;

            const itemWithChildren = attributes.items?.find(item => item?.items?.some(item => item?.url === path));
            if (itemWithChildren) {
                selectedItem = itemWithChildren.items?.find(item => item?.url === path);
            } else {
                selectedItem = attributes.items?.find(item => item?.url === path);
            }

            return selectedItem;
        }
    }))

    const selectListItem = useCallback((selectedItem) => {
        const selectedStyleItems = attributes.style?.items?.selected.split(" ");
        const selectedTextStyleItems = attributes.style?.items?.title.split(" ");

        let listContainer = (attributes?.componentObserver?.current) ? attributes?.componentObserver?.current : componentObserver.current;
        const selectedItems = listContainer?.querySelectorAll("div[aria-controls=\"selector\"]");
        if (selectedItems) {
            [...selectedItems]?.forEach(item => {
                selectedStyleItems.forEach(style => {
                    item.classList.remove(style);
                });
            });
        }

        if (selectedItem) {
            selectedStyleItems.forEach(style => selectedItem?.querySelector("div[aria-controls=\"selector\"]")?.classList.add(style));
            selectedTextStyleItems.forEach(style => selectedItem?.querySelector("h1")?.classList.add(style));
        }
    }, [attributes])

    const widgetObserver = useCallback(async () => {
        const search = attributes?.items.filter(item => Object.keys(item).length > 0).find(result => window.location.hash.split("/").includes(result.url));

        const path = window.location.hash.split("/").pop().toLowerCase();
        let selectedListItem;
        const itemWithChildren = attributes.items?.find(item => item?.items?.some(item => item?.url === path));
        if (itemWithChildren) {
            selectedListItem = itemWithChildren.items?.find(item => item?.url === path);
        } else {
            selectedListItem = attributes.items?.find(item => item?.url === path);
        }


        // select list item
        selectListItem(componentObserver.current?.querySelector("div[aria-owns=\"" + (selectedListItem)?.url + "\"]"));

        return selectedListItem;
    }, [attributes, selectListItem])

    useEffect(() => {
        (async () => {
            if (attributes.observer) {
                attributes.observer.current = await widgetObserver;
                widgetObserver();
            }

            const selector = componentObserver?.current?.querySelector("div[aria-controls=\"selector\"]");
            if (selector) {
                selector.classList.add("max-width-" + componentObserver?.current.clientWidth + "px");
                selector.classList.add("width-" + componentObserver?.current.clientWidth + "px");
            }
        })();
    }, [attributes])

    const handleOnClick = (e, args) => {
        selectListItem(e.currentTarget);
        attributes?.trigger(args);
    }

    const handleItemClick = (e, itemAttributes) => {
        if (!itemAttributes?.items) {
            itemAttributes?.onClick(e, itemAttributes);
        }
    }

    const getCollapsibleItem = (itemAttributes) => {
        return (
            <div className={"w-100 d-flex justify-content-start align-items-start"}>
                <div className={"w-100"}>
                    {
                        (() => {
                            return <div
                                aria-owns={itemAttributes.url}
                                aria-pressed={itemAttributes.url}
                                aria-rowindex={itemAttributes.key}
                                onClick={(e) => handleItemClick(e, itemAttributes)}
                                className={"position-relative " + ((!itemAttributes?.items) ? itemAttributes?.style?.items?.hover : "") + " height-36px p-1 margin-top-1px margin-bottom-1px bg-light-"}>

                                <div aria-controls="selector"
                                    className={"position-absolute top-0px start--0px bottom-0px end--1px rounded-3 " + ((itemAttributes.selected) ? itemAttributes.style.items.selected : "") + ((!itemAttributes?.items) ? "cursor-pointer" : "")}></div>

                                <div className={"position-absolute top-0px start-0px bottom-0px end-0px w-100 d-flex justify-content-start align-items-center " + ((!itemAttributes?.items) ? "cursor-pointer" : "")}>
                                    <div className={"d-flex me-0 ms-2 " + itemAttributes?.style?.leading + ((itemAttributes?.leading && itemAttributes?.showIcon) ? " show" : " hide")}>
                                        {itemAttributes?.leading}
                                    </div>
                                    <h1 className={"ms-3 " + ((itemAttributes?.style) ? itemAttributes?.style?.title + " mb-0" : "text-25px text-bold mb-3")}>{itemAttributes?.text}</h1>
                                </div>
                            </div>
                        })()
                    }
                    <div className={"w-100 " + (itemAttributes?.items ? "show" : "hide")}>
                        <div className={"d-flex w-100"}>
                            <div className={"d-flex margin-end-12px " + itemAttributes?.style?.leading + ((itemAttributes?.leading) ? " show" : " hide")}>
                            </div>
                            {
                                (() => {
                                    return (
                                        <CollapsibleSidebarMenu attributes={{
                                            style: itemAttributes?.style,
                                            items: itemAttributes?.items,
                                            componentObserver: itemAttributes.componentObserver,
                                            trigger: itemAttributes?.trigger,
                                            showIcon: itemAttributes?.showChildIcon
                                        }} />
                                    )
                                })()
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div ref={componentObserver} className={"w-100 mb-0 p-0"}>
            <div className={attributes?.style?.g + " position-relative"}>
                {
                    attributes?.items?.map((itemAttributes, i) => {
                        if (Object.keys(itemAttributes).length > 0) {
                            itemAttributes["style"] = attributes?.style;
                            itemAttributes["key"] = itemAttributes.title.toLowerCase().split(" ").join("-");
                            itemAttributes["selected-key"] = selectedKey;
                            itemAttributes["componentObserver"] = componentObserver;
                            itemAttributes["onClick"] = handleOnClick;
                            itemAttributes["trigger"] = attributes?.trigger;
                            itemAttributes["showIcon"] = attributes?.showIcon;
                            return (
                                <div key={i} className={"w-100 text-decoration-none "} to={itemAttributes.href}>
                                    {
                                        getCollapsibleItem(itemAttributes)
                                    }
                                </div>
                            )
                        } else {
                            return (
                                <div key={i} className={"position-relative height-2px mt-2 mb-2"}>
                                    <HorizontalSeparator />
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    )

})

export { CollapsibleSidebarMenu };