import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import useAppConfig from "../../hooks/app.config.hook";

const BannerUploader = forwardRef(({ attributes, model, isImageAvailable }, ref) => {

    // const isInitialized = useRef(0);
    const uploaderComponentObserver = useRef();
    const appConfig = useAppConfig();

    const [imageSize, setImageSize] = useState();

    useImperativeHandle(attributes?.observer, () => ({
        loadImage(args) {
            const preview = uploaderComponentObserver?.current?.querySelector("i[aria-controls=\"image-preview-controls\"]");
            if (args && attributes?.url !== "") {
                const loadImageUrl = (attributes?.config?.downloadUrl + "/" + args[attributes?.url]) + "?size=" + attributes?.config?.imageSize;
                preview.setAttribute("style", "background-image: url('" + loadImageUrl + "')");
            } else {
                preview.setAttribute("style", "background-image: url('')");
            }
        }
    }))

    useEffect(() => {
        const size = uploaderComponentObserver.current.clientWidth;
        setImageSize(size);
    }, [attributes])

    const loadUploadedImage = async (args) => {
        const size = attributes?.config?.imageSize;

        const loader = uploaderComponentObserver?.current?.querySelector("div[aria-controls=\"loader-controls\"]");
        const fileUploader = uploaderComponentObserver?.current?.querySelector("input[type=\"file\"]");
        const hiddenField = uploaderComponentObserver?.current?.querySelector("input[type=\"hidden\"]");
        const preview = uploaderComponentObserver?.current?.querySelector("i[aria-controls=\"image-preview-controls\"]");

        if (args) {
            const loadImageUrl = (attributes?.config?.downloadUrl + "/" + args[attributes?.config?.url]) + "?size=" + size;
            hiddenField.value = args[attributes?.config?.key];

            if (attributes?.onUploadCompleted)
                attributes?.onUploadCompleted(args);

            setTimeout(() => {
                preview.setAttribute("style", "background-image: url('" + loadImageUrl + "')");

                // hide loader
                loader.classList.add("hide");
            }, 1000);

            fileUploader.value = "";
        }
    }

    const handleBrowseFile = (e) => {
        const fileInput = uploaderComponentObserver.current.querySelector("input[type=\"file\"]");
        fileInput.click();
    }

    const handleFileSelectionChange = (e) => {
        const files = e.target.files;

        // Create a new worker thread
        if (files.length > 0) {
            const loader = uploaderComponentObserver?.current?.querySelector("div[aria-controls=\"loader-controls\"]");
            loader.classList.remove("hide");

            const worker = new Worker(new URL('./uploader.worker', import.meta.url));
            worker.onmessage = ({ data }) => {
                if (data.isUploading) {
                    handleImageUploading(data);
                }

                if (
                    ('isUploadCompleted' in data && data.isUploadCompleted === true) ||
                    ('isUploadCancelled' in data && data.isUploadCancelled === true)
                ) {
                    if (data.response.statusCode === 200) {
                        // console.log(data.response.data);
                        const uploadResponse = data.response.data;
                        loadUploadedImage(uploadResponse);
                    }

                    // clear file
                    e.target.value = "";
                }
            };

            worker.postMessage({
                url: attributes?.config?.uploadUrl,
                file: files[0],
                ownerId: appConfig?.sessionAuthDetails?.userId
            });
        }
    }

    const handleImageUploading = (args) => {
        //
    }

    return (
        <div ref={uploaderComponentObserver} id={attributes?.name} className={"text-dark text-decoration-none cursor-pointer"}>
            <div onClick={handleBrowseFile} className={"position-relative d-flex justify-content-center align-items-center mx-auto " + attributes?.size + " " + attributes?.style?.g}>
                <input
                    name={attributes?.id}
                    id={"" + attributes?.id}
                    aria-owns={attributes?.name}
                    aria-controls={attributes?.formElement}
                    aria-required={attributes?.validate}
                    type={"hidden"} />

                <div className={"position-absolute top-0px left-0px width-1px height-1px overflow-hidden"}>
                    <input type="file" accept=".png, .jpg, .webp, .mp4"
                        onChange={handleFileSelectionChange} />
                </div>

                {/* placeholder image */}
                {
                    (() => {
                        return (
                            <div className={"d-flex justify-content-center align-items-center "}>
                                <div className={"" + attributes?.style?.textColor}>
                                    <div className={"w-100 mb-2 mx-auto d-flex justify-content-center " + attributes?.style?.placeholder}>{attributes?.leading}</div>
                                </div>
                            </div>
                        )
                    })()
                }

                {/* preview uploaded image */}
                {
                    ((size) => {
                        return (
                            <div className={""}>
                                <i aria-controls="image-preview-controls" className={"bg-center image-cover d-flex position-absolute top-0px start-0px bottom-0px end-0px " + ((attributes?.style?.image) ? attributes?.style?.image : "rounded-2 ")}></i>
                            </div>
                        )
                    })(imageSize)
                }

                {/* loader */}
                <div aria-controls="loader-controls" className={"hide transparent-bg-white-theme position-absolute top-0px start-0px bottom-0px end-0px z-index-10 bottom-0px mx-auto d-flex justify-content-center align-items-center"}>
                    <div className={"transparent-bg-white-theme- shadow-lg- mx-auto min-height-50px p-4"}>
                        <div className="spinner-border border-2px text-blue-theme icon-70px" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
})
export default BannerUploader;