import {  useHttps } from "../../../../shared/utilities";

const ProductDealService = () => {
    const request = useHttps();
    const controller = "/deals";

    return {
        uploadUrl: request?.BASE_URL + controller + "/upload-image",
        downloadUrl: request?.BASE_URL + controller + "/download-image",

        addProductBrand: async (data) => await request.post(controller + "/add-product-brand", data),
        addProductVariation: async (data) => await request.post(controller + "/add-product-variation", data),
        addProductColor: async (data) => await request.post(controller + "/add-product-color", data),
        addProductType: async (data) => await request.post(controller + "/add-product-type", data),
        getAll: async (data) => await request.post(controller + "/get-all", data),
        getProductAttributes: async (data) => await request.post(controller + "/get-product-attributes", data),
        get: async (id) => await request.post(controller + "/get-all", id),
        getProductImages: async (id) => await request.post(controller + "/get-product-media", id),

        getProductMarkets: async (id) => await request.post(controller + "/get-product-markets", id),

        getProductInventoryStockItem: async (id) => await request.post(controller + "/get-product-inventory-stock-item", id),
        search: async (data) => await request.post(controller + "/get-all", data),
        save: async (data) => await request.post(controller + "/save", data),
        saveBulk: async (data) => await request.post(controller + "/save-bulk", data),
        update: async (data) => await request.post(controller + "/update", data),
        remove: async (data) => await request.post(controller + "/delete", data),
        removeProductBrand: async (data) => await request.post(controller + "/delete-product-brand", data),
        removeProductVariation: async (data) => await request.post(controller + "/delete-product-variation", data),
        removeProductColor: async (data) => await request.post(controller + "/delete-product-color", data),
        removeProductImage: async (data) => await request.post(controller + "/delete-product-media", data),
        removeProductType: async (data) => await request.post(controller + "/delete-product-type", data),
        publish: async (data) => await request.post(controller + "/publish", data),
        publishAll: async (data) => await request.post(controller + "/publish-all", data),
        unpublish: async (data) => await request.post(controller + "/unpublish", data),
        unpublishAll: async (data) => await request.post(controller + "/unpublish-all", data),
    }
}

export { ProductDealService }