import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { ALIGNMENTTYPE, COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, MENUITEMTYPE, PAGEDIRECTION, SLIDEDIRECTION, SORTENUM, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import { PharmacyProductService } from './pharmacy.product.service';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import MenuList from '../../../../../shared/components/menu/menu.list.component';
import { ProductImageService } from '../../../configuration.settings/settings/product.images/product.image.service';
import CreateOrEditPharmacyProductView from '../createoredit.pharmacy.product/createoredit.pharmacy.product.view';
import { CalloutWindow } from '../../../../../shared/components/callout/callout.window.component';
import { useStackablePanel } from '../../../../../shared/components/stackable.panel/stackable.panel.component';
import { Avatar } from '../../../../../shared/components/avatar';
import useAppConfig from '../../../../../shared/hooks/app.config.hook';
import { PharmacyService } from '../pharmacy.service';
import PharmacyCustomerOrderView from '../pharmacy.customer.order/pharmacy.customers.orders.view';
import PharmacyProductCategoriesView from '../pharmacy.product.categories/pharmacy.product.categories.view';
import PharmacyProductVariationsView from '../pharmacy.product.variations/pharmacy.product.variations.view';
import PharmacyProductColorsView from '../pharmacy.product.colors/pharmacy.product.colors.view';
import PharmacyProductTypesView from '../pharmacy.product.types/pharmacy.product.types.view';
import PharmacyCustomersTransactionsView from '../pharmacy.customer.transaction/pharmacy.customers.transactions.view';
import PharmacyCustomersOrdersView from '../pharmacy.customer.order/pharmacy.customers.orders.view';
import global from '../../../../../shared/helpers/global.variable.helper';
import { useInfiniteLoader } from '../../../../../shared/components/loader/infinite.loader.component';

const usePharmacyProductsHook = (attributes) => {

    const appConfig = useAppConfig();

    // theme
    const icons = useIconsThemes().rounded;
    const themes = usePrimaryUIThemes();

    // state objects
    const [products, setProducts] = useState([]);
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [selectedPharmacy, setSelectedPharmacy] = useState({});

    const actionButtonsMenuItems = {
        customerOrderMenuItem: "pharmacy-customer-order-menuitem",
        transactionsMenuItem: "pharmacy-product-transactions-menuitem",
        categoriesMenuItem: "pharmacy-product-categories-menuitem",
        variationsMenuItem: "pharmacy-product-variations-menuitem",
        typeMenuItem: "pharmacy-product-types-menuitem",
        colorMenuItem: "pharmacy-product-colors-menuitem",
        browsePharmacyPageMenuItem: "browse-pharmacy-products-page-menuitem",
        managePharmacyAccountMenuItem: "manage-pharmacy-account-menuitem"
    };

    // 
    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No product selected!",
            message: "Please select an product from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New product",
            successMessage: {
                title: "Product added successfully!",
                message: "Product was successfully added."
            }
        },

        handleEdit: {
            dialogTitle: "Edit product",
            alertMessage: {
                title: "Multiple products selected!",
                message: "Oops!, you selected multiple products. Please select a product from the list to continue"
            },
            successMessage: {
                title: "Product updated successfully!",
                message: "Product was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Delete product",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these products?" : "this product?")
                }
            },
            successMessage: {
                title: "Product deleted successfully!",
                message: "Product was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these products?" : "this product?")
                }
            },
            successMessage: {
                title: "Product published successfully!",
                message: "Product was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these products?" : "this product?")
                }
            },
            successMessage: {
                title: "Product published successfully!",
                message: "Product was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these products?" : "this product?")
                }
            },
            successMessage: {
                title: "Product archived successfully!",
                message: "Product was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these products?" : "this product?")
                }
            },
            successMessage: {
                title: "Product restored successfully!",
                message: "Product was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available products, please wait..."
        },


        manageCategory: {
            dialogTitle: "Product categories",
            successMessage: {
                title: "Product added successfully!",
                message: "Product was successfully added."
            }
        },


        manageVariations: {
            dialogTitle: "Product variations",
            successMessage: {
                title: "Product added successfully!",
                message: "Product was successfully added."
            }
        },


        manageTypes: {
            dialogTitle: "Product types",
            successMessage: {
                title: "Product added successfully!",
                message: "Product was successfully added."
            }
        },


        manageColors: {
            dialogTitle: "Product colors",
            successMessage: {
                title: "Product added successfully!",
                message: "Product was successfully added."
            }
        },
    };

    // observers
    const componentObserver = useRef();
    const pharmacyProductDatagridListObserver = useRef([]);
    const pharmacyLogoObserver = useRef();
    const createOrEditPharmacyProductCalloutWindowObserver = useRef();
    const pharmacyCustomersOrdersCalloutWindowObserver = useRef();
    const pharmacyCustomersTransactionsCalloutWindowObserver = useRef();
    const pharmacyProductCategoriesCalloutWindowObserver = useRef();
    const pharmacyProductVariationsCalloutWindowObserver = useRef();
    const pharmacyProductColorsCalloutWindowObserver = useRef();
    const pharmacyProductTypesCalloutWindowObserver = useRef();
    const totalRowsCount = useRef(0);
    const lastRowsCount = useRef(0);

    // components
    const stackablePanelComponentName = "pharmacy-products-stackable-panel";
    const pharmacyProductDatagridName = "products-datagrid";

    const stackablePanelComponent = useStackablePanel({
        name: stackablePanelComponentName
    });

    const datagrid = useDataGrid({
        name: pharmacyProductDatagridName
    });

    const toolbarButtons = {
        addProductButtonName: "add-pharmacy-product-button",
        importProductButtonName: "import-pharmacy-product-button",
        editProductButtonName: "edit-pharmacy-product-button",
        deleteProductButtonName: "delete-pharmacy-product-button",
        reloadProductButtonName: "reload-pharmacy-product-button",
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    const toolbarName = "products-right-pane-toolbar";
    const toolbar = useToolbar({
        name: toolbarName
    });

    // dialogs
    const createOrEditPharmacyProductCalloutWindowName = "createoredit-pharmacy-product-modal";
    const pharmacyCustomersOrdersCalloutWindowName = "pharmacy-customer-orders-dialog-modal";
    const pharmacyCustomersTransactionsCalloutWindowName = "pharmacy-customer-transactions-dialog-modal";
    const pharmacyProductCategoriesCalloutWindowName = "pharmacy-product-categories-modal";
    const pharmacyProductVariationsCalloutWindowName = "pharmacy-product-variations-modal";
    const pharmacyProductColorsCalloutWindowName = "pharmacy-product-color-modal";
    const pharmacyProductTypesCalloutWindowName = "pharmacy-product-types-modal";
    // 
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();
    const infiniteLoader = useInfiniteLoader();

    // services
    const pharmacyService = PharmacyService();
    const pharmacyProductService = PharmacyProductService();

    const getPharmacyProducts = useCallback(async (request) => {
        infiniteLoader.show();
        datagrid?.showLoader(messages?.loader?.fetchingMessage);
        await pharmacyProductService.getAll(request)
            .then(response => {
                datagrid?.hideLoader();
                infiniteLoader.hide();

                // showing rows
                if (request.direction === PAGEDIRECTION.PREVIOUS) {
                    totalRowsCount.current -= lastRowsCount.current;
                } else if (request.direction === PAGEDIRECTION.NEXT) {
                    totalRowsCount.current += response?.data?.list?.length;
                } else {
                    totalRowsCount.current = response?.data?.list?.length;
                }

                // populate datagrid 
                setProducts(response?.data?.list);
                lastRowsCount.current = response?.data?.list?.length;

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

            })
            .catch(error => {
                datagrid?.hideLoader();
                infiniteLoader.hide();
            });

    }, []);

    // observers
    const widgetObserver = useCallback(async (args) => {
        stackablePanelComponent.setPanel(0);

        // close any opened window
        createOrEditPharmacyProductCalloutWindowObserver.current.close();
        pharmacyProductCategoriesCalloutWindowObserver.current.close();
        pharmacyProductColorsCalloutWindowObserver.current.close();
        pharmacyProductTypesCalloutWindowObserver.current.close();
        pharmacyProductVariationsCalloutWindowObserver.current.close();

        // load image
        pharmacyLogoObserver.current.loadImage(args?.data);

        if (args?.data !== null) {

            // selected pharmacy
            setSelectedPharmacy(args?.data);

            // get products in pharmacy
            await getPharmacyProducts({
                pharmacyId: args?.data?.pharmacyId,
                queryString: queryString,
                pageNumber: pageNumber,
                rowsPerPage: rowsPerPage,
                sort: {}
            });

        } else {
            // form.clear();
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;
        })();
    }, []);

    const handleCreatePharmacyProduct = (e) => {
        createOrEditPharmacyProductCalloutWindowObserver.current.show({
            title: messages?.handleCreate?.dialogTitle,
            data: {
                pharmacyId: selectedPharmacy?.pharmacyId
            },
            isNew: true
        });
    }

    const handleEditPharmacyProduct = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show(messages?.handleEdit?.alertMessage);
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedProduct = products.find(x => x.productId === datagrid.getSelectedRowKeyValue());
                setSelectedProduct(selectedProduct);

                // listen to dialog for changes
                createOrEditPharmacyProductCalloutWindowObserver.current.show({
                    title: messages?.handleEdit?.dialogTitle,
                    data: selectedProduct,
                    isNew: false,
                    callback: () => {
                        getPharmacyProducts({
                            pharmacyId: selectedPharmacy?.pharmacyId,
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show(messages?.noRecordSelectedErrorMessage);
            }
        }
    }

    const handleBrowseProduct = (e) => {
        const selectedProduct = products.find(product => product.marketProductId === datagrid.getSelectedRowKeyValue());
        setSelectedProduct(selectedProduct);
        handleDatagridRowDoubleClick({ selectedRow: selectedProduct });
    }

    const handleDeleteProduct = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these products?" : "this product?"),
                onConfirmed: (e) => {
                    infiniteLoader.show();

                    pharmacyProductService.remove(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getPharmacyProducts({
                            pharmacyId: selectedPharmacy?.pharmacyId,
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        infiniteLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No product selected!",
                message: "Please select a product from the list to continue"
            });
        }
    }

    const handleSearchRecords = async (e, args) => {
        await getPharmacyProducts({
            pharmacyId: selectedPharmacy?.pharmacyId,
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getPharmacyProducts({
                pharmacyId: selectedPharmacy?.pharmacyId,
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        getPharmacyProducts({
            pharmacyId: selectedPharmacy?.pharmacyId,
            queryString: queryString,
            pageNumber: pageNumber + 1,
            rowsPerPage: rowsPerPage
        });
    }

    const handleRowsPerPageChange = (e, args) => {
        if (pageNumber < totalPages)
            getPharmacyProducts({
                pharmacyId: selectedPharmacy?.pharmacyId,
                queryString: queryString,
                pageNumber: pageNumber,
                rowsPerPage: args?.value
            });
    }

    const handlePageNumberChange = (e, args) => {
        getPharmacyProducts({
            pharmacyId: selectedPharmacy?.pharmacyId,
            queryString: queryString,
            pageNumber: args?.value,
            rowsPerPage: rowsPerPage
        });
    }

    const handleFirstPage = (e, args) => {
        if (pageNumber > 1)
            getPharmacyProducts({
                pharmacyId: selectedPharmacy?.pharmacyId,
                queryString: queryString,
                pageNumber: 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleProductToolbarOptionsMenuItemClick = (args) => {
        switch (args.name) {
            case actionButtonsMenuItems?.managePharmacyAccountMenuItem:
                // loadPharmacyWithProducts();
                break;

            case actionButtonsMenuItems?.browsePharmacyPageMenuItem:
                // loadPharmacyWithProducts();
                break;

            case actionButtonsMenuItems?.typeMenuItem:
                pharmacyProductTypesCalloutWindowObserver.current.show({
                    title: messages?.manageTypes?.dialogTitle,
                    data: {
                        pharmacyId: selectedPharmacy?.pharmacyId
                    },
                    isNew: true
                });
                break;

            case actionButtonsMenuItems?.colorMenuItem:
                pharmacyProductColorsCalloutWindowObserver.current.show({
                    title: messages?.manageColors?.dialogTitle,
                    data: {
                        pharmacyId: selectedPharmacy?.pharmacyId
                    },
                    isNew: true
                });
                break;

            case actionButtonsMenuItems?.variationsMenuItem:
                pharmacyProductVariationsCalloutWindowObserver.current.show({
                    title: messages?.manageVariations?.dialogTitle,
                    data: {
                        pharmacyId: selectedPharmacy?.pharmacyId
                    },
                    isNew: true
                });
                break;

            case actionButtonsMenuItems?.categoriesMenuItem:
                pharmacyProductCategoriesCalloutWindowObserver.current.show({
                    title: messages?.manageCategory?.dialogTitle,
                    data: {
                        pharmacyId: selectedPharmacy?.pharmacyId
                    },
                    isNew: true
                });
                break;

            case actionButtonsMenuItems?.transactionsMenuItem:
                pharmacyCustomersTransactionsCalloutWindowObserver.current.show({
                    title: messages?.handleCreate?.dialogTitle,
                    data: {
                        pharmacyId: selectedPharmacy?.pharmacyId
                    },
                    isNew: true
                });
                break;

            case actionButtonsMenuItems.customerOrderMenuItem:
            default:
                pharmacyCustomersOrdersCalloutWindowObserver.current.show({
                    title: messages?.handleCreate?.dialogTitle,
                    data: {
                        pharmacyId: selectedPharmacy?.pharmacyId
                    },
                    isNew: true
                });
                break;
        }
    }

    const handleDatagridRowDoubleClick = handleEditPharmacyProduct

    const handleSearchFieldChange = async (e) => {
        getPharmacyProducts(e.value);
    }

    const handleReloadMarketProducts = (e) => {
        getPharmacyProducts({
            pharmacyId: selectedPharmacy?.pharmacyId,
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    return {

        stackablePanelAttributes: {
            name: stackablePanelComponentName,
            items: [
                {
                    // products
                    widget: (() => {
                        return (
                            <div className={"position-absolute top-0px start-0px bottom-0px w-100 pt-0 pb-5 border-solid border-top-0px border-bottom-0px border-start-0px border-end-0px"}>
                                {/* <div className={"position-absolute top-0px end-0px bottom-0px"}> */}
                                {/* create or edit product */}
                                <CalloutWindow attributes={{
                                    title: messages?.handleCreate?.dialogTitle,
                                    name: createOrEditPharmacyProductCalloutWindowName,
                                    data: selectedProduct,
                                    observer: createOrEditPharmacyProductCalloutWindowObserver,
                                    widget: CreateOrEditPharmacyProductView,
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    style: {
                                        window: "max-width-510px"
                                    },
                                    buttons: [
                                        {
                                            text: "Save & continue",
                                            style: themes?.button?.primary,
                                            onClick: (e, winArgs) => {
                                                winArgs.submit(e, args => {
                                                    infiniteLoader.hide();
                                                    getPharmacyProducts({
                                                        pharmacyId: selectedPharmacy?.pharmacyId,
                                                        queryString: queryString,
                                                        pageNumber: pageNumber,
                                                        rowsPerPage: rowsPerPage
                                                    });
                                                    setSelectedProduct({});
                                                    createOrEditPharmacyProductCalloutWindowObserver.current.close();
                                                });
                                            }
                                        },
                                        {
                                            text: "Cancel",
                                            style: themes?.button?.secondary,
                                            onClick: (e, winArgs) => winArgs.close(e)
                                        }
                                    ]
                                }} />

                                {/* manage pharmacy's customers orders */}
                                <CalloutWindow attributes={{
                                    title: messages?.handleCreate?.dialogTitle,
                                    name: pharmacyCustomersOrdersCalloutWindowName,
                                    data: selectedProduct,
                                    observer: pharmacyCustomersOrdersCalloutWindowObserver,
                                    widget: PharmacyCustomersOrdersView,
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    style: {
                                        window: "max-width-710px"
                                    }
                                }} />

                                {/* manage pharmacy's customers transactions */}
                                <CalloutWindow attributes={{
                                    title: messages?.handleCreate?.dialogTitle,
                                    name: pharmacyCustomersTransactionsCalloutWindowName,
                                    data: selectedProduct,
                                    observer: pharmacyCustomersTransactionsCalloutWindowObserver,
                                    widget: PharmacyCustomersTransactionsView,
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    style: {
                                        window: "max-width-710px"
                                    }
                                }} />

                                {/* manage pharmacy's categories */}
                                <CalloutWindow attributes={{
                                    title: messages?.manageCategory?.dialogTitle,
                                    name: pharmacyProductCategoriesCalloutWindowName,
                                    data: selectedProduct,
                                    observer: pharmacyProductCategoriesCalloutWindowObserver,
                                    widget: PharmacyProductCategoriesView,
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    dockType: DOCKTYPE.FILL,
                                    style: {
                                        window: "max-width-510px"
                                    }
                                }} />

                                {/* manage pharmacy's weight/size/variation */}
                                <CalloutWindow attributes={{
                                    title: messages?.manageVariations?.dialogTitle,
                                    name: pharmacyProductVariationsCalloutWindowName,
                                    data: selectedProduct,
                                    observer: pharmacyProductVariationsCalloutWindowObserver,
                                    widget: PharmacyProductVariationsView,
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    style: {
                                        window: "max-width-510px"
                                    }
                                }} />

                                {/* manage pharmacy's type */}
                                <CalloutWindow attributes={{
                                    title: messages?.manageTypes?.dialogTitle,
                                    name: pharmacyProductTypesCalloutWindowName,
                                    data: selectedProduct,
                                    observer: pharmacyProductTypesCalloutWindowObserver,
                                    widget: PharmacyProductTypesView,
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    style: {
                                        window: "max-width-510px"
                                    }
                                }} />

                                {/* manage pharmacy's color */}
                                <CalloutWindow attributes={{
                                    title: messages?.manageColors?.dialogTitle,
                                    name: pharmacyProductColorsCalloutWindowName,
                                    data: selectedProduct,
                                    observer: pharmacyProductColorsCalloutWindowObserver,
                                    widget: PharmacyProductColorsView,
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    style: {
                                        window: "max-width-510px"
                                    }
                                }} />
                                {/* </div> */}

                                {/* toolbar */}
                                <div className={themes?.toolbarCollectionWrapperRightPadding}>
                                    <div className={"w-50 d-flex justify-content-start align-items-center mb-1 text-blue-theme text-truncate"}>
                                        <div className={"max-width-38px min-width-38px height-38px rounded-5px position-relative me-2 overflow-hidden"}>
                                            <Avatar attributes={{
                                                name: "pharmacy-logo",
                                                formElement: true,
                                                validate: true,
                                                id: "mediaId",
                                                key: "mediaId",
                                                url: "mediaUrl",
                                                size: themes?.sizeAbsolute,
                                                leading: icons?.PhotoSizeSelectActualIcon,
                                                style: themes?.image38,
                                                model: selectedPharmacy,
                                                observer: pharmacyLogoObserver,
                                                config: {
                                                    ownerId: appConfig?.sessionAuthDetails?.userId,
                                                    uploadUrl: pharmacyService?.uploadUrl,
                                                    downloadUrl: pharmacyService?.downloadUrl
                                                }
                                            }} />
                                        </div>
                                        <div className={"mt-1"}>
                                            <div className={"d-flex align-items-center text-13px text-bold mb-1 max-width-200px-"}>
                                                <span className={"me-2 text-truncate line-height-14px height-15px"}>{selectedPharmacy?.pharmacyName}</span>
                                            </div>
                                            <div className={"d-flex text-12px m-0 opacity-8 text-truncate"}>
                                                <div className={"d-flex align-items-center justify-content-start text-12px me-3 text-truncate"}>
                                                    <span className={"d-flex text-blue-theme text-truncate w-100 me-1"}>Showing {totalRowsCount.current} of {totalRows} inventory items</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"w-70 d-flex justify-content-end"}>
                                        <Toolbar attributes={{
                                            name: toolbarName,
                                            items: [
                                                {
                                                    type: TOOLBARITEMTYPE.SEARCHFIELD,
                                                    name: toolbarButtons.filterButtonName,
                                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                                    leading: icons?.SearchRoundedIcon,
                                                    placeholder: "Find a product...",
                                                    trailing: icons?.ArrowForwardRoundedIcon,
                                                    style: themes?.toolbar?.searchField250px,
                                                    onClick: (e, args) => { handleSearchRecords(e, args) },
                                                    filter: {
                                                        placeholder: "Filter",
                                                        leading: icons?.FilterAltRoundedIcon,
                                                        onClick: (e, args) => { handleProductFilterRecords(e, args) },
                                                    }
                                                },
                                                {
                                                    type: TOOLBARITEMTYPE.SPACE,
                                                    style: themes?.toolbar?.space?.space4
                                                },
                                                {
                                                    name: toolbarButtons.reloadProductButtonName,
                                                    type: TOOLBARITEMTYPE.BUTTON,
                                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                                    leading: icons?.ReplayRoundedIcon,
                                                    onClick: handleReloadMarketProducts,
                                                    observer: componentObserver,
                                                    style: themes?.toolbar?.button,
                                                },
                                                {
                                                    type: TOOLBARITEMTYPE.SPACE,
                                                    style: themes?.toolbar?.space?.space4
                                                },
                                                {
                                                    type: TOOLBARITEMTYPE.DROPDOWNBUTTON,
                                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                                    leading: icons?.MoreVertRoundedIcon,
                                                    onClick: () => { },
                                                    observer: componentObserver,
                                                    style: themes?.toolbar?.endButton,
                                                    widget: <MenuList attributes={{
                                                        items: [
                                                            {
                                                                name: actionButtonsMenuItems.editProductMenuItem,
                                                                leading: icons?.EditRoundedIcon,
                                                                title: "Edit product",
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.deleteProductMenuItem,
                                                                leading: icons?.DeleteRoundedIcon,
                                                                title: "Delete",
                                                            },
                                                            {
                                                                type: MENUITEMTYPE.SEPARATOR
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.categoriesMenuItem,
                                                                leading: icons?.CategoryCardsRoundedIcon,
                                                                title: "Manage products",
                                                                transparent: true,
                                                            },
                                                            {
                                                                type: MENUITEMTYPE.SEPARATOR
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.customerOrderMenuItem,
                                                                leading: icons?.ShoppingBasketRoundedIcon,
                                                                title: "Customers' orders",
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.transactionsMenuItem,
                                                                leading: icons?.ReceiptRoundedIcon,
                                                                title: "Transactions dashboard",
                                                            },
                                                            {
                                                                type: MENUITEMTYPE.SEPARATOR
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.categoriesMenuItem,
                                                                leading: icons?.CategoryCardsRoundedIcon,
                                                                title: "View market activity history",
                                                                transparent: true,
                                                            }
                                                        ],
                                                        style: themes?.menuList,
                                                        onItemClick: handleProductToolbarOptionsMenuItemClick,
                                                        isSelectable: false
                                                    }} />,
                                                }
                                            ]
                                        }} />
                                    </div>
                                </div>

                                {/* datagrid view */}
                                <div className={"position-absolute end-15px top-50px start-0px bottom-0px "}>
                                    <DataGrid attributes={{
                                        name: pharmacyProductDatagridName,
                                        key: "productId",
                                        observer: pharmacyProductDatagridListObserver,
                                        columns: [
                                            {
                                                default: "",
                                                style: themes?.width15px
                                            },
                                            {
                                                type: COLUMNTYPE.CHECKBOX,
                                                value: ["productId"],
                                                style: themes?.width30px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                type: COLUMNTYPE.IMAGETHUMBNAIL,
                                                value: ["mediaUrl"],
                                                default: "",
                                                style: themes?.width35px,
                                                image: {
                                                    id: "mediaId",
                                                    key: "mediaId",
                                                    url: "mediaUrl",
                                                    size: themes?.size35px,
                                                    leading: icons?.BrandingWatermarkRoundedIcon,
                                                    style: themes?.image,
                                                    config: {
                                                        downloadUrl: pharmacyProductService?.downloadUrl
                                                    },
                                                }
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Product",
                                                value: ["productName"],
                                                default: "",
                                                style: themes?.width100
                                            },
                                            {
                                                default: "",
                                                style: themes?.width5px
                                            },
                                            {
                                                type: COLUMNTYPE.TOOLBAR,
                                                items: [
                                                    {
                                                        type: TOOLBARITEMTYPE.BUTTON,
                                                        tooltip: "Preview",
                                                        leading: icons?.SearchRoundedIcon,
                                                        onClick: (e, args) => { handleBrowseProduct(e, args) },
                                                        observer: componentObserver,
                                                        style: themes?.toolbar?.flatIconButton
                                                    },
                                                ],
                                                style: themes?.width45px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width5px
                                            },
                                            {
                                                title: "Price",
                                                value: ["priceAlias"],
                                                default: "",
                                                style: themes?.width90px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Type",
                                                value: ["productType"],
                                                default: "",
                                                style: themes?.width100px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Size/Weight",
                                                value: ["productVariation"],
                                                default: "",
                                                style: themes?.width100px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width5px
                                            }
                                        ],
                                        isStripped: true,
                                        isSelectable: true,
                                        dockType: DOCKTYPE.FILL,
                                        floatingBar: {
                                            page: {
                                                pageNumber: pageNumber,
                                                numberOfPages: totalPages,
                                                rowsPerPageList: global.variables.rowsPerPageList,
                                                onHomeClick: handleFirstPage,
                                                onPageNumberChange: handlePageNumberChange,
                                                onRowsPerPageClick: handleRowsPerPageChange,
                                                onPreviousClick: handlePreviousPage,
                                                onNextClick: handleNextPage
                                            }
                                        },
                                        dataset: products,
                                        onRowDoubleClick: handleDatagridRowDoubleClick
                                    }} />
                                </div>
                            </div>
                        );
                    })()
                },
                {
                    // consultants
                    widget: (() => {
                        return (
                            <div className={"position-absolute top-0px start-0px bottom-0px w-100 pt-0 pb-5 border-solid border-top-0px border-bottom-0px border-start-0px border-end-0px"}>
                                <div className={"w-100 position-absolute end-0px top-50px start-0px bottom-0px "}>

                                </div>
                            </div>
                        );
                    })()
                },
            ]
        },

    };
}

export default usePharmacyProductsHook;