import React, { useEffect, useRef, useState } from "react";

const PageFieldButton = ({ attributes }) => {
    const componentObserver = useRef();

    const [numberOfPages, setNumberOfPages] = useState((attributes?.page?.numberOfPages || 1));

    useEffect(() => {
        setNumberOfPages(attributes?.page?.numberOfPages);
    }, [attributes?.page?.numberOfPages]);

    const handleKeyDown = (event) => {
        if (!Number.isInteger(parseInt(event.key))) {

            // check for backspace
            if (event.keyCode === 8) {
                // Handle Enter key press
                if ((event.target.innerHTML.length - 1) < 1) {
                    event.preventDefault();
                    event.target.innerHTML = 1;
                    window.getSelection().selectAllChildren(event.target);
                }
                return;
            }

            // check for enter key
            else if (event.keyCode === 13) {
                event.preventDefault();
                // Handle Enter key press
                console.log(attributes?.page);
                attributes?.page?.onPageNumberChange(event, { value: parseInt(event.target.innerHTML) });
            }

            // check for enter key
            else if (event.keyCode === 37 || event.keyCode === 39) {
                // Handle Enter key press
            }

            else {
                event.preventDefault();
            }
        }
    };

    const handleKeyUp = (event) => {
        if (Number.isInteger(parseInt(event.key)) && Number.isInteger(parseInt(event.target.innerHTML)) && (parseInt(event.target.innerHTML) > numberOfPages)) {
            event.target.innerHTML = 1;
        }
    };

    return (
        <div ref={componentObserver} className={"text-dark text-decoration-none d-flex justify-content-start align-items-center " + ((attributes?.style?.g) ? attributes?.style?.g : " border-0px")}>
            <div className={"d-flex me-1 " + attributes?.style?.text}>Go to Page: </div>
            <div data-attribute={"page-number-field"}
                type="text"
                className={"white-space-nowrap " + attributes?.style?.field}
                contentEditable={true}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                suppressContentEditableWarning={true}>
                {attributes?.page?.pageNumber}
            </div>
            <div className={"white-space-nowrap ms-1 " + attributes?.style?.text}>of {(attributes?.page?.numberOfPages || 1)}
            </div>
        </div>
    );
}
export default PageFieldButton;