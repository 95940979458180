import { useCallback, useEffect, useRef, useState } from 'react';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { useForm } from '../../../../../shared/components/form/form.component';
import { Toolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { ProductCategoryService } from '../../../configuration.settings/settings/product.categories/product.category.service';
import { COLUMNTYPE, DOCKTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { ProductService } from '../product.service';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import { usePapaParse } from 'react-papaparse';
import { v4 as uuidv4 } from 'uuid';
import { useToastr } from '../../../../../shared/components/toastr/toastr.component';
import { SingleSelectDropDownField } from '../../../../../shared/components/field/single.select.dropdown.field.component';


const useCreateBulkProductHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes().rounded;

    // state objects
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [productCategories, setProductCategories] = useState([]);

    // refs
    const reactiveForm = useRef(null);

    // obervers
    const productsDatagridObserver = useRef(null);
    const componentObserver = useRef(null);
    const productCategoryDropDownFieldObserver = useRef(null);
    const bulkProductsObserver = useRef(null);
    const bulkProductObserver = useRef(null);

    // components
    const bulkProductsDatagridName = "bulk-products-datagrid";
    const bulkProductsDatagrid = useDataGrid({
        name: bulkProductsDatagridName
    });

    const addBulkProductForm = useForm({
        ref: reactiveForm
    });

    const leftPaneToolbarItems = {
        selectProductCategoryButton: "select-product-category-button",
        productCategoryLabel: "select-product-category-label",
    }

    const rightPaneToolbarItems = {
        bulkCreateProductsButton: "bulk-create-product-button",
        deleteProductButton: "delete-product-button",
    }

    // loader
    const pageLoader = usePageLoader();

    // toastr
    const toastr = useToastr();

    // csv reader
    const { readString } = usePapaParse();

    // callbacks
    const widgetReactiveFormModule = useCallback(async (args) => {
        const isEmptyField = addBulkProductForm.validate(args.data);
        if (isEmptyField)
            return;

        args.data["products"] = bulkProductsObserver.current;
        handleSaveOrUpdate(args);
    }, [])

    const widgetObserver = useCallback(async (args) => {
        pageLoader.hide();

        // set selected market
        setSelectedProduct(args?.data);

        // load product categories
        await getProductCategories("");

        // clear purchase market products datagrid
        bulkProductsObserver.current = [];
        productsDatagridObserver.current?.clear();

        if (args?.data !== null) {
            //
            bulkProductObserver.current = await args?.data;
        } else {
            bulkProductObserver.current = await {};
        }

    }, [])

    // services
    const productService = ProductService();
    const productCategoryService = ProductCategoryService();

    const getProductCategories = useCallback(async (search) => {
        await productCategoryService.getAll({
            queryString: search,
            pageNumber: 1,
            rowsPerPage: 5
        }).then(response => {
            pageLoader.hide();
            setProductCategories(response.data.list);
        }).catch(error => {
            pageLoader.hide();
        });
    }, []);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleSearchCategoryFieldChange = (e) => {
        getProductCategories(e.value);
    }

    const handleBrowseBulkCreateFile = async (e) => {
        readString(e.target.files[0], {
            worker: true,
            header: false,
            complete: (results) => {
                if (Array.isArray(results?.data)) {
                    bulkProductsObserver.current = results?.data?.map((item, i) => ({
                        productId: uuidv4(),
                        productName: item[0],
                        productDescription: item[1]
                    }))
                }
                bulkProductsObserver.current.shift();
                productsDatagridObserver.current?.updateList(bulkProductsObserver.current);
            },
        });
    }

    const handleSaveOrUpdate = async (args) => {

        console.log(args.data);
        // console.log(args, bulkProductsObserver.current);
        // return;

        args?.successful({
            data: bulkProductsObserver.current,
            callback: args?.callback
        });

        pageLoader.show({
            message: "Loading, please wait...",
        });

        await productService.saveBulk(args.data).then((response) => {
            pageLoader.hide();
            toastr.success("Products successfully created");
        }).catch((error) => {
            pageLoader.hide();
            toastr.error("Oops! something went wrong.");
        });
    }

    return {
        reactiveForm: reactiveForm,
        selectedProduct,

        gridViewAttributes: (() => {
            return <div className={"w-100 flex-column"}>
                <div className={themes?.toolbarCollectionWrapperNoPadding}>
                    <div className={"w-40 d-flex align-items-center"}>
                        <div className={"w-100 me-1"}>
                            <SingleSelectDropDownField attributes={{
                                name: "bulk-product-category-selectable-dropdownlist-field",
                                type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
                                placeholder: "Select a category",
                                observer: productCategoryDropDownFieldObserver,
                                style: themes?.field?.singleSelectDropDownField,
                                formElement: true,
                                key: "productCategoryId",
                                title: "productCategoryName",
                                value: "productCategoryId",
                                isSearchable: true,
                                model: bulkProductObserver?.current,
                                onFilter: handleSearchCategoryFieldChange
                            }} />
                        </div>
                        <div className={"w-100"}>
                            <SingleSelectDropDownField attributes={{
                                name: "bulk-product-category-selectable-dropdownlist-field",
                                type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
                                placeholder: "Select a category",
                                observer: productCategoryDropDownFieldObserver,
                                style: themes?.field?.singleSelectDropDownField,
                                formElement: true,
                                key: "productCategoryId",
                                title: "productCategoryName",
                                value: "productCategoryId",
                                isSearchable: true,
                                model: bulkProductObserver?.current,
                                onFilter: handleSearchCategoryFieldChange
                            }} />
                        </div>
                    </div>
                    <div className={"w-5 d-flex justify-content-end"}></div>
                    <div className={"w-55 d-flex justify-content-end"}>
                        <Toolbar attributes={{
                            items: [
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: rightPaneToolbarItems.bulkCreateProductsButton,
                                    type: TOOLBARITEMTYPE.UPLOADBUTTON,
                                    text: "Browse",
                                    leading: icons?.FolderIcon,
                                    onClick: (e) => handleBrowseBulkCreateFile(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                            ]
                        }} />
                    </div>
                </div>

                <div className={"position-relative height-440px"}>
                    <DataGrid attributes={{
                        name: bulkProductsDatagridName,
                        observer: productsDatagridObserver,
                        key: "productId",
                        columns: [
                            {
                                default: "",
                                style: themes?.width15px
                            },
                            {
                                type: COLUMNTYPE.CHECKBOX,
                                value: "productId",
                                style: themes?.width30px
                            },
                            {
                                type: COLUMNTYPE.IMAGETHUMBNAIL,
                                default: "",
                                style: themes?.width35px,
                                image: {
                                    id: "mediaId",
                                    key: "mediaId",
                                    url: "mediaUrl",
                                    size: themes?.size35px,
                                    leading: icons?.PhotoSizeSelectActualRoundedIcon,
                                    style: themes?.image
                                }
                            },
                            {
                                default: "",
                                style: themes?.width15px
                            },
                            {
                                title: "Product",
                                value: "productName",
                                default: "",
                                style: themes?.width100
                            },
                            {
                                default: "",
                                style: themes?.width15px
                            },
                            {
                                title: "Description",
                                value: "productDescription",
                                default: "",
                                style: themes?.width100
                            },
                            {
                                default: "",
                                style: themes?.width15px
                            }
                        ],
                        isStripped: true,
                        isSelectable: true,
                        dockType: DOCKTYPE.FILL,
                        dataset: bulkProductsObserver.current
                    }} />
                </div>
            </div>
        })(),

        themes: themes,
    }
}

export default useCreateBulkProductHook;