import React, { useEffect, useRef, useState } from "react";

const Button = ({ attributes }) => {
    const [button, setProperties] = useState({
        inProgress: false,
        message: ""
    });

    useEffect(() => {
        (async () => {
            if (attributes?.observer)
                attributes.observer.current = await notifier;
        })();
    }, [attributes])

    const notifier = (e) => {
        setProperties({
            inProgress: e.inProgress,
            message: e.message
        });
    }

    const noAction = () => {
        // 
    }

    const handleOnClick = (e) => {
        if (attributes?.baseClickHandler)
            attributes?.baseClickHandler(e);

        if (attributes?.args) {
            attributes.onClick(e, attributes.args);
        } else {
            attributes.onClick(e);
        }

        e.stopPropagation();
    }

    return (
        <div id={attributes?.name} className={"d-flex"} onClick={attributes?.onClick ? handleOnClick : () => noAction()}>
            <button data-controls="button" type={attributes?.type} className={"btn border-0px- " + ((attributes?.text ? "rounded-pill- " : "rounded-circle- p-0 ") + ((attributes?.style?.g) ? attributes?.style?.g : "shadow-sm- border-0px height-34px") + " " + ((attributes?.style?.bgColor) ? attributes?.style?.bgColor : "btn-grey-theme"))}>
                <div className={"position-relative d-flex justify-content-center align-items-center " + attributes?.style?.textColor}>
                    <div className={"d-flex justify-content-center align-items-center text-13px " + (attributes?.text ? "ps-1 pe-1" : " ps-0 pe-0")}>
                        <i aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 " + ((attributes?.leading) ? "" : "hide ") + (attributes?.text ? "me-1 " : "") + " icon " + attributes?.style?.leading}>
                            {attributes?.leading}
                        </i>
                        <span aria-controls="label" className={"white-space-nowrap " + (attributes?.style?.text) + " " + (attributes?.leading ? "" : "ms-1 me-1")}>{attributes?.text}</span>
                        <i aria-controls="trailing" className={"d-flex justify-content-center align-items-center " + ((attributes?.trailing) ? "" : "hide ") + "m-0 ms-1 icon " + attributes?.style?.trailing}>
                            {attributes?.trailing}
                        </i>
                    </div>
                    <div className={"position-absolute top-0px end-0px bottom-0px margin-auto d-flex icon icon-20px " + ((attributes?.showProgress) ? "show" : "hide")}>
                        <div className={"spinner-border icon icon-20px " + ((attributes?.showProgress || button?.inProgress) ? "show" : "hide")} role="status">
                            <span className={"visually-hidden"}>Loading...</span>
                        </div>
                    </div>
                </div>
            </button>
        </div>
    );
}
export default Button;