import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { COMPONENTYPE, DOCKTYPE, MENUALIGNMENTTYPE } from "../../enums/enums";
import { useIconsThemes } from "../../hooks/ui.icons.hook";

const SingleSelectDropDownField = forwardRef(({ attributes }, ref) => {

    // theme
    const icons = useIconsThemes()?.rounded;

    const [items, setItems] = useState([]);

    const componentObserver = useRef();
    const selectableDropDownList = useSingleSelectDropDownField(attributes);

    const collapseAll = useCallback((e) => {
        [...document.querySelectorAll("div[aria-owns=\"dropdown\"]")].forEach((item, i) => {
            item.classList.add("hide");
        });
    }, []);

    useImperativeHandle(attributes?.observer, () => ({
        addToStore(items) {
            if (!componentObserver?.current?.hasAttribute("items-store")) {
                componentObserver?.current?.setAttribute("items-store", JSON.stringify(items));
            }
        },

        updateList(items) {
            const label = componentObserver?.current?.querySelector("span[aria-controls=\"label\"]");
            label.innerHTML = "" || attributes?.placeholder;

            attributes.items = items;
            setItems(items);
        },

        clearItem() {
            componentObserver?.current?.removeAttribute("selected-item-store");
            componentObserver?.current?.removeAttribute("selected-item");
        },

        selectItem(model) {
            if (!componentObserver?.current?.hasAttribute("selected-item")) {
                componentObserver?.current?.setAttribute("selected-item", JSON.stringify(model));
            } else {
                model = JSON.parse(componentObserver?.current?.getAttribute("selected-item"));
                componentObserver?.current?.setAttribute("selected-item-store", JSON.stringify(model));
            }

            const label = componentObserver?.current?.querySelector("span[aria-controls=\"label\"]");
            const hiddenField = componentObserver?.current?.querySelector("input[type=\"hidden\"]");

            if (model) {
                label.innerHTML = (model ? model[attributes?.title] : attributes?.model[attributes?.title]) || attributes?.placeholder;
                hiddenField.value = (model ? model[attributes?.value] : attributes?.model[attributes?.value]); // attributes?.model[attributes?.value];
                if (model) {
                    if (attributes?.onChange) {
                        attributes?.onChange({
                            selectedItem: model,
                            id: attributes?.key,
                            value: model[attributes?.value],
                            title: model[attributes?.title],
                        });
                    }
                }
            }

        }
    }))

    useEffect(() => {
        (async () => {
            // list items
            setItems(attributes?.items);

            if (componentObserver?.current?.hasAttribute("selected-item")) {
                componentObserver?.current?.removeAttribute("selected-item");

                // 
                if (componentObserver?.current?.hasAttribute("selected-item-store")) {
                    componentObserver?.current?.setAttribute("selected-item", componentObserver?.current?.getAttribute("selected-item-store"));
                }
            }

            document.body.addEventListener("click", collapseAll);
            const label = componentObserver?.current?.querySelector("span[aria-controls=\"label\"]");
            const hiddenField = componentObserver?.current?.querySelector("input[type=\"hidden\"]");

            if (attributes?.model) {
                const selectedModel = JSON.parse(componentObserver?.current?.getAttribute("selected-item"));
                const model = (selectedModel) ? selectedModel : attributes?.model; //attributes?.items?.find(x => x[attributes?.value]?.toString() === attributes?.model[attributes?.key]?.toString());

                label.innerHTML = (model ? model[attributes?.title] : attributes?.model[attributes?.title]) || attributes?.placeholder;
                hiddenField.value = (Object.keys((model || attributes?.model)).length > 0) ? (model ? model[attributes?.value] : attributes?.model[attributes?.value]) : ""; //attributes?.model[attributes?.value];

                if (model) {
                    if (attributes?.onChange) {
                        attributes?.onChange({
                            selectedItem: model,
                            id: attributes?.key,
                            value: model[attributes?.value],
                            title: model[attributes?.title],
                        });
                    }
                }
            } else {
                label.innerHTML = "" || attributes?.placeholder;
            }

            // garbage collection
            return () => {
                document.body.removeEventListener("click", collapseAll);
            }
        })();
    }, [attributes, collapseAll])

    const getDockStyle = (type) => {
        switch (type) {
            case DOCKTYPE.BOTTOM:
                return "position-absolute start-0px bottom-0px end-0px";
            case DOCKTYPE.FILL:
                return "position-absolute top-0px start-0px bottom-0px end-0px";
            case DOCKTYPE.LEFT:
                return "position-absolute top-0px start-0px bottom-0px";
            case DOCKTYPE.RIGHT:
                return "position-absolute top-0px bottom-0px end-0px";
            case DOCKTYPE.TOP:
                return "position-absolute top-0px start-0px end-0px";
            default:
                return;
        }
    }

    const getMenuAlignment = (e) => {
        switch (e) {
            case MENUALIGNMENTTYPE.LEFT:
                return "top-37px start-0px";
            case MENUALIGNMENTTYPE.RIGHT:
                return "top-37px end-0px";
            case MENUALIGNMENTTYPE.TOP:
                return;
            case MENUALIGNMENTTYPE.TOPLEFT:
                return "bottom-37px start-0px";
            case MENUALIGNMENTTYPE.BOTTOMLEFT:
                return "top-37px start-0px";
            default:
                return "top-37px end-0px";
        }
    }

    const handleOnClick = (e) => {
        try {
            const dropdown = componentObserver.current.querySelector("*[aria-owns=\"dropdown\"]");
            if (dropdown.classList.contains("hide")) {
                collapseAll(e);
                dropdown.classList.remove("hide");
                if (attributes.onClick) {
                    attributes.onClick(e, {
                        key: ((e) => componentObserver.current.parentNode.parentNode.parentNode.getAttribute("aria-rowindex"))()
                    });
                }
            } else {
                dropdown.classList.add("hide");
            }
            e.stopPropagation();
        } catch (e) { }
    }

    const handleDropdownStopPropagation = (e) => {
        e.cancelBubble = true;
        e.stopPropagation();
    }

    const handleOnFilter = (e) => {
        if (attributes?.onFilter)
            attributes?.onFilter(e.target);
    }

    const handleItemClick = (e, model) => {
        selectableDropDownList.selectItem(e);

        const tr = e;

        // hidden field
        const hiddenField = componentObserver?.current?.querySelector("input[type=\"hidden\"]");
        hiddenField.value = (attributes?.returnAsCollection) ? JSON.stringify([tr.getAttribute("id")]) : tr.getAttribute("id");

        const label = componentObserver?.current?.querySelector("span[aria-controls=\"label\"]");
        label.innerHTML = model[attributes?.title];

        // console.log(model);

        // 
        if (attributes?.onItemClick) {
            attributes?.onItemClick(e, {
                selectedItem: model,
                id: attributes?.key,
                value: model[attributes?.value],
                title: model[attributes?.title],
            });
        }

        componentObserver?.current?.setAttribute("selected-item", JSON.stringify(model));
        componentObserver?.current?.setAttribute("selected-item-store", JSON.stringify(model));

        handleOnClick(e);
    }

    const listItem = ({ auto, attributes, model, isFirstOrDefault, isExpanded }) => {
        if (model[attributes?.value])
            return (
                <tr
                    aria-controls="table-row"
                    key={model[attributes?.value]}
                    aria-rowindex={auto}
                    id={"" + model[attributes?.value]}
                    aria-selected={(isFirstOrDefault ? "true" : "")}
                    className={"w-100 hover-bg-blue-light-theme"}>
                    <td className={"border-0px p-0"}>
                        <div
                            onClick={(e) => handleItemClick(e.currentTarget.parentNode.parentNode, model)}
                            aria-controls="table-row-header"
                            className={"w-100 padding-top-1px padding-bottom-1px ps-2 rounded-0 hover-bg-blue-light-theme box-sizing-border d-flex justify-content-start align-items-center cursor-pointer " + (isFirstOrDefault ? "" : "") + ((attributes?.style?.row) ? attributes?.style?.row : "")}>
                            <div
                                aria-controls="row-column" className={"box-sizing-border "}>
                                <div className={"d-flex align-items-center w-100 pe-4 box-sizing-border"}>
                                    <div className={"white-space-nowrap text-overflow-ellipsis pt-1 pb-1 w-100- box-sizing-border"}>{model[attributes?.title]}</div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            );
    }

    const noRecordPlaceholder = () => {
        return (
            <tr className={"w-100"}>
                <td className={"border-0px p-0"}>
                    <div className={"d-flex justify-content-center align-items-center mx-auto max-width-350px pt-2 pb-2 mt-0"}>{(!attributes?.listPlaceholder)? "No record yet." : attributes?.listPlaceholder}</div>
                </td>
            </tr>
        );
    }

    return (
        <div aria-controls={"selectable-dropdown-list-field"} id={attributes?.name} ref={componentObserver} className={"d-flex position-relative"}
            aria-errormessage={attributes?.errorMessage}>
            <div type={attributes?.type} className={"position-relative cursor-pointer form-control ps-2 d-flex justify-content-center align-items-center " + (((attributes?.style?.g) ? attributes?.style?.g : " border-0px height-34px") + ((attributes?.style?.bgColor) ? attributes?.style?.bgColor : "btn-grey-theme"))} onClick={handleOnClick}>
                <span aria-controls="label" className={"w-100 white-space-nowrap " + (attributes?.style?.text)}>{(attributes?.text || attributes?.placeholder)? (attributes?.text || attributes?.placeholder) : ((attributes?.items && attributes?.items?.length > 0)? attributes?.items[0][attributes?.title] : "")}</span>
                <i aria-controls="trailing" className={"d-flex justify-content-center align-items-center m-0 ms-1 icon " + attributes?.style?.trailing}>
                    {icons?.ExpandMoreRoundedIcon}
                </i>
                <input
                    id={attributes?.key}
                    aria-owns={attributes?.name}
                    aria-controls={attributes?.formElement}
                    aria-roledescription={COMPONENTYPE.SINGLESELECTDROPDOWNFIELD}
                    aria-required={attributes?.validate}
                    type={"hidden"} />

            </div>
            <div onClick={handleDropdownStopPropagation} aria-owns="dropdown" className={"select-dropdown-menu hide position-absolute z-index-10 min-width-100- " + getMenuAlignment(attributes?.menuPosition)}>
                <div className={"bg-white border border-1px transparent-border-blue-dark-theme shadow padding-8px rounded-3 w-100 min-width-50px min-height-50px display-inline-block float-start"}>
                    <div id={attributes?.name} className={"d-flex align-items-center position-relative form-control ps-2 text-14px margin-bottom-8px " + (attributes?.isSearchable ? "" : "hide ") + ((attributes?.style) ? attributes?.style?.textField : " rounded-pill padding-top-2px padding-bottom-2px padding-start-1px padding-end-1px")}>
                        <div className={"hide"}>
                            <div aria-controls="placeholder" type={attributes?.type} className={"border-0px cursor-pointer " + ((attributes?.text ? "rounded-pill- " : "rounded-circle- p-0 ") + ((attributes?.style?.text) ? attributes?.style?.text : "shadow-sm- border-0px height-34px"))}>
                                <div className={"position-relative d-flex justify-content-center align-items-center"}>
                                    <div className={"d-flex justify-content-center align-items-center text-13px " + (attributes?.text ? "ps-0 pe-0" : " ps-0 pe-0")}>
                                        <i aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 " + ((attributes?.leading) ? "" : "hide ") + (attributes?.text ? "me-2 " : "") + " icon " + attributes?.style?.leading}>
                                            {attributes?.leading}
                                        </i>
                                        <span aria-controls="label" className={"white-space-nowrap text-italic opacity-6 " + (attributes?.style?.text) + " " + (attributes?.leading ? "" : "ms-1 me-1")}>{attributes?.placeholder}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div aria-controls="field-tokenbar"></div>
                        <div className={"d-flex align-items-center position-relative w-100"}>
                            <i aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 me-2 " + (attributes?.text ? "me-2 " : "") + " icon " + attributes?.style?.leading}>
                                {icons.SearchRoundedIcon}
                            </i>
                            <input
                                onChange={handleOnFilter}
                                placeholder={attributes?.placeholder || "Search..."}
                                type={'text'}
                                className={"w-100 form-control text-13px rounded-3px border-0px p-0 bg-transparent"} />
                            <i aria-controls="trailing" className={"d-flex justify-content-center align-items-center " + ((attributes?.trailing) ? "" : "hide ") + "m-0 ms-1 icon " + attributes?.style?.trailing}>
                                {attributes?.trailing}
                            </i>
                        </div>
                    </div>

                    <div aria-controls={"table-grid"} ref={componentObserver} id={"dg-" + attributes?.name} className={"" + getDockStyle(attributes?.dockType) + " " + attributes?.style?.dropdown}>
                        <div aria-owns={attributes?.name} className={((attributes?.dockType === DOCKTYPE.FILL) ? ((!attributes.hasOwnProperty("showHeader") || attributes?.showHeader) ? "top-37px " : "top-7px ") + "position-absolute start-0px end-0px bottom-0px overflow-x-hidden- " + ((attributes?.hasOwnProperty("allowScrolling") && attributes?.allowScrolling === false) ? "" : "overflow-y-auto") : "")}>
                            <table className={"table table-hover- w-100 mb-0 text-13px box-sizing-border table-layout-fixed"}>
                                <tbody>
                                    {
                                        (() => {
                                            if (items?.length > 0) {
                                                return items?.map((data, i) => {
                                                    return listItem({
                                                        isFirstOrDefault: (i === 0),
                                                        auto: i,
                                                        key: (data[attributes?.value] + "-" + i),
                                                        attributes: attributes,
                                                        model: data,
                                                        onItemClick: (e, data) => handleItemClick
                                                    });
                                                })
                                            } else {
                                                return noRecordPlaceholder();
                                            }
                                        })()
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div >
                </div>
            </div>
        </div>
    )

})

const useSingleSelectDropDownField = (attributes) => {

    const selectedItemTheme = "bg-blue-lighter-theme";

    const clearSelection = () => {
        const dgrid = target();
        if (dgrid) {
            const selectedItems = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
            [...selectedItems].forEach(row => {
                row.querySelector("*[aria-controls=\"table-row-header\"]").classList.remove(selectedItemTheme);
                row.classList.remove(selectedItemTheme);
                row?.removeAttribute("aria-selected");
                const checkbox = row.querySelector("input[type=\"checkbox\"]");
                if (checkbox)
                    checkbox.checked = false;
            });
        }
    }

    const getPaging = () => {
        const _target = target();
        return {
            pageNumber: parseInt(_target.getAttribute("pagenumber")) || attributes.page.pageNumber,
            rowsPerPage: parseInt(_target.getAttribute("rowsperpage")) || attributes.page.rowsPerPage,
            totalPages: parseInt(_target.getAttribute("totalpages")),
            totalItems: parseInt(_target.getAttribute("totalrows"))
        };
    }

    const getSelectedItem = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.find(x => x.checked);
        }
    }

    const getSelectedItemIndex = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const selectedItem = tbody.querySelector("tr[aria-selected=\"true\"]");
        if (selectedItem) {
            const selectedIndex = selectedItem.getAttribute("aria-rowindex");
            return selectedIndex;
        } else {
            return 0;
        }
    }

    const getSelectedItemKeyValue = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.filter(x => x.checked)[0].value;
        }
    }

    const getSelectedItemsKeyValue = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.filter(x => x.checked).map(item => item.value);
        }
    }

    const getSelectedItems = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            [...checkboxes].forEach(checkbox => {
                //
            });
        }
    }

    const itemsCount = () => {
        const dgrid = target();
        if (dgrid) {
            const tbody = dgrid.querySelector("tbody");
            const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
            return checkboxes.length;
        }
        return 0;
    }

    const rows = () => {
        const dgrid = target();
        if (dgrid) {
            const tbody = dgrid?.querySelector("tbody");
            const rows = tbody?.querySelectorAll("*[aria-controls=\"table-row\"]");
            return [...rows];
        }
        return [];
    }

    const rowColumns = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const columns = tbody.querySelectorAll("*[aria-controls=\"row-column\"]");
        return [...columns];
    }

    const selectAllItem = (selectedItem) => {
        const dgrid = target();
        const selectedItems = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedItems].forEach(item => {
            item.classList.add(selectedItemTheme);
            item.querySelector("input[type=\"checkbox\"]").checked = true;
        });
    }

    const selectDefaultItem = (attributes) => {
        const dgrid = target();
        const selectedItems = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedItems].forEach(item => {
            item.classList.remove(selectedItemTheme);
            const checkbox = item.querySelector("input[type=\"checkbox\"]");
            if (checkbox)
                checkbox.checked = false;
        });
        if (selectedItems.length > 0) {
            selectedItems[0]?.classList.add(selectedItemTheme);
            const selectedCheckbox = selectedItems[0].querySelector("input[type=\"checkbox\"]");
            if (selectedCheckbox)
                selectedCheckbox.checked = true;
        }
    }

    const selectItem = (selectedItem) => {
        const dgrid = target();
        const selectedItems = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");

        [...selectedItems].forEach(row => {
            const rowHeader = row.querySelector("*[aria-controls=\"table-row-header\"]");
            rowHeader.classList.remove(selectedItemTheme);
            rowHeader.classList.add(rowHeader.getAttribute("aria-atomic"));
            if (selectedItem === row) {
                rowHeader.classList.remove(rowHeader.getAttribute("aria-atomic"));
            }
            row?.removeAttribute("aria-selected");
            const checkbox = row.querySelector("input[type=\"checkbox\"]");
            if (checkbox)
                checkbox.checked = false;
        });

        if (selectedItem) {
            selectedItem?.querySelector("*[aria-controls=\"table-row-header\"]").classList.add(selectedItemTheme);
            selectedItem?.setAttribute("aria-selected", true);
            const selectedItemCheckbox = selectedItem.querySelector("input[type=\"checkbox\"]");
            if (selectedItemCheckbox) {
                selectedItemCheckbox.checked = true;
                // selectedItemCheckbox.click();
            }
        }
    }

    const selectedItemsCount = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.filter(x => x.checked).length;
        }
        return 0;
    }

    const setPaging = () => {
        return attributes.page;
    }

    const setSelectedItem = (key) => {
        const dgrid = target();
        const selectedItems = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedItems].forEach(row => {
            row?.removeAttribute("aria-selected");
            const checkbox = row.querySelector("input[type=\"checkbox\"]");
            if (checkbox)
                checkbox.checked = false;
        });

        const selectedItem = dgrid.querySelector("*[id=\"" + key + "\"]");
        const checkbox = selectedItem.querySelector("input[type=\"checkbox\"]");
        if (checkbox && checkbox.value === key) {
            checkbox.checked = true;
            selectedItem?.setAttribute("aria-selected", true);
        }
    }

    const target = () => {
        return document.getElementById("dg-" + attributes?.name);
    }

    const unselectAllItem = (selectedItem) => {
        const dgrid = target();
        const selectedItems = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedItems].forEach(item => {
            item.classList.remove(selectedItemTheme);
            item.querySelector("input[type=\"checkbox\"]").checked = false;
        });
        if (selectedItems.length > 0) {
            selectedItems[0]?.classList.add(selectedItemTheme);
            selectedItems[0].querySelector("input[type=\"checkbox\"]").checked = true;
        }
    }

    const unselectCheckedItem = (selectedItem) => {
        const dgrid = target();
        const selectedItems = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedItems].forEach(item => {
            item.classList.remove("bg-light-theme");
        });
        selectedItem?.classList.add("bg-light-theme");
    }

    return {
        clearSelection,
        target,
        getPaging,
        getSelectedItem,
        getSelectedItemIndex,
        getSelectedItemKeyValue,
        getSelectedItems,
        getSelectedItemsKeyValue,
        itemsCount,
        rows,
        rowColumns,
        selectAllItem,
        selectDefaultItem,
        selectItem,
        setPaging,
        setSelectedItem,
        selectedItemsCount,
        unselectAllItem,
        unselectCheckedItem
    }

}

export { SingleSelectDropDownField, useSingleSelectDropDownField };