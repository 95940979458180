import React, { useEffect, useRef, useState } from "react";
import Button from "../button/button.component";
import { useIconsThemes } from "../../hooks/ui.icons.hook";

const SearchField = ({ attributes }) => {

    // theme
    const icons = useIconsThemes().rounded;

    const [value, setValue] = useState("");
    const [toggleCancelButton, setToggleCancelButton] = useState(false);

    const componentObserver = useRef();

    useEffect(() => {
        (async () => {
            if (attributes?.observer)
                attributes.observer.current = await notifier;
        })();
    }, [attributes])

    const notifier = (e) => {
        // 
    }

    const handleOnKeyChange = (e) => {
        setValue(e?.target?.value);
        if (attributes?.onChange)
            attributes?.onChange(e.target);
    }

    const handleOnKeyUp = (e) => {
        setValue(e?.target?.value);
        if (e.keyCode === 13)
            attributes?.onClick(e, { value: e?.target?.value });
    }

    const handleOnKeyDown = (e) => {
        setValue(e?.target?.value);
        setToggleCancelButton(true);
        if (e.keyCode === 8 && e.target.value.length <= 1) {
            e.target.value = "";
            attributes?.onClick(e, { value: e?.target?.value });
            setToggleCancelButton(false);
        }
    }

    const handleCancelSearch = (e, value) => {
        const field = componentObserver.current.querySelector("input");
        field.value = "";
        attributes?.onClick(e, { value: field.value })
    }

    return (
        <div ref={componentObserver} className={"d-flex align-items-center form-control text-14px " + (attributes?.style?.g + " " + attributes?.style?.bgColor)}>
            {
                (() => {
                    if (attributes?.filter) {
                        return (
                            <>
                                <i onClick={(e) => attributes?.filter?.onClick(e, { value: value })} aria-controls="leading" className={"d-flex justify-content-center align-items-center cursor-pointer m-0 " + ((attributes?.leading) ? "" : "hide ") + "me-0 icon " + attributes?.style?.leading}>
                                    {attributes?.filter?.leading}
                                </i>
                                <div className="width-0px border-blue-light-theme border-solid border-top-0px border-start-0px border-bottom-0px border-end-1px height-20px margin-start-6px margin-end-6px"></div>
                            </>
                        )
                    }
                })()
            }
            <i aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 " + ((attributes?.leading) ? "" : "hide ") + "me-1 icon " + attributes?.style?.leading}>
                {attributes?.leading}
            </i>
            <input
                onChange={handleOnKeyChange}
                onKeyUp={handleOnKeyUp}
                onKeyDown={handleOnKeyDown}
                id={attributes?.id}
                name={attributes?.id}
                value={attributes?.value}
                placeholder={attributes?.placeholder}
                type={"text"}
                className={"w-100 form-control text-14px rounded-5px border-0px p-0 bg-transparent " + attributes?.style?.text} />
            <div aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 " + ((attributes?.leading) ? "" : "hide ") + "me-0 icon "}>
                {
                    (() => {
                        if (toggleCancelButton) {
                            return (
                                <Button attributes={{
                                    name: "",
                                    tooltip: "Clear",
                                    leading: icons?.ClearRoundedIcon,
                                    onClick: (e, args) => { handleCancelSearch(e, args) },
                                    style: attributes?.style?.button
                                }} />
                            )
                        }
                    })()
                }
                <Button attributes={{
                    name: "",
                    tooltip: "Search",
                    leading: attributes?.trailing,
                    onClick: (e, args) => { attributes?.onClick(e, { value: value }) },
                    style: attributes?.style?.button
                }} />
            </div>
        </div>
    );
}
export default SearchField;