import axios from "axios";
import { httpGetHeaderOptions, httpPostHeaderOptions } from "./http.headers";

const api_base_url = process.env.REACT_APP_API_URL;

const useHttps = () => {
    const post = (url, data) => {
        try {
            return axios({
                method: "post",
                url: api_base_url + url,
                headers: httpPostHeaderOptions,
                data: data
            })
        } catch (e) {
            //
        }
    }

    const get = (url, data) => {
        try {
            return axios({
                method: "get",
                url: api_base_url + url,
                headers: httpGetHeaderOptions,
                data: data
            })
        } catch (e) {
            //
        }
    }

    const getRaw = (url, data) => {
        try {
            console.log(url);
            return axios({
                method: "get",
                url: url,
                headers: httpGetHeaderOptions,
                data: data
            })
        } catch (e) {
            //
        }
    }

    return { BASE_URL: api_base_url, post, get, getRaw }
};
export default useHttps;