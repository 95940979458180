import { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../shared/components/datagrid';
import {
    COLUMNTYPE,
    DOCKTYPE,
    GRIDPANE,
    MENUALIGNMENTTYPE,
    SLIDEDIRECTION,
    TOOLBARITEMTYPE,
} from '../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../shared/components/loader/page.loader.component';
import { usePrimaryUIThemes } from '../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../shared/hooks/ui.icons.hook';
import CreateOrEditMarketView from './createoredit.market/createoredit.market.view';
import { MarketService } from './market.service';
import MarketProductsView from './market.products/market.products.view';
import { useInfiniteLoader } from '../../../../shared/components/loader/infinite.loader.component';

const useMarketsHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [markets, setMarkets] = useState([]);
    const [selectedMarket, setSelectedMarket] = useState({});
    const [marketQueryString, setMarketQueryString] = useState("");
    const [marketPageNumber, setMarketPageNumber] = useState(1);
    const [marketRowsPerPage, setMarketRowsPerPage] = useState(25);
    const [marketTotalPages, setMarketTotalPages] = useState(0);
    const [marketTotalRows, setMarketTotalRows] = useState(0);

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No market selected!",
            message: "Please select an market from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New market",
            successMessage: {
                title: "Market created successfully!",
                message: "Market was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit market details",
            alertMessage: {
                title: "Multiple markets selected!",
                message: "Oops!, you selected multiple markets. Please select a market from the list to continue"
            },
            successMessage: {
                title: "Market updated successfully!",
                message: "Market was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit market details",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these markets?" : "this market?")
                }
            },
            successMessage: {
                title: "Market deleted successfully!",
                message: "Market was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these markets?" : "this market?")
                }
            },
            successMessage: {
                title: "Market published successfully!",
                message: "Market was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these markets?" : "this market?")
                }
            },
            successMessage: {
                title: "Market published successfully!",
                message: "Market was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these markets?" : "this market?")
                }
            },
            successMessage: {
                title: "Market archived successfully!",
                message: "Market was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these markets?" : "this market?")
                }
            },
            successMessage: {
                title: "Market restored successfully!",
                message: "Market was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available markets, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();
    const createOrEditMarketObserver = useRef();
    const marketProductsViewObserver = useRef();
    const marketsDatagridObserver = useRef();

    // components
    const marketDatagridName = "product-markets-datagrid";
    const datagrid = useDataGrid({
        name: marketDatagridName
    });

    const leftPaneToolbarName = "product-markets-left-pane-toolbar";
    const leftPaneToolbar = useToolbar({
        name: leftPaneToolbarName
    });
    const leftToolbarButtons = {
        addMarketButtonName: "add-market-button",
        editMarketButtonName: "edit-market-button",
        deleteMarketButtonName: "delete-market-button",
        reloadMarketButtonName: "reload-market-button",
    }

    const rightPaneToolbarName = "product-markets-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // services
    const marketService = MarketService();

    // dialogs
    const createOrEditMarketDialogName = "createoredit-market-modal";
    const createOrEditMarketDialog = useModalDialog({
        name: createOrEditMarketDialogName
    });
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();
    const infiniteLoader = useInfiniteLoader();

    // services
    const getMarkets = useCallback(async (request) => {
        infiniteLoader.show();
        datagrid?.showLoader(messages?.loader?.fetchingMessage);
        await marketService.getAll(request)
            .then(response => {
                datagrid?.hideLoader();
                infiniteLoader.hide();

                // populate datagrid 
                setMarkets(response?.data?.list);
                marketsDatagridObserver.current?.selectDefault();

                // set page
                setMarketPageNumber(response?.data?.page?.pageNumber);
                setMarketRowsPerPage(response?.data?.page?.rowsPerPage);
                setMarketTotalPages(response?.data?.page?.totalPages);
                setMarketTotalRows(response?.data?.page?.totalRows);

                rightPaneToolbar.button({
                    name: rightToolbarButtons.pagesLabelName
                }).setText("Page " + response?.data?.page?.pageNumber + " of " + response?.data?.page?.totalPages);

            })
            .catch(error => {
                infiniteLoader.hide();
            });

    }, []);

    useEffect(() => {
        (async () => {
            await getMarkets({
                queryString: marketQueryString,
                pageNumber: marketPageNumber,
                rowsPerPage: marketRowsPerPage
            });
        })();
    }, []);

    // market
    const handleCreate = (e) => {
        createOrEditMarketObserver.current.show({
            title: messages?.handleCreate?.dialogTitle,
            data: {},
            isNew: true
        });
    }

    const handleUpload = (e) => {
        // createOrEditCatererObserver.current.show({
        //     title: messages?.handleCreate?.dialogTitle,
        //     data: {},
        //     isNew: true
        // });
    }

    const handleEdit = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show(messages?.handleEdit?.alertMessage);
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedMarket = markets.find(market => market.marketId === datagrid.getSelectedRowKeyValue());
                setSelectedMarket(selectedMarket);

                // listen to dialog for changes
                createOrEditMarketObserver.current.show({
                    title: messages?.handleEdit?.dialogTitle,
                    data: selectedMarket,
                    isNew: false,
                    callback: () => {
                        getMarkets({
                            queryString: marketQueryString,
                            pageNumber: marketPageNumber,
                            rowsPerPage: marketRowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show(messages?.noRecordSelectedErrorMessage);
            }
        }
    }

    const handleDelete = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: messages?.handleDelete?.confirmMessage()?.title,
                message: messages?.handleDelete?.confirmMessage(datagrid.selectedRowsCount())?.message,
                onConfirmed: (e) => {
                    marketService.remove(datagrid.getSelectedRowsKeyValue()).then(async (response) => {
                        await getMarkets({
                            queryString: marketQueryString,
                            pageNumber: marketPageNumber,
                            rowsPerPage: marketRowsPerPage
                        });
                    });
                }
            });
        } else {
            alertDialog.show(messages?.noRecordSelectedErrorMessage);
        }
    }

    const handleReload = (e) => {
        getMarkets({
            queryString: marketQueryString,
            pageNumber: marketPageNumber,
            rowsPerPage: marketRowsPerPage
        });
    }

    const handlePublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: messages?.handlePublish?.confirmMessage()?.title,
                message: messages?.handlePublish?.confirmMessage(datagrid.selectedRowsCount())?.message,
                onConfirmed: (e) => {
                    infiniteLoader.show();
                    marketService.publish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getMarkets({
                            queryString: marketQueryString,
                            pageNumber: marketPageNumber,
                            rowsPerPage: marketRowsPerPage
                        });
                        infiniteLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show(messages?.noRecordSelectedErrorMessage);
        }
    }

    const handleUnpublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: messages?.handleUnpublish?.confirmMessage()?.title,
                message: messages?.handleUnpublish?.confirmMessage(datagrid.selectedRowsCount())?.message,
                onConfirmed: (e) => {
                    infiniteLoader.show();
                    marketService.unpublish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getMarkets({
                            queryString: marketQueryString,
                            pageNumber: marketPageNumber,
                            rowsPerPage: marketRowsPerPage
                        });
                        infiniteLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show(messages?.noRecordSelectedErrorMessage);
        }
    }

    const handleSearchRecords = async (e, args) => {
        getMarkets({
            queryString: args?.value,
            pageNumber: marketPageNumber,
            rowsPerPage: marketRowsPerPage
        });
    }

    const handlePreviousPage = async (e, args) => {
        if (marketPageNumber > 1)
            getMarkets({
                queryString: marketQueryString,
                pageNumber: marketPageNumber - 1,
                rowsPerPage: marketRowsPerPage
            });
    }

    const handleNextPage = async (e, args) => {
        if (marketPageNumber < marketTotalPages)
            getMarkets({
                queryString: marketQueryString,
                pageNumber: marketPageNumber + 1,
                rowsPerPage: marketRowsPerPage
            });
    }

    const handleDatagridRowClicked = async (args) => {
        infiniteLoader.show();
        marketProductsViewObserver.current({
            data: args.selectedRow
        });
    }

    const handleDatagridRowDoubleClicked = async (args) => {
        const selectedMarket = markets.find(market => market.marketId === args.selectedRow?.marketId);
        setSelectedMarket(selectedMarket);
    }

    return {
        toolbar: (() => {
            return (
                <div className={themes?.toolbarCollectionWrapper}>
                    <div className={"w-50"}>
                        <Toolbar attributes={{
                            name: rightPaneToolbarName,
                            items: [
                                {
                                    name: rightToolbarButtons.filterButtonName,
                                    type: TOOLBARITEMTYPE.SEARCHFIELD,
                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                    leading: icons?.SearchRoundedIcon,
                                    placeholder: "Search",
                                    trailing: icons?.ArrowForwardRoundedIcon,
                                    clear: icons?.ArrowForwardRoundedIcon,
                                    observer: componentObserver,
                                    style: themes?.toolbar?.searchField292px,
                                    onClick: (e, args) => { handleSearchRecords(e, args) },
                                    filter: {
                                        placeholder: "Filter",
                                        leading: icons?.FilterAltRoundedIcon,
                                        onClick: (e, args) => { handleProductFilterRecords(e, args) },
                                    }
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: rightToolbarButtons.previousButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Previous",
                                    leading: icons?.ArrowBackIosRoundedIcon,
                                    onClick: (e, args) => { handlePreviousPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.startDropDownButton
                                },
                                {
                                    name: rightToolbarButtons.nextButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Next",
                                    leading: icons?.ArrowForwardIosRoundedIcon,
                                    onClick: (e, args) => { handleNextPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.endDropDownButton
                                },
                            ]
                        }} />
                    </div>
                    <div className={"w-50 d-flex justify-content-end"}>
                        <Toolbar attributes={{
                            name: leftPaneToolbarName,
                            items: [
                                {
                                    name: leftToolbarButtons.addMarketButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "Add Market",

                                    leading: icons?.AddRoundedIcon,
                                    onClick: (e) => handleCreate(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.editMarketButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Edit",
                                    leading: icons?.EditRoundedIcon,
                                    onClick: (e) => handleEdit(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.deleteMarketButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.DeleteRoundedIcon,
                                    onClick: (e) => handleDelete(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: leftToolbarButtons.reloadMarketButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.ReplayRoundedIcon,
                                    onClick: (e) => handleReload(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: leftToolbarButtons.reloadMarketButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.ThumbUpAltIcon,
                                    onClick: (e) => handlePublish(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.reloadMarketButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "Unpublish",
                                    leading: icons?.ThumbDownAltIcon,
                                    onClick: (e) => handleUnpublish(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                // {
                                //     type: TOOLBARITEMTYPE.SPACE,
                                //     style: themes?.toolbar?.space?.space15
                                // },
                            ]
                        }} />
                    </div>
                </div>
            )
        })(),

        gridViewAttributes: {
            dockType: DOCKTYPE.FILL,
            fixedPane: GRIDPANE?.LEFT,
            paneWidth: themes?.width380px,
            allowAutoScroll: true,
            pane: {
                left: (
                    <div className='position-absolute top-0px start-15px bottom-15px end-10px overflow-hidden'>
                        <div className='position-absolute top-0px start-0px bottom-0px end--10px'>
                            <DataGrid attributes={{
                                name: marketDatagridName,
                                observer: marketsDatagridObserver,
                                key: ["marketId"],
                                columns: [
                                    {
                                        default: "",
                                        style: themes?.width15px
                                    },
                                    {
                                        type: COLUMNTYPE.CHECKBOX,
                                        value: ["marketId"],
                                        style: themes?.width30px
                                    },
                                    {
                                        type: COLUMNTYPE.IMAGETHUMBNAIL,
                                        default: "",
                                        style: themes?.width35px,
                                        image: {
                                            id: "mediaId",
                                            key: "mediaId",
                                            url: "mediaUrl",
                                            size: themes?.size35px,
                                            leading: icons?.StorefrontRoundedIcon,
                                            style: themes?.image,
                                            config: {
                                                downloadUrl: marketService?.downloadUrl,
                                                size: 300
                                            }
                                        }
                                    },
                                    {
                                        default: "",
                                        style: themes?.width15px
                                    },
                                    {
                                        title: "Name",
                                        value: ["marketName"],
                                        default: "",
                                        style: themes?.width100
                                    },
                                    {
                                        type: COLUMNTYPE.TOOLBAR,
                                        items: [
                                            {
                                                name: rightToolbarButtons.previousButton,
                                                type: TOOLBARITEMTYPE.BUTTON,
                                                tooltip: "Preview",
                                                leading: icons?.SearchRoundedIcon,
                                                onClick: (e, args) => { handleEdit(e, args) },
                                                observer: componentObserver,
                                                style: themes?.toolbar?.flatIconButton
                                            },
                                        ],
                                        style: themes?.width45px
                                    }
                                ],
                                rowHeight: 35,
                                isStripped: true,
                                isSelectable: true,
                                dockType: DOCKTYPE.FILL,
                                onRowSelected: handleDatagridRowClicked,
                                onRowDoubleClick: handleDatagridRowDoubleClicked,
                                dataset: markets
                            }} showPaneOnAction={false} />
                        </div>
                    </div>
                ),

                right: (
                    <div className='position-absolute top-3px start-15px bottom-1px end-0px'>
                        <MarketProductsView attributes={{
                            observer: marketProductsViewObserver
                        }} />
                    </div>
                )
            }
        },

        calloutWindowAttributes: {
            title: messages?.handleCreate?.dialogTitle,
            name: createOrEditMarketDialogName,
            data: selectedMarket,
            observer: createOrEditMarketObserver,
            widget: CreateOrEditMarketView,
            slideDirection: SLIDEDIRECTION.RIGHT,
            style: {
                window: "max-width-430px"
            },
            buttons: [
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            infiniteLoader.hide();
                            getMarkets({
                                queryString: marketQueryString,
                                pageNumber: marketPageNumber,
                                rowsPerPage: marketRowsPerPage
                            });

                            setSelectedMarket({});
                            createOrEditMarketObserver.current.close();
                        });
                    }
                },
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                }
            ]
        },

        createOrEditDialog: {
            name: createOrEditMarketDialogName,
            data: selectedMarket,
            observer: createOrEditMarketObserver,
            widget: CreateOrEditMarketView,
            style: {
                window: "max-width-420px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getMarkets({
                                queryString: marketQueryString,
                                pageNumber: marketPageNumber,
                                rowsPerPage: marketRowsPerPage
                            });
                        });
                    }
                }
            ]
        },

    };
}

export default useMarketsHook;