import { useHttps } from '../../../../../shared/utilities';


const ProductColorService = () => {
    const request = useHttps();
    const controller = "/product-colors";

    const getAll = async (data) => await request.post(controller + "/get-all", data);
    const get = async (id) => await request.post(controller + "/get-all", id);
    const search = async (data) => await request.post(controller + "/get-all", data);
    const save = async (data) => await request.post(controller + "/save", data);
    const update = async (data) => await request.post(controller + "/update", data);
    const remove = async (data) => await request.post(controller + "/delete", data);

    return { 
        getAll, 
        get, 
        search,
        save, 
        update,
        remove
    }
}

export { ProductColorService }