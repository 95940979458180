import { useHttps } from '../../../../shared/utilities';


const OrderService = () => {
    const request = useHttps();
    const controller = "/customer-orders";

    return { 
        getAll: async (data) => await request.post(controller + "/get-all", data), 
        getOrderHistory: async (data) => await request.post(controller + "/get-customer-orders", data),
        get: async (id) => await request.post(controller + "/get-all", id),
        getOrderProducts: async (data) => await request.post(controller + "/get-order-products", data),
        search: async (data) => await request.post(controller + "/get-all", data),
        save: async (data) => await request.post(controller + "/save", data), 
        update: async (data) => await request.post(controller + "/update", data),
        remove: async (data) => await request.post(controller + "/delete", data),
        publish: async (data) => await request.post(controller + "/publish", data),
        unpublish: async (data) => await request.post(controller + "/unpublish", data),
        sendToArchive: async (data) => await request.post(controller + "/archive", data),
        restoreArchive: async (data) => await request.post(controller + "/restore-archive", data)
    }
}

export { OrderService }