import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { FaSort } from "react-icons/fa";
import { ALIGNMENTTYPE, COLUMNTYPE, COMPONENTYPE, GRIDVIEWTYPE, DOCKTYPE, SORTENUM, TOOLBARITEMTYPE, MENUALIGNMENTTYPE } from "../../enums/enums";
import StatusBar from "../status.bar/status.bar.component";
import { Toolbar } from "../toolbar/toolbar.component";
import { useModalDialog } from "../dialog/modal.dialog.component";
import ToggleIcon from "../toggle.icon/toggle.icon.component";
import DatagridImage from "../image/datagrid.image.component";
import Flag from "../flag/flag.component";
import { usePrimaryUIThemes } from "../../hooks/ui.themes";
import { useIconsThemes } from "../../hooks/ui.icons.hook";
import { DatagridColumnCellField } from "../field/datagrid.column.cell.field.component";
import { SingleSelectDropDownField } from "../field/single.select.dropdown.field.component";
import Button from "../button/button.component";

const DataGrid = forwardRef(({ attributes, dataset, onRowClicked }, ref) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    const [datalist, setDatalist] = useState();

    if (attributes?.filter)
        attributes.filter["name"] = attributes?.name + "-filter-dialog";

    // adapters
    const componentObserver = useRef();
    const tableHeaderRef = useRef();
    const datagridHeader = useRef();
    const datagridHeaderColumnWidthList = useRef([]);

    const pageToolbarRowsPerPageDropDownFieldObserver = useRef();

    const datagrid = useDataGrid(attributes);

    useImperativeHandle(attributes?.observer, () => ({
        updateList(dataset) {
            setDatalist(dataset);
            attributes.dataset = dataset;
        },

        clear() {
            attributes.dataset = [];
        },

        selectDefault() {
            setTimeout(() => datagrid?.selectDefaultRow({
                dblClick: () => {
                    try {
                        if (attributes?.onRowSelected) {
                            attributes?.onRowSelected({
                                selectedRow: dataset[0]
                            });
                        }
                    } catch (e) {
                        //
                    }
                }
            }), 100);
        }
    }))

    useEffect(() => {
        //
    }, [])

    const getDockStyle = (type) => {
        switch (type) {
            case DOCKTYPE.BOTTOM:
                return "position-absolute start-0px bottom-0px end-0px";
            case DOCKTYPE.FILL:
                return "position-absolute top-0px start-0px bottom-0px end-0px";
            case DOCKTYPE.LEFT:
                return "position-absolute top-0px start-0px bottom-0px";
            case DOCKTYPE.RIGHT:
                return "position-absolute top-0px bottom-0px end-0px";
            case DOCKTYPE.TOP:
                return "position-absolute top-0px start-0px end-0px";
            default:
                return;
        }
    }

    const selectRow = (e) => {

        const tr = e;
        const tbody = tr.parentNode;
        const rows = tbody.querySelectorAll("*[aria-controls=\"table-row\"]");
        const selectedCheckboxes = [];
        if (attributes?.isSingleSelect) {
            [...rows].forEach(row => {
                const checkbox = row.querySelector("input[type=\"checkbox\"]");
                checkbox.checked = false;
            });

            const selectedCheckedBox = e.querySelector("input[type=\"checkbox\"]");
            selectedCheckedBox.checked = true;

            // hidden field
            const field = tbody.parentNode.parentNode.querySelector("input[type=\"hidden\"]");
            field.value = selectedCheckedBox.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute("id");
        } else {
            [...rows].forEach(row => {
                const checkbox = row.querySelector("input[type=\"checkbox\"]");

                // build selected checkedbox array
                if (checkbox.checked) {
                    selectedCheckboxes.push(row.getAttribute("id"));
                }
            });

            // hidden field
            const field = tbody.parentNode.parentNode.querySelector("input[type=\"hidden\"]");
            field.value = JSON.stringify(selectedCheckboxes);
        }
    }

    const toggleHeaderCheckbox = (e) => {
        if (!e.currentTarget.checked) {
            datagrid.unselectAllRow();
        } else {
            datagrid.selectAllRow();
        }
    }

    let handleCardClick;
    const handleRowClick = handleCardClick = (el, dataObject, evt, preventLastEvent) => {
        if (attributes?.isSelectable) {
            datagrid.selectRow(el);
        }

        if (!preventLastEvent) {
            if (attributes?.onRowSelected) {
                attributes?.onRowSelected({
                    selectedRow: dataObject
                }, evt)
            }
            else if (attributes?.onRowClick) {
                attributes?.onRowClick({
                    selectedRow: dataObject
                }, evt);
            }
        }
    }

    let handleCardDoubleClick;
    const handleRowDoubleClick = handleCardDoubleClick = (e, dataObject, evt) => {
        if (attributes?.onRowDoubleClick) {
            attributes?.onRowDoubleClick({
                selectedRow: dataObject
            }, evt);
        }
    }

    const handleRowColumnClick = (e, args) => {
        if (onRowClicked) {
            args["rowIndex"] = e.getAttribute("aria-rowindex");
            onRowClicked(args);
        }
    }

    const handleCheckBoxClick = (e, dataObject) => {
        datagrid.selectCheckbox(e.currentTarget);
        e.stopPropagation();
    }

    const handleSortColumnClick = (e, args) => {
        if (attributes?.onColumnSortButtonClick)
            attributes?.onColumnSortButtonClick(e, {
                column: args?.value[0],
                sortOrder: SORTENUM.ASCENDING
            });
    }

    const handleOnRowColumnCellLostFocus = (args) => {
        if (attributes?.onRowColumnCellLostFocus)
            attributes?.onRowColumnCellLostFocus(args);
    }

    // reuseable ui components/widgets
    // 
    const tableHeader = () => {
        return (
            <table className={"table table-striped- mb-0 text-13px w-100 box-sizing-border- text-uppercase table-layout-fixed-"}>
                <thead>
                    <tr aria-controls="table-header"
                        ref={tableHeaderRef}
                        className={"border border-top-0px border-start-0px border-bottom-0px transparent-border-blue-dark-theme border-end-0px "}>
                        <td className={"w-100 ps-0 pe-0 border-0px"}>
                            <div className={"w-100 d-flex justify-content-start align-items-center overflow-x-hidden overflow-y-scroll"}>
                                {
                                    (() => {
                                        return attributes?.columns?.map((column, i) => {
                                            return (
                                                <div onClick={(e) => handleSortColumnClick(e, column)} aria-controls="header-column" aria-colindex={"header-column-" + i} key={"table-header-column-" + i} className={"cursor-pointer " + column?.style}>
                                                    <div className={"text-12px w-100 text-bold d-flex align-items-center"}>
                                                        {
                                                            (() => {
                                                                if (column.type === COLUMNTYPE.BUTTON) {
                                                                    return (
                                                                        <div className={"d-flex align-items-center " + (column?.style ? column?.style : "width-80px")}></div>
                                                                    )
                                                                }
                                                                else if (column?.type === COLUMNTYPE.VIDEOTHUMBNAIL) {
                                                                    return (
                                                                        <div className={"d-flex align-items-center " + column?.style.value}></div>
                                                                    )
                                                                }
                                                                else if ((column?.type === COLUMNTYPE.CHECKBOX) || (column?.type === COLUMNTYPE.READONLYCHECKBOX)) {
                                                                    return (
                                                                        <div className={"d-flex align-items-center " + column?.style}>
                                                                            <input
                                                                                onClick={toggleHeaderCheckbox}
                                                                                className={"form-check-input rounded-1 width-15px height-15px cursor-pointer m-0"}
                                                                                type={"checkbox"}
                                                                                disabled={column?.type === COLUMNTYPE.READONLYCHECKBOX}
                                                                                value={""}
                                                                                id={"flexCheckDefault"}
                                                                                name={"flexCheckDefault"}
                                                                            />
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        // <div className={"d-flex justify-content-between align-items-center " + column?.style}>
                                                                        <div className={"d-flex justify-content-between align-items-center w-100"}>
                                                                            <div className={"white-space-nowrap me-1"}>{column?.title}</div>
                                                                            <div className={"d-flex text-12px me-0 " + (column?.title ? "" : "hide")}>
                                                                                <FaSort />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            })()
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })
                                    })()
                                }
                            </div>
                        </td>
                    </tr>
                </thead>
            </table>
        );
    }

    const tableRow = ({ auto, attributes, dataObject, onRowColumnClick, isFirstOrDefault, isExpanded }) => {
        if (dataObject[attributes.key]) {

            datagridHeader.current = componentObserver.current.querySelector("div[aria-controls=\"datagrid-header\"]");
            if (datagridHeader.current) {
                const columns = datagridHeader?.current?.querySelectorAll("div[aria-controls=\"header-column\"]");

                [...columns].forEach(column => {
                    datagridHeaderColumnWidthList.current.push(column.offsetWidth);
                });
            }

            // 
            return (
                <tr
                    aria-controls="table-row"
                    key={dataObject[attributes.key] + "-" + auto}
                    aria-rowindex={auto}
                    id={dataObject[attributes.key]}
                    aria-selected={"false"}
                    className={"w-100"}>
                    <td className={"border-0px p-0"}>
                        {/* row header */}
                        <div
                            onClick={(e) => handleRowClick(e.currentTarget.parentNode.parentNode, dataObject, e)}
                            onDoubleClick={(e) => handleRowDoubleClick(e.currentTarget.parentNode.parentNode, dataObject, e)}
                            aria-controls="table-row-header"
                            aria-atomic={((attributes?.isStripped && (auto % 2 === 1)) ? "bg-blue-light-theme" : "bg-white-theme")}
                            className={"w-100 padding-top-1px padding-bottom-1px ps-0 box-sizing-border d-flex justify-content-start align-items-center cursor-pointer " + (isFirstOrDefault ? "" : "") + ((attributes?.isStripped && (auto % 2 === 1)) ? "bg-blue-light-theme" : "")}>
                            {
                                (() => {
                                    return attributes?.columns?.map((column, i) => {
                                        return (
                                            <div
                                                onMouseUp={(e) => onRowColumnClick(e.currentTarget.parentNode.parentNode.parentNode, dataObject)}
                                                aria-controls={"row-column"}
                                                aria-colindex={"row-column-" + i}
                                                aria-label={column?.value}
                                                key={dataObject[attributes.key.column] + "-column-" + i}
                                                className={"d-flex align-items-center box-sizing-border " + column?.style + " min-height-" + attributes?.rowHeight + "px max-width-" + datagridHeaderColumnWidthList.current[i] + "px"}>
                                                {
                                                    (() => {
                                                        let columnText = dataObject[column?.value]?.toString() || "";

                                                        if ((column?.type === COLUMNTYPE.CHECKBOX)) {
                                                            const primaryKey = attributes?.key;
                                                            return (
                                                                <input
                                                                    onClick={(e) => handleCheckBoxClick(e, dataObject)}
                                                                    className={"form-check-input rounded-1 width-15px height-15px cursor-pointer m-0"}
                                                                    type={"checkbox"}
                                                                    aria-label={column?.value}
                                                                    disabled={column?.type === COLUMNTYPE.READONLYCHECKBOX}
                                                                    value={dataObject[primaryKey]}
                                                                    id={dataObject[primaryKey]}
                                                                    name={dataObject[primaryKey]} />
                                                            )
                                                        }
                                                        else if (column?.type === COLUMNTYPE.VIDEOTHUMBNAIL) {
                                                            return (
                                                                <div className={"position-relative d-flex align-items-center " + column?.style.thumbnail}>
                                                                    <div className={"border border-1px position-absolute top-0px start-0px bottom-0px end-0px bg-white rounded-4px"}></div>
                                                                </div>
                                                            )
                                                        }
                                                        else if (column.type === COLUMNTYPE.TOOLBAR) {
                                                            const primaryKey = attributes?.key;
                                                            return (
                                                                <DataGridToolbar attributes={{
                                                                    key: dataObject[primaryKey],
                                                                    items: column.items,
                                                                    baseClickHandler: (e) => {
                                                                        handleRowClick(e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode, dataObject, e, true);
                                                                    }
                                                                }} />
                                                            )
                                                        }
                                                        else if (column.type === COLUMNTYPE.TEXTFIELD) {
                                                            const primaryKey = attributes?.key;
                                                            const columnObject = {};
                                                            columnObject[primaryKey] = dataObject[primaryKey];
                                                            columnObject[column.key] = "";
                                                            return (
                                                                <DatagridColumnCellField attributes={{
                                                                    name: dataObject[primaryKey] + "-textfield",
                                                                    key: dataObject[primaryKey],
                                                                    value: columnText,
                                                                    ariaLabel: column?.value,
                                                                    style: themes?.field?.datagridColumnCellField,
                                                                    onLostFocus: (e) => handleOnRowColumnCellLostFocus(((e) => {
                                                                        const columnObject = {};
                                                                        columnObject[primaryKey] = dataObject[primaryKey];
                                                                        columnObject[column.key] = e;
                                                                        return columnObject;
                                                                    })(e))
                                                                }} />
                                                            )
                                                        }
                                                        else if (column.type === COLUMNTYPE.BUTTON) {
                                                            return (
                                                                <div className={"d-flex align-items-center justify-content-end " + (column?.style ? column?.style : "width-80px")}>
                                                                    {
                                                                        (() => {
                                                                            return column.leading
                                                                        })()
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        else if (column.type === COLUMNTYPE.AUTO) {
                                                            return (
                                                                <div className={"white-space-nowrap text-overflow-ellipsis pt-1 pb-1 " + column?.style.value}>{auto + 1}</div>
                                                            )
                                                        }
                                                        else if (column.type === COLUMNTYPE.DATETIME) {
                                                            return (
                                                                <div aria-controls="datetime-cell" className={"white-space-nowrap text-overflow-ellipsis pt-1 pb-1 " + column?.style.value}>{columnText}</div>
                                                            )
                                                        }
                                                        else if (column.type === COLUMNTYPE.ICON) {
                                                            return (
                                                                <ToggleIcon attributes={{
                                                                    leading: column?.leading
                                                                }} />
                                                            )
                                                        }
                                                        else if (column.type === COLUMNTYPE.IMAGETHUMBNAIL) {
                                                            return (
                                                                <DatagridImage attributes={column?.image} model={dataObject} />
                                                            )
                                                        }
                                                        else if (column.type === COLUMNTYPE.INDICATOR) {
                                                            return (
                                                                <StatusBar attributes={{
                                                                    status: columnText ? JSON.parse(columnText) : ""
                                                                }} />
                                                            )
                                                        }
                                                        else if (column.type === COLUMNTYPE.FLAG) {
                                                            return (
                                                                <Flag attributes={{
                                                                    value: columnText || 0,
                                                                    label: dataObject[column?.label]?.toString() || "",
                                                                    flags: column?.flags,
                                                                    showLabel: column?.showLabel
                                                                }} />
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <div className={"w-100 d-flex justify-content-between"}>
                                                                    <div className={"w-100 flex-column"}>
                                                                        <input
                                                                            className={"form-check-input rounded-1 width-15px height-15px cursor-pointer m-0"}
                                                                            type={"hidden"}
                                                                            aria-label={column?.value}
                                                                            value={columnText} />
                                                                        <div className={"text-truncate pt-1 pb-1 w-100- box-sizing-border " + ((column?.textAlign === ALIGNMENTTYPE.RIGHT) ? "text-end" : "")}>{columnText}</div>
                                                                        {
                                                                            (() => {
                                                                                if (column?.widget) {
                                                                                    return (
                                                                                        <div className={"white-space-nowrap text-overflow-ellipsis text-11px opacity-8 pt-0 pb-1 w-100 box-sizing-border " + (column?.widget ? "" : "hide")}>
                                                                                            {column?.widget}
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            })()
                                                                        }
                                                                    </div>
                                                                    <div className={"width-3px me-1 "}></div>
                                                                </div>
                                                            )
                                                        }
                                                    })()
                                                }
                                            </div>
                                        );
                                    })
                                })()
                            }
                        </div>
                    </td>
                </tr>
            );
        }
    }

    const gridCard = ({ auto, attributes, dataObject, onCardClick, isFirstOrDefault, isExpanded }) => {
        if (dataObject[attributes.key]) {
            return (
                <div
                    aria-controls="table-row"
                    key={dataObject[attributes.key] + "-" + auto}
                    aria-rowindex={auto}
                    id={dataObject[attributes.key]}
                    aria-selected={"false"}
                    className={"w-100- float-start"}>
                    <div
                        onClick={(e) => handleCardClick(e.currentTarget.parentNode, dataObject)}
                        onDoubleClick={(e) => handleCardDoubleClick(e.currentTarget.parentNode.parentNode, dataObject)}
                        aria-controls="table-row-header"
                        aria-atomic={((attributes?.isStripped && (auto % 2 === 1)) ? "bg-blue-light-theme-" : "bg-white-theme")}
                        className={"w-100 padding-top-1px rounded-5px padding-bottom-1px ps-0 box-sizing-border d-flex- justify-content-start align-items-center cursor-pointer " + (isFirstOrDefault ? "" : "") + ((attributes?.isStripped && (auto % 2 === 1)) ? "bg-blue-light-theme-" : "")}>
                        {
                            (() => {
                                return attributes?.columns?.map((column, i) => {
                                    return (
                                        <div
                                            onClick={(e) => onCardClick(e.currentTarget.parentNode.parentNode.parentNode, dataObject)}
                                            aria-controls={"row-column"}
                                            aria-colindex={"row-column-" + i}
                                            key={dataObject[attributes.key.column] + "-column-" + i}
                                            className={((column?.type === COLUMNTYPE.CHECKBOX) ? "position-relative" : "") + column?.style}>
                                            {
                                                (() => {
                                                    let columnText = dataObject[column?.value]?.toString() || "";
                                                    if (column?.type === COLUMNTYPE.CHECKBOX) {
                                                        const primaryKey = attributes?.key;
                                                        return (
                                                            <div className={"position-absolute height-0px width-0px top-0 start-0 overflow-hidden"}>
                                                                <input
                                                                    onClick={(e) => handleCheckBoxClick(e, dataObject)}
                                                                    className={"form-check-input rounded-1 width-15px height-15px cursor-pointer m-0"}
                                                                    type={"checkbox"}
                                                                    disabled={column?.type === COLUMNTYPE.READONLYCHECKBOX}
                                                                    value={dataObject[primaryKey]}
                                                                    id={dataObject[primaryKey]}
                                                                    name={dataObject[primaryKey]}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    else if (column?.type === COLUMNTYPE.VIDEOTHUMBNAIL) {
                                                        return (
                                                            <div className={"position-relative d-flex align-items-center " + column?.style.thumbnail}>
                                                                <div className={"border border-1px position-absolute top-0px start-0px bottom-0px end-0px bg-white rounded-4px"}></div>
                                                            </div>
                                                        )
                                                    }
                                                    else if (column.type === COLUMNTYPE.TOOLBAR) {
                                                        const primaryKey = attributes?.key;
                                                        return (
                                                            <Toolbar attributes={{
                                                                key: dataObject[primaryKey],
                                                                items: column.items,
                                                                baseClickHandler: (e) => {
                                                                    const tableRow = e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
                                                                    selectRow(tableRow);
                                                                }
                                                            }} />
                                                        )
                                                    }
                                                    else if (column.type === COLUMNTYPE.TEXTFIELD) {
                                                        const primaryKey = attributes?.key;
                                                        return (
                                                            <DatagridColumnCellField attributes={{
                                                                name: dataObject[primaryKey] + "-textfield",
                                                                key: dataObject[primaryKey],
                                                                value: columnText,
                                                                style: themes?.field?.datagridColumnCellField
                                                            }} />
                                                        )
                                                    }
                                                    else if (column.type === COLUMNTYPE.BUTTON) {
                                                        return (
                                                            <div className={"d-flex align-items-center justify-content-end " + (column?.style ? column?.style : "width-80px")}>
                                                                {
                                                                    (() => {
                                                                        return column.leading
                                                                    })()
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    else if (column.type === COLUMNTYPE.AUTO) {
                                                        return (
                                                            <div className={"white-space-nowrap text-overflow-ellipsis pt-1 pb-1 " + column?.style.value}>{auto + 1}</div>
                                                        )
                                                    }
                                                    else if (column.type === COLUMNTYPE.DATETIME) {
                                                        return (
                                                            <div aria-controls="datetime-cell" className={"white-space-nowrap text-overflow-ellipsis pt-1 pb-1 " + column?.style.value}>{columnText}</div>
                                                        )
                                                    }
                                                    else if (column.type === COLUMNTYPE.ICON) {
                                                        return (
                                                            <ToggleIcon attributes={{
                                                                leading: column?.leading
                                                            }} />
                                                        )
                                                    }
                                                    else if (column.type === COLUMNTYPE.IMAGETHUMBNAIL) {
                                                        return (
                                                            <DatagridImage attributes={column?.image} model={dataObject} />
                                                        )
                                                    }
                                                    else if (column.type === COLUMNTYPE.INDICATOR) {
                                                        return (
                                                            <StatusBar attributes={{
                                                                status: columnText ? JSON.parse(columnText) : ""
                                                            }} />
                                                        )
                                                    }
                                                    else if (column.type === COLUMNTYPE.FLAG) {
                                                        return (
                                                            <Flag attributes={{
                                                                value: dataObject[column?.value] ? parseInt(dataObject[column?.label]) : 0,
                                                                label: dataObject[column?.label]?.toString() || "",
                                                                flags: column?.flags
                                                            }} />
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <div className={((columnText === "") ? "hide- " : "") + " w-100 d-flex justify-content-between"}>
                                                                <div className={"w-100 flex-column"}>
                                                                    <div className={"min-height-45px text-truncate- text-normal pt-1 pb-1 ps-1 pe-2 w-100- box-sizing-border " + ((column?.textAlign === ALIGNMENTTYPE.RIGHT) ? "text-end" : "")}>{columnText}</div>
                                                                    {
                                                                        (() => {
                                                                            if (column?.widget) {
                                                                                return (
                                                                                    <div className={"white-space-nowrap text-overflow-ellipsis text-11px opacity-8 pt-0 pb-1 w-100 box-sizing-border " + (column?.widget ? "" : "hide")}>
                                                                                        {column?.widget}
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })()
                                                                    }
                                                                </div>
                                                                <div className={"width-3px me-1 "}></div>
                                                            </div>
                                                        )
                                                    }
                                                })()
                                            }
                                        </div>
                                    );
                                })
                            })()
                        }
                    </div>
                </div>
            );
        }
    }

    const placeholderView = (placeholder) => {
        return (
            <tr className={"w-100"}>
                <td className={"border-0px p-0"}>
                    <div className={"height-200px"}>
                        <div className={((placeholder?.showImage) ? "" : "hide") + " d-flex justify-content-center align-items-center mx-auto height-180px max-width-220px illustration-404-error image-contain"}></div>
                        <div className={"d-flex justify-content-center align-items-center mx-auto max-width-350px mt-5"}>{(placeholder?.text) ? placeholder.text : "No records available."}</div>
                    </div>
                </td>
            </tr>
        );
    }

    const preloaderView = () => {
        return (
            <tr className={"w-100"}>
                <td className={"border-0px p-0"}>
                    <div className={"d-flex justify-content-center align-items-center mx-auto height-250px width-250px illustration-404-error image-contain"}></div>
                    <div className={"d-flex justify-content-center align-items-center mx-auto max-width-350px mt-0"}>Loading, please wait...</div>
                </td>
            </tr>
        );
    }

    return (
        <div className={"d-flex " + getDockStyle(attributes?.dockType)} aria-controls={"table-grid"} ref={componentObserver} id={"dg-" + attributes?.name} data-key={attributes?.key} aria-posinset={attributes?.isStripped} aria-multiselectable={attributes?.isSelectable}>
            <div className={"w-100 position-relative"}>
                {
                    (() => {
                        if ((!attributes?.hasOwnProperty("showHeader") || attributes?.showHeader) && attributes?.viewType !== GRIDVIEWTYPE.CARD) {
                            return (
                                <div aria-controls="datagrid-header" className={((attributes?.dockType === DOCKTYPE.FILL) ? "d-flex position-absolute top-0px start-0px end-0px height-35px z-index-0" : "")}>
                                    {
                                        tableHeader()
                                    }
                                    <div className={((attributes?.dockType === DOCKTYPE.FILL) ? "position-absolute bottom-0px start-0px end-0px z-index-1 height-1px transparent-bg-blue-dark-theme" : "position-relative")}></div>
                                </div>
                            )
                        }
                    })()
                }
                <div aria-owns={attributes?.name} className={((attributes?.dockType === DOCKTYPE.FILL) ? (((!attributes.hasOwnProperty("showHeader") || attributes?.showHeader) && attributes?.viewType !== GRIDVIEWTYPE.CARD) ? "top-35px " : "top-5px ") + "position-absolute start-0px end-0px bottom-0px " + (attributes?.floatingBar?.showingPaging ? "padding-bottom-80px" : "") + " overflow-x-hidden- " + ((attributes?.hasOwnProperty("allowScrolling") && attributes?.allowScrolling === false) ? "" : "overflow-y-auto") + " pb-4" : "position-relative pb-4")}>
                    <input
                        name={attributes?.id}
                        id={attributes?.id}
                        aria-owns={attributes?.name}
                        aria-controls={attributes?.formElement}
                        aria-roledescription={COMPONENTYPE.DATAGRID}
                        aria-required={attributes?.validate}
                        type={"hidden"} />
                    {
                        (() => {
                            if (attributes?.viewType !== GRIDVIEWTYPE.CARD) {
                                return (
                                    <table className={"table table-hover- w-100 mb-0 text-13px box-sizing-border table-layout-fixed"}>
                                        <tbody>
                                            {
                                                (() => {
                                                    preloaderView();

                                                    if (attributes?.dataset?.length > 0) {
                                                        return attributes?.dataset?.map((data, i) => {
                                                            return tableRow({
                                                                isFirstOrDefault: (i === 0),
                                                                auto: i,
                                                                key: (data[attributes.key.column] + "-" + i),
                                                                attributes: attributes,
                                                                dataObject: data,
                                                                onRowColumnClick: handleRowColumnClick
                                                            });
                                                        })
                                                    } else {
                                                        return placeholderView(attributes?.placeholder);
                                                    }
                                                })()
                                            }
                                        </tbody>
                                    </table>
                                );
                            } else {
                                return (
                                    <div className={"d-flex- "}>
                                        {
                                            (() => {
                                                preloaderView();
                                                if (attributes?.dataset?.length > 0) {
                                                    return attributes?.dataset?.map((data, i) => {
                                                        return gridCard({
                                                            isFirstOrDefault: (i === 0),
                                                            auto: i,
                                                            key: (data[attributes.key.column] + "-" + i),
                                                            attributes: attributes,
                                                            dataObject: data,
                                                            onCardClick: (e, data) => { }
                                                        });
                                                    })
                                                } else {
                                                    return placeholderView(attributes?.placeholder);
                                                }
                                            })()
                                        }
                                    </div>
                                );
                            }
                        })()
                    }

                    <div className={(attributes?.floatingBar?.page ? "" : "hide") + " mx-auto mb-4 height-50px d-flex justify-content-center"}>
                    </div>
                </div>

                {/* loader */}
                <div aria-controls="loader-controls" className={"hide transparent-bg-white-theme position-absolute top-0px start-0px bottom-0px end-0px z-index-10 bottom-0px mx-auto d-flex justify-content-center align-items-center"}>
                    <div className={"transparent-bg-white-theme- shadow-lg- mx-auto min-height-50px p-4"}>
                        <div className="spinner-border border-2px text-blue-theme icon-50px" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>

                {/* page */}
                <div aria-controls="page-controls" className={(attributes?.floatingBar?.page ? "" : "hide") + " position-absolute start-0px end-0px bottom-15px mx-auto d-flex justify-content-center"}>
                    <div className={"bg-white-theme rounded-3 ps-2 pe-2 pt-2 pb-2 shadow-lg mx-auto d-flex justify-content-center border border-start-1px border-top-1px border-bottom-1px border-end-1px"}>
                        {
                            (() => {
                                if (attributes?.floatingBar?.page?.rowsPerPageList) {
                                    return (
                                        <div className="d-flex justify-content-center">
                                            <div className="d-flex justify-item-between align-items-center ms-1 me-3 text-blue-theme">
                                                <div className="text-13px me-2">Show</div>
                                                <div className="width-70px">
                                                    <SingleSelectDropDownField
                                                        attributes={{
                                                            name: "page-toolbar-rowsperpage-dropdownlist-field",
                                                            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
                                                            placeholder: "Select a category",
                                                            observer: pageToolbarRowsPerPageDropDownFieldObserver,
                                                            style: themes?.field?.singleSelectDropDownField,
                                                            formElement: true,
                                                            key: "rowValue",
                                                            title: "rowTitle",
                                                            value: "rowValue",
                                                            items: attributes?.floatingBar?.page?.rowsPerPageList,
                                                            isSearchable: false,
                                                            menuPosition: MENUALIGNMENTTYPE.TOPLEFT,
                                                            model: (attributes?.floatingBar?.page?.rowsPerPageList?.find(x => x.default === true)) || {},
                                                            onItemClick: (e, args) => { attributes?.floatingBar?.page?.onRowsPerPageClick(e, args) }
                                                        }} />
                                                </div>
                                                <div className="text-13px ms-2">records</div>
                                            </div>

                                            <div className="ms-2 me-2 width-1px height-35px border border-top-0px border-bottom-0px border-start-0px border-end-1px"></div>
                                        </div>
                                    );
                                }
                            })()
                        }

                        {
                            (() => {
                                const items = [];
                                items.push({
                                    name: attributes?.id + "-page-toolbar-pages-label",
                                    type: TOOLBARITEMTYPE.PAGEFIELDBUTTON,
                                    page: attributes?.floatingBar?.page,
                                    style: themes?.toolbar?.pageFieldButton
                                });
                                items.push({
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                });

                                if (attributes?.floatingBar?.page?.onReloadClick) {
                                    items.push({
                                        name: attributes?.id + "-page-toolbar-home-button",
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Reload",
                                        leading: icons?.ReplayRoundedIcon,
                                        onClick: (e, args) => { attributes?.floatingBar?.page?.onReloadClick(e, args) },
                                        style: themes?.toolbar?.startDropDownButton
                                    });
                                }

                                if (attributes?.floatingBar?.page?.onHomeClick) {
                                    items.push({
                                        name: attributes?.id + "-page-toolbar-home-button",
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Home",
                                        leading: icons?.HomeRoundedIcon,
                                        onClick: (e, args) => { attributes?.floatingBar?.page?.onHomeClick(e, args) },
                                        style: themes?.toolbar?.startDropDownButton
                                    });
                                }

                                items.push({
                                    name: attributes?.id + "-page-toolbar-previous-button",
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Previous",
                                    leading: icons?.ArrowBackIosRoundedIcon,
                                    onClick: (e, args) => { attributes?.floatingBar?.page?.onPreviousClick(e, args) },
                                    style: themes?.toolbar?.startDropDownButton
                                });
                                items.push({
                                    name: attributes?.id + "-page-toolbar-next-button",
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Next",
                                    leading: icons?.ArrowForwardIosRoundedIcon,
                                    onClick: (e, args) => { attributes?.floatingBar?.page?.onNextClick(e, args) },
                                    style: themes?.toolbar?.endDropDownButton
                                });
                                items.push({
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space5
                                });


                                return (
                                    <Toolbar attributes={{
                                        name: attributes?.id + "-page-toolbar",
                                        items: items
                                    }} />
                                );
                            })()
                        }
                    </div>
                </div>

            </div>
        </div >
    )

})

const DataGridToolbar = ({ attributes }) => {

    const toolbarRef = useRef()

    useEffect(() => {
        (async () => {
            if (attributes?.observer)
                attributes.observer.current = await notifier;
        })();
    }, [attributes])

    const notifier = (e) => {
        // 
    }

    return (
        <div id={attributes?.name} ref={toolbarRef} className={"d-flex"} aria-rowindex={attributes?.key}>
            <div className={"position-relative d-flex justify-content-center align-items-center ps-0 pe-0"}>
                {
                    (() => {
                        return attributes?.items?.map((itemAttributes, i) => {
                            itemAttributes["trigger"] = attributes?.trigger;
                            itemAttributes["name"] = attributes?.name + "-" + itemAttributes?.name;
                            itemAttributes["baseClickHandler"] = (e) => {
                                if (attributes?.baseClickHandler) {
                                    attributes?.baseClickHandler(e);
                                }
                            };

                            return (
                                <div key={i}>
                                    {
                                        (() => {
                                            switch (itemAttributes.type) {
                                                case TOOLBARITEMTYPE.LABELLEDICON:
                                                    return (<LabelledIcon attributes={itemAttributes} />);
                                                case TOOLBARITEMTYPE.LABEL:
                                                    return (<Label attributes={itemAttributes} />);
                                                case TOOLBARITEMTYPE.DROPDOWNBUTTON:
                                                    return (<DropDownButton attributes={itemAttributes} />);
                                                case TOOLBARITEMTYPE.DROPDOWNMENUBUTTON:
                                                    return (<DropDownMenuButton attributes={itemAttributes} />);
                                                case TOOLBARITEMTYPE.SPACE:
                                                    return (<div className={itemAttributes?.style}></div>);
                                                case TOOLBARITEMTYPE.SEARCHFIELD:
                                                    return (<SearchField attributes={itemAttributes} />);
                                                case TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTBUTTON:
                                                    return (<SelectableDropDownListButton attributes={itemAttributes} />);
                                                case TOOLBARITEMTYPE.UPLOADBUTTON:
                                                    return (<UploadButton attributes={itemAttributes} />);
                                                case TOOLBARITEMTYPE.PAGEFIELDBUTTON:
                                                    return (<PageFieldButton attributes={itemAttributes} />);
                                                default:
                                                    return (<Button attributes={itemAttributes} />);
                                            };
                                        })()
                                    }
                                </div>
                            )
                        })
                    })()
                }
            </div>
        </div>
    );
}

const useDataGrid = (attributes) => {

    const selectedRowTheme = "bg-blue-lighter-theme";

    // dialogs
    const filterDialogName = attributes?.name + "-filter-dialog";
    const filterDialog = useModalDialog({
        name: filterDialogName
    });

    const addSelectedRow = () => {

    }

    const addSelectedRows = () => {

    }

    const clearSelection = () => {
        const dgrid = target();
        if (dgrid) {
            const thead = dgrid.querySelector("thead").querySelector("*[aria-controls=\"table-header\"]");
            const checkAllBox = thead.querySelector("input[type=\"checkbox\"]");
            checkAllBox.checked = false;

            const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
            [...selectedRows].forEach(row => {
                const rowHeader = row.querySelector("*[aria-controls=\"table-row-header\"]");
                rowHeader.classList.remove(selectedRowTheme);
                rowHeader.classList.add(rowHeader.getAttribute("aria-atomic"));
                row?.removeAttribute("aria-selected");
                const checkbox = row.querySelector("input[type=\"checkbox\"]");
                if (checkbox)
                    checkbox.checked = false;
            });
        }
    }

    const getKey = () => {
        const dgrid = target();
        return dgrid.getAttribute("data-key");
    }

    const getPaging = () => {
        const _target = target();
        return {
            pageNumber: parseInt(_target.getAttribute("pagenumber")) || attributes.page.pageNumber,
            rowsPerPage: parseInt(_target.getAttribute("rowsperpage")) || attributes.page.rowsPerPage,
            totalPages: parseInt(_target.getAttribute("totalpages")),
            totalRows: parseInt(_target.getAttribute("totalrows"))
        };
    }

    const getSelectedRow = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.find(x => x.checked);
        }
    }

    const getSelectedRowIndex = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const selectedRow = tbody.querySelector("tr[aria-selected=\"true\"]");
        if (selectedRow) {
            const selectedIndex = selectedRow.getAttribute("aria-rowindex");
            return selectedIndex;
        } else {
            return 0;
        }
    }

    const getSelectedRowKeyValue = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.filter(x => x.checked)[0].value;
        }
    }

    const getSelectedRowsKeyValue = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.filter(x => x.checked).map(item => item.value);
        }
    }

    const getSelectedRows = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.filter(x => x.checked);
        }
    }

    const getRowObjects = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const rows = tbody.querySelectorAll("tr");
        const rowArray = [];
        if (rows.length > 0) {
            rows.forEach(row => {
                const fieldObject = {};
                const fields = row.querySelectorAll("input, div[contentEditable]");
                fields.forEach(field => {
                    if (field.getAttribute("aria-label")) {
                        if ((field.tagName.toLowerCase()) === "input") {
                            fieldObject[field.getAttribute("aria-label")] = field.value;
                        } else {
                            fieldObject[field.getAttribute("aria-label")] = field.innerHTML;
                        }
                    }
                });
                rowArray.push(fieldObject);
            });
        }
        return rowArray;
    }

    const headerColumns = () => {
        const dgrid = target();
        const thead = dgrid?.querySelector("thead");
        if (thead) {
            const columns = thead.querySelectorAll("*[aria-controls=\"header-column\"]");
            return [...columns];
        } else {
            return [];
        }
    }

    const hideLoader = () => {
        const dgrid = target();
        if (dgrid) {
            const loader = dgrid.querySelector("div[aria-controls=\"loader-controls\"]");
            loader.classList.add("hide");
        }
    }

    const hideProgress = () => {
        const dgrid = target();
        if (dgrid) {
            const loader = dgrid.querySelector("div[aria-controls=\"progress-controls\"]");
            loader.classList.add("hide");
        }
    }

    const isRowSelected = () => {

    }

    const itemsCount = () => {
        const dgrid = target();
        if (dgrid) {
            const tbody = dgrid.querySelector("tbody");
            const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
            return checkboxes.length;
        }
        return 0;
    }

    const nextPage = () => {
        const _target = target();
        const currentPageNumber = parseInt(_target.getAttribute("pagenumber"));
        const totalPages = parseInt(_target.getAttribute("totalpages"));
        if (currentPageNumber < totalPages) {
            _target.setAttribute("pagenumber", currentPageNumber + 1);
        }
    }

    const previousPage = () => {
        const _target = target();
        const currentPageNumber = parseInt(_target.getAttribute("pagenumber"));
        if ((currentPageNumber - 1) > 0) {
            _target.setAttribute("pagenumber", currentPageNumber - 1);
        }
    }

    const removeSelectedRow = () => {

    }

    const removeSelectedRows = () => {

    }

    const reset = () => {
        const dgrid = target();
        const thead = dgrid?.querySelector("thead");
        if (thead?.querySelector("input[type=\"checkbox\"]"))
            thead.querySelector("input[type=\"checkbox\"]").checked = false;
    }

    const rows = () => {
        const dgrid = target();
        if (dgrid) {
            const tbody = dgrid?.querySelector("tbody");
            const rows = tbody?.querySelectorAll("*[aria-controls=\"table-row\"]");
            return (rows) ? [...rows] : [];
        }
        return [];
    }

    const rowColumns = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const columns = tbody.querySelectorAll("*[aria-controls=\"row-column\"]");
        return [...columns];
    }

    const selectAllRow = (selectedRow) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedRows].forEach(item => {
            item.classList.add(selectedRowTheme);
            item?.setAttribute("aria-selected", true);
            if (item.querySelector("input[type=\"checkbox\"]"))
                item.querySelector("input[type=\"checkbox\"]").checked = true;

            const rowHeader = item?.querySelector("*[aria-controls=\"table-row-header\"]");
            rowHeader.classList.remove(rowHeader.getAttribute("aria-atomic"));
            rowHeader.classList.add(selectedRowTheme);
        });

        // get selected fields
        const field = dgrid.querySelector("input[type=\"hidden\"]");
        field.value = JSON.stringify([...dgrid?.querySelectorAll("tr[aria-selected=\"true\"]")].map(row => row.getAttribute("id")));
    }

    const selectCheckbox = (selectedCheckbox) => {
        const dgrid = target();
        const isSelectable = dgrid.getAttribute("aria-multiselectable");
        const selectedRow = selectedCheckbox.parentNode.parentNode.parentNode.parentNode;
        if (selectedRow && isSelectable) {
            const rowHeader = selectedRow?.querySelector("*[aria-controls=\"table-row-header\"]");
            const checkbox = selectedRow.querySelector("input[type=\"checkbox\"]");
            if (selectedCheckbox.checked) {
                rowHeader.classList.remove(rowHeader.getAttribute("aria-atomic"));
                rowHeader.classList.add(selectedRowTheme);
                selectedRow?.setAttribute("aria-selected", true);
                if (checkbox)
                    checkbox.checked = true;
            } else {
                rowHeader.classList.add(rowHeader.getAttribute("aria-atomic"));
                rowHeader.classList.remove(selectedRowTheme);
                selectedRow?.setAttribute("aria-selected", false);
                if (checkbox)
                    checkbox.checked = false;
            }
        }

        // get selected fields
        const field = dgrid.querySelector("input[type=\"hidden\"]");
        field.value = JSON.stringify([...dgrid?.querySelectorAll("tr[aria-selected=\"true\"]")].map(row => row.getAttribute("id")));
    }

    const selectDefaultRow = (evtCallback) => {

        const dgrid = target();
        const isSelectable = dgrid.getAttribute("aria-multiselectable");
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedRows].forEach(item => {
            item.classList.remove(selectedRowTheme);
            const checkbox = item.querySelector("input[type=\"checkbox\"]");
            if (checkbox)
                checkbox.checked = false;
        });

        if (selectedRows.length > 0) {
            const selectedRow = selectedRows[0];
            if (selectedRows && isSelectable) {
                const selectedRowHeader = selectedRow?.querySelector("*[aria-controls=\"table-row-header\"]");
                selectedRowHeader.classList.remove(selectedRowHeader.getAttribute("aria-atomic"));

                selectedRow.firstChild.firstChild.click();

                selectedRow?.querySelector("*[aria-controls=\"table-row-header\"]").classList.add(selectedRowTheme);
                selectedRow?.setAttribute("aria-selected", true);
                const selectedRowCheckbox = selectedRow.querySelector("input[type=\"checkbox\"]");
                if (selectedRowCheckbox) {
                    selectedRowCheckbox.checked = true;
                }
            }
        }

        // get selected fields
        const field = dgrid.querySelector("input[type=\"hidden\"]");
        field.value = JSON.stringify([...dgrid?.querySelectorAll("tr[aria-selected=\"true\"]")].map(row => row.getAttribute("id")));
    }

    const selectRow = (selectedRow) => {
        const dgrid = target();
        const thead = dgrid.querySelector("thead").querySelector("*[aria-controls=\"table-header\"]");
        const checkAllBox = thead.querySelector("input[type=\"checkbox\"]");
        checkAllBox.checked = false;

        const isSelectable = dgrid.getAttribute("aria-multiselectable");
        const checkBoxes = dgrid.querySelectorAll("input[type=\"checkbox\"]");
        if (checkBoxes.length > 1) {
            const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
            [...selectedRows].forEach(item => {
                const rowHeader = item.querySelector("*[aria-controls=\"table-row-header\"]");
                rowHeader.classList.remove(selectedRowTheme);
                rowHeader.classList.add(rowHeader.getAttribute("aria-atomic"));
                const checkbox = item.querySelector("input[type=\"checkbox\"]");
                if (checkbox)
                    checkbox.checked = false;
            });
        }

        // active row
        const activeRow = dgrid.querySelector("*[aria-selected=\"true\"]");
        if (activeRow) {
            activeRow?.removeAttribute("aria-selected");
            const rowHeader = activeRow.querySelector("*[aria-controls=\"table-row-header\"]");
            rowHeader.classList.remove(selectedRowTheme);
            rowHeader.classList.add(rowHeader.getAttribute("aria-atomic"));
            if (selectedRow === activeRow) {
                rowHeader.classList.remove(rowHeader.getAttribute("aria-atomic"));
            }
            if (activeRow.querySelector("input[type=\"checkbox\"]"))
                activeRow.querySelector("input[type=\"checkbox\"]").checked = false;
        }

        // select row
        if (selectedRow && isSelectable) {
            const selectedRowHeader = selectedRow?.querySelector("*[aria-controls=\"table-row-header\"]");
            selectedRowHeader?.classList?.remove(selectedRowHeader.getAttribute("aria-atomic"));

            selectedRow?.querySelector("*[aria-controls=\"table-row-header\"]").classList.add(selectedRowTheme);
            selectedRow?.setAttribute("aria-selected", true);
            const selectedRowCheckbox = selectedRow.querySelector("input[type=\"checkbox\"]");
            if (selectedRowCheckbox) {
                selectedRowCheckbox.checked = true;
            }
        }

        // get selected fields
        const field = dgrid.querySelector("input[type=\"hidden\"]");
        field.value = JSON.stringify([...dgrid?.querySelectorAll("*[aria-selected=\"true\"]")].map(row => row.getAttribute("id")));
    }

    const selectedRowsCount = () => {
        const dgrid = target();
        const tbody = dgrid?.querySelector("tbody");
        const checkboxes = tbody?.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes?.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.filter(x => x.checked).length;
        }
        return 0;
    }

    const setPaging = () => {
        return attributes.page;
    }

    const setSelectedRow = (key) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedRows].forEach(row => {
            row?.removeAttribute("aria-selected");
            const checkbox = row.querySelector("input[type=\"checkbox\"]");
            if (checkbox)
                checkbox.checked = false;
        });

        const selectedRow = dgrid.querySelector("*[id=\"" + key + "\"]");
        if (selectedRow) {
            const checkbox = selectedRow.querySelector("input[type=\"checkbox\"]");
            if (checkbox && checkbox.value === key) {
                checkbox.checked = true;
                selectedRow?.setAttribute("aria-selected", true);
            }
        }
    }

    const setSelectedRows = (rows) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedRows].forEach(row => {
            row?.removeAttribute("aria-selected");
            const checkbox = row.querySelector("input[type=\"checkbox\"]");
            if (checkbox)
                checkbox.checked = false;
        });

        if (rows && rows?.length > 0) {
            rows.forEach(row => {
                const key = row[getKey()];
                const selectedRow = dgrid.querySelector("*[id=\"" + key + "\"]");
                if (selectedRow) {
                    const checkbox = selectedRow.querySelector("input[type=\"checkbox\"]");
                    if (checkbox && checkbox.value === key) {
                        checkbox.checked = true;
                        selectedRow?.setAttribute("aria-selected", true);
                    }
                }
            });
        }
    }

    const showFilterDialog = (dialogAttributes) => {
        dialogAttributes["name"] = filterDialogName;
        dialogAttributes["title"] = "Filters";
        filterDialog.show(dialogAttributes);
    }

    const showLoader = (text) => {
        const dgrid = target();
        if (dgrid) {
            const loader = dgrid.querySelector("div[aria-controls=\"loader-controls\"]");
            loader.classList.remove("hide");
        }
    }

    const showProgress = (text) => {
        const dgrid = target();
        if (dgrid) {
            const progress = dgrid.querySelector("div[aria-controls=\"progress-controls\"]");
            progress.classList.remove("hide");
        }
    }

    const target = () => {
        return document.getElementById("dg-" + attributes?.name);
    }

    const unselectAllRow = (selectedRow) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedRows].forEach(item => {
            item.classList.remove(selectedRowTheme);
            item?.setAttribute("aria-selected", false);
            item.querySelector("input[type=\"checkbox\"]").checked = false;

            // 
            const rowHeader = item?.querySelector("*[aria-controls=\"table-row-header\"]");
            rowHeader.classList.add(rowHeader.getAttribute("aria-atomic"));
            rowHeader.classList.remove(selectedRowTheme);
        });

        // select first of default row 
        if (selectedRows.length > 0) {
            selectedRows[0]?.classList.add(selectedRowTheme);
            selectedRows[0].querySelector("input[type=\"checkbox\"]").checked = true;

            // 
            const rowHeader = selectedRows[0]?.querySelector("*[aria-controls=\"table-row-header\"]");
            rowHeader.classList.remove(rowHeader.getAttribute("aria-atomic"));
            rowHeader.classList.add(selectedRowTheme);
        }

        // get selected fields
        const field = dgrid.querySelector("input[type=\"hidden\"]");
        field.value = "";
    }

    const unselectCheckedRow = (selectedRow) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedRows].forEach(item => {
            item.classList.remove("bg-light-theme");
        });
        selectedRow?.classList.add("bg-light-theme");
    }

    return {
        addSelectedRow,
        addSelectedRows,
        clearSelection,
        target,
        getKey,
        getPaging,
        getSelectedRow,
        getSelectedRowIndex,
        getSelectedRowKeyValue,
        getSelectedRows,
        getSelectedRowsKeyValue,
        getRowObjects,
        headerColumns,
        hideLoader,
        hideProgress,
        isRowSelected,
        itemsCount,
        nextPage,
        previousPage,
        removeSelectedRow,
        removeSelectedRows,
        reset,
        rows,
        rowColumns,
        selectAllRow,
        selectCheckbox,
        selectDefaultRow,
        selectRow,
        setPaging,
        setSelectedRow,
        setSelectedRows,
        selectedRowsCount,
        showFilterDialog,
        showLoader,
        showProgress,
        unselectAllRow,
        unselectCheckedRow
    }

}

const useDataGridToolbar = (toolbarAttr) => {

    const toolbar = () => {
        return document.getElementById(toolbarAttr.name);
    }

    const toggleButton = (buttonAttribute) => {
        const buttons = document.getElementById(buttonAttribute.name).querySelectorAll("button");
        buttons.forEach(button => {
            button.classList.remove("hide");
        });
        buttons[buttonAttribute?.state ? 0 : 1].classList.add("hide");
    }

    const disableButton = (buttonAttribute) => {
        const buttons = document.getElementById(buttonAttribute.name).querySelectorAll("button");
        buttons.forEach(button => {
            button.classList.remove("hide");
        });
        buttons[buttonAttribute?.state ? 0 : 1].classList.add("hide");
    }

    const disableButtonMenuItem = (menuItemsAttribute) => {
        menuItemsAttribute.forEach(menuItemsAttribute => {
            //
        });
    }

    const button = (args) => {
        const _toolbar = toolbar();
        const button = _toolbar.querySelector("div[id=\"" + toolbarAttr?.name + "-" + args.name + "\"]")?.querySelector("button");
        return {
            setText: (text) => {
                button.querySelector("*[aria-controls=\"label\"]").innerHTML = text;
            }
        }
    }

    const label = (args) => {
        const _toolbar = toolbar();
        const label = _toolbar.querySelector("div[id=\"" + toolbarAttr?.name + "-" + args.name + "\"]")?.querySelector("div");
        return {
            setText: (text) => {
                label.innerHTML = text;
            }
        }
    }

    return {
        button,
        label,
        disableButton,
        disableButtonMenuItem,
        toolbar,
        toggleButton
    };
}

export { DataGrid, useDataGrid, DataGridToolbar, useDataGridToolbar }