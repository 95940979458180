import React, { useEffect, useRef } from "react";
import { DOCKTYPE } from "../../enums/enums";

const StackablePanel = ({ attributes }) => {

    const stackablePanelNotifer = useRef()

    return (
        <div id={attributes?.name} aria-controls="stackable-panel" ref={stackablePanelNotifer} className={"w-100 position-absolute top-0px left-0px bottom-0px right-0px"}>
            {
                attributes?.items?.map((item, i) => {
                    return (
                        <div aria-controls="panel" stacked-panel-index={i} className={"transition-ease-out- position-absolute top-0px start-0px end-0px " + ((attributes?.dock === DOCKTYPE.UNDOCK) ? "" : "bottom-0px ") + ((i === 0) ? "" : "hide")} key={attributes.name + "-stackable-panel-" + i}>
                            {
                                (() => {
                                    if (item?.widget) {
                                        return item?.widget
                                    }
                                })()
                            }
                        </div>
                    )
                })
            }
        </div>
    );
}

const useStackablePanel = (attributes) => {
    const addWidget = () => {

    }

    const getPanels = () => {

    }

    const getPanelByIndex = (index) => {
        const sPanel = stackablePanel();
        const panels = sPanel.querySelectorAll("div[aria-controls=\"panel\"]");
        const panelArray = [...panels];
        return panelArray[index];
    }

    const getPanelByName = (name) => {
        const sPanel = stackablePanel();
        const panels = sPanel.querySelectorAll("div[aria-controls=\"panel\"]");
        const panelArray = [...panels];
        return panelArray.find(x => x.querySelector("div[aria-owns=\"" + name + "\"]"));
    }

    const stackablePanel = () => {
        return document.getElementById(attributes?.name);
    }

    const setPanel = (index) => {
        const sPanel = stackablePanel();
        const panels = sPanel.querySelectorAll("div[aria-controls=\"panel\"]");
        [...panels].forEach((panel, i) => {
            panel.classList.add("hide")
        });
        const selectedPanel = sPanel.querySelector("div[stacked-panel-index=\"" + index + "\"]");
        selectedPanel.classList.remove("hide");
    }

    return { addWidget, getPanels, getPanelByIndex, getPanelByName, setPanel, stackablePanel }
}
export { StackablePanel, useStackablePanel };