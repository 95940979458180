import {  useHttps } from "../../../../shared/utilities";

const CustomerService = () => {
    const request = useHttps();
    const controller = "/customers";

    const getAll = async (data) => await request.post(controller + "/get-all", data);
    const get = async (id) => await request.post(controller + "/get-all", id);
    const search = async (data) => await request.post(controller + "/get-all", data);
    const save = async (data) => await request.post(controller + "/save", data);
    const update = async (data) => await request.post(controller + "/update", data);
    const remove = async (data) => await request.post(controller + "/delete", data);
    const publish = async (data) => await request.post(controller + "/publish", data);
    const unpublish = async (data) => await request.post(controller + "/unpublish", data);
    const sendToArchive = async (data) => await request.post(controller + "/archive", data);
    const restoreArchive = async (data) => await request.post(controller + "/restore-archive", data);

    return { 
        getAll, 
        get, 
        search,
        save, 
        update,
        remove,
        publish,
        unpublish,
        sendToArchive,
        restoreArchive
    }
}

export { CustomerService }