import { useCallback, useEffect, useRef, useState } from 'react';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { MarketService } from '../market.service';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { MarketTypeService } from '../market.type/market.type.service';
import { useForm } from '../../../../../shared/components/form/form.component';
import { CountryService } from '../../../configuration.settings/settings/location/country/country.service';
import { StateService } from '../../../configuration.settings/settings/location/state/state.service';
import { CityService } from '../../../configuration.settings/settings/location/city/city.service';
import { LocalGovernmentAreaService } from '../../../configuration.settings/settings/location/lga/lga.service';
import useAppConfig from '../../../../../shared/hooks/app.config.hook';
import { useToastr } from '../../../../../shared/components/toastr/toastr.component';

const useCreateOrEditMarketHook = (attributes) => {

    // config
    const appConfig = useAppConfig();

    // theme
    const icons = useIconsThemes()?.rounded;
    const themes = usePrimaryUIThemes();


    // state objects
    const [market, setMarket] = useState({
        marketName: '',
    });

    const [marketTypes, setMarketTypes] = useState([]);
    const [selectedMarketType, setSelectedMarketType] = useState({});

    const [status, setStatus] = useState({
        inProgress: false,
        errorOccurred: false,
        asyncMessage: ""
    });

    // refs

    const countries = useRef([]);
    const states = useRef([]);
    const cities = useRef([]);

    const marketObserver = useRef(null);
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);
    const marketPhotoObserver = useRef(null);
    const marketTypeObserver = useRef(null);
    const countryObserver = useRef(null);
    const stateObserver = useRef(null);
    const cityObserver = useRef(null);

    // components
    const form = useForm({
        ref: reactiveForm
    });
    const toastr = useToastr();

    // services
    const marketService = MarketService();
    const marketTypeService = MarketTypeService();
    const countryService = CountryService();
    const stateService = StateService();
    const cityService = CityService();
    const lgaService = LocalGovernmentAreaService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);

        if (isEmptyField)
            return;
        
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        marketObserver.current = args?.data;

        // load profile image
        marketPhotoObserver.current.loadImage(marketObserver.current);

        // get countries
        await getCountries({
            queryString: "",
            pageNumber: 1,
            rowsPerPage: 1000
        });

        if (args?.data !== null) {
            form.setData(args?.data);
        } else {
            form.clear();
        }
    }, [form]);

    const getCountries = useCallback(async (request) => {
        await countryService.getAll(request)
            .then(response => {
                countries.current = response?.data?.list;
                countryObserver.current.updateList(countries.current);
                countryObserver.current.selectItem();
            })
            .catch(error => {
                //
            });
    }, []);

    const getStates = useCallback(async (request) => {
        await stateService.getAll(request)
            .then(response => {
                states.current = response?.data?.list;
                stateObserver.current.updateList(states.current);
                stateObserver.current.selectItem();
            })
            .catch(error => {
                //
            });

    }, []);

    const getCities = useCallback(async (request) => {
        await cityService.getAll(request)
            .then(response => {
                cities.current = response?.data?.list;
                cityObserver.current.updateList(cities.current);
                cityObserver.current.selectItem();
            })
            .catch(error => {
                //
            });

    }, []);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleCountryChange = (e, args) => {
        console.log(args);
        getStates({
            countryId: args.value,
            queryString: "",
            pageNumber: 1,
            rowsPerPage: 50
        });
    }

    const handleCountrySearchFieldChange = async (e) => {
        await getCountries({
            queryString: e.value,
            pageNumber: 1,
            rowsPerPage: 1000
        });
    }

    const handleStateChange = (e, args) => {
        getCities({
            stateId: args.value,
            queryString: "",
            pageNumber: 1,
            rowsPerPage: 50
        });
    }

    const handleStateSearchFieldChange = async (e) => {
        await getCities({
            queryString: e.value,
            pageNumber: 1,
            rowsPerPage: 1000
        });
    }

    const handleMarketPhotoUpload = (args) => {
        if (Object.keys(args).length > 0) {
            marketObserver.current = args?.data;
        }
    }

    const handleSaveOrUpdate = async (args) => {
        console.log(args);
        args?.inProgress(true);
        if (args?.isNew) {
            await marketService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
                toastr.success("Market created successfully");

            }).catch((error) => {
                args?.error(error);
                toastr.error("Oops! something went wrong");
            });
        } else {
            await marketService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
                toastr.success("Market updated successfully");
            }).catch((error) => {
                args?.error(error);
                toastr.error("Oops! something went wrong");
            });
        }
    }

    return {
        market: market,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        // media
        marketPhotoUploaderAttributes: {
            formElement: true,
            validate: true,
            id: "mediaId",
            key: "mediaId",
            url: "mediaUrl",
            caption: "*File supported .png, .jpg, .webp",
            size: themes?.sizeAbsolute,
            observer: marketPhotoObserver,
            leading: icons?.BrandingWatermarkRoundedIcon,
            style: themes?.image,
            model: marketObserver.current,
            isImageAvailable: marketObserver.current ? true : false,
            onUploadCompleted: handleMarketPhotoUpload,
            config: {
                key: "mediaId",
                url: "mediaUrl",
                imageSize: 500,
                ownerId: appConfig?.sessionAuthDetails?.userId,
                uploadUrl: marketService?.uploadUrl,
                downloadUrl: marketService?.downloadUrl
            }
        },

        // name
        marketNameLabel: {
            text: "Name:",
            style: themes?.label?.text13px
        },
        marketNameField: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "Type the name of the market here",
            errorMessage: "Please enter market name to continue",
            id: "marketName",
            value: market?.marketName,
            style: themes?.field?.textField,
            observer: componentObserver,
        },

        // description
        marketDescriptionLabel: {
            text: "Description:",
            style: themes?.label?.text13px
        },
        marketDescriptionField: {
            formElement: true,
            validate: false,
            readOnly: status.inProgress,
            placeholder: "Write something about this market",
            errorMessage: "Please enter market description to continue",
            id: "marketDescription",
            isMultiline: true,
            value: market?.marketDescription,
            style: themes?.field?.multiTextField,
            observer: componentObserver,
        },

        // address
        contactAddressLabel: {
            text: "Market address/landmark:",
            style: themes?.label?.text13px
        },
        contactAddressField: {
            formElement: true,
            validate: false,
            placeholder: "Type the address of the market here",
            errorMessage: "Please enter market address to continue",
            id: "marketAddress",
            value: market?.contactAddress,
            style: themes?.field?.multiTextField,
            observer: componentObserver,
        },

        // country
        countrySingleSelectDropDownFieldAttributes: {
            name: "country-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Country",
            errorMessage: "Please make a selection to continue",
            onClick: (e, args) => { },
            observer: countryObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "countryId",
            title: "countryName",
            value: "countryId",
            items: countries.current,
            isSearchable: true,
            model: marketObserver.current,
            onFilter: handleCountrySearchFieldChange,
            onItemClick: handleCountryChange
        },

        // state
        stateSingleSelectDropDownFieldAttributes: {
            name: "state-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "State",
            errorMessage: "Please make a selection to continue",
            onClick: (e, args) => { },
            observer: stateObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "stateId",
            title: "stateName",
            value: "stateId",
            items: states.current,
            model: marketObserver.current,
            onFilter: handleStateSearchFieldChange,
            onItemClick: handleStateChange
        },

        // city
        citySingleSelectDropDownFieldAttributes: {
            name: "city-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "City",
            errorMessage: "Please make a selection to continue",
            onClick: (e, args) => { },
            observer: cityObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "cityId",
            title: "cityName",
            value: "cityId",
            items: cities.current,
            model: marketObserver.current
        },

        // postal code
        postalCodeField: {
            formElement: true,
            validate: false,
            placeholder: "Postal code",
            errorMessage: "Please enter market postal code to continue",
            id: "postalCode",
            value: market?.marketPostalCode,
            style: themes?.field?.textField,
            observer: componentObserver,
        },


        themes: themes,
    }
}

export default useCreateOrEditMarketHook;