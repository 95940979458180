import { useCallback, useEffect, useRef, useState } from 'react';
import { useDataGrid } from '../../../../../shared/components/datagrid';
import { TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useForm } from '../../../../../shared/components/form/form.component';
import { useTokenSearchField } from '../../../../../shared/components/field/token.search.textfield.component';
import { ProductCategoryService } from '../../../configuration.settings/settings/product.categories/product.category.service';
import { ProductColorService } from '../../../configuration.settings/settings/product.colors/product.color.service';
import { ProductTypeService } from '../../../configuration.settings/settings/product.types/product.type.service';
import { ProductVariationService } from '../../../configuration.settings/settings/product.variations/product.variation.service';
import { ProductService } from '../product.service';
import { ProductBrandService } from '../../../configuration.settings/settings/product.brands/product.brand.service';
import { ProductImageService } from '../../../configuration.settings/settings/product.images/product.image.service';
import { useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import { useToastr } from '../../../../../shared/components/toastr/toastr.component';

const useProductFilterHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();

    // state objects
    const [product, setProduct] = useState();
    const [productCategories, setProductCategories] = useState([]);
    const [productBrands, setProductBrands] = useState([]);
    const [productVariations, setProductVariations] = useState([]);
    const [productColors, setProductColors] = useState([]);
    const [productTypes, setProductTypes] = useState([]);
    const [selectedProductCategory, setSelectedProductCategory] = useState([]);
    const [productImages, setProductImages] = useState([]);
    const [productImageQueryString, setProductImageQueryString] = useState("");
    const [productImagePageNumber, setProductImagePageNumber] = useState(1);
    const [productImageRowsPerPage, setProductImageRowsPerPage] = useState(15);
    const [productImageTotalPages, setProductImageTotalPages] = useState(0);
    const [productImageTotalRows, setProductImageTotalRows] = useState(0);

    const queryConfigurations = {
        default: {
            pageNumber: 1,
            rowsPerPage: 20
        },
        productBrands: {
            pageNumber: 1,
            rowsPerPage: 20
        },
        productVariations: {
            pageNumber: 1,
            rowsPerPage: 20
        },
        productColors: {
            pageNumber: 1,
            rowsPerPage: 20
        },
        productTypes: {
            pageNumber: 1,
            rowsPerPage: 20
        },
        productCategories: {
            pageNumber: 1,
            rowsPerPage: 20
        },
        productImages: {
            pageNumber: 1,
            rowsPerPage: 15
        }
    }

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No images selected!",
            message: "Please select an images from the list to continue"
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available images, please wait..."
        }
    };

    // observer
    const productObserver = useRef(null);
    const componentObserver = useRef(null);
    const reactiveFormObserver = useRef(null);
    const productFrontImageUploaderObserver = useRef(null);
    const productBackImageUploaderObserver = useRef(null);
    const productCategoryDropDownFieldObserver = useRef(null);
    const productBrandDropDownFieldObserver = useRef(null);
    const productSelectedBrandsObserver = useRef(null);
    const productColorDropDownFieldObserver = useRef(null);
    const productSelectedColorsObserver = useRef(null);
    const productTypeDropDownFieldObserver = useRef(null);
    const productSelectedTypesObserver = useRef(null);
    const productVariationDropDownFieldObserver = useRef(null);
    const productSelectedVariationsObserver = useRef(null);

    // components
    const productImageGalleryToolbarName = "product-images-gallery-toolbar";
    const productImageGalleryToolbar = useToolbar({
        name: productImageGalleryToolbarName
    });
    const productImageGalleryToolbarButtons = {
        filterButtonName: "product-images-gallery-filter-button",
        previousButtonName: "product-images-gallery-previous-button",
        pagesLabelName: "product-images-gallery-pages-label",
        nextButtonName: "product-images-gallery-next-button",
    }

    const productImageGalleryDatagridName = "product-images-gallery-datagrid";
    const productImageGalleryDatagrid = useDataGrid({
        name: productImageGalleryDatagridName
    });

    const categoryTokenSearchFieldName = "createoredit-parent-category-token-search-field";
    const parentCategoriesTokenSearchField = useTokenSearchField({
        name: categoryTokenSearchFieldName
    });

    const form = useForm({
        ref: reactiveFormObserver
    });

    // toastr
    const toastr = useToastr();

    // loader
    const pageLoader = usePageLoader();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        setProduct(args?.data);
        setSelectedProductCategory(args?.data);

        setProductBrands([]);
        setProductCategories([]);
        setProductColors([]);
        setProductTypes([]);
        setProductVariations([]);

        // clear selected items
        productCategoryDropDownFieldObserver?.current?.clearItem();
        // 
        try {
            productBrandDropDownFieldObserver?.current?.clearItems();
            productColorDropDownFieldObserver?.current?.clearItems();
            productTypeDropDownFieldObserver?.current?.clearItems();
            productVariationDropDownFieldObserver?.current?.clearItems();
        } catch (e) {
            //
        }

        //
        getProductImages({
            queryString: productImageQueryString,
            pageNumber: productImagePageNumber,
            rowsPerPage: productImageRowsPerPage
        });

        // create a promise list of product brands, categories, variations, types, etc.
        const request = {
            queryString: "",
            pageNumber: queryConfigurations.default.pageNumber,
            rowsPerPage: queryConfigurations.default.rowsPerPage
        };

        Promise.all([
            productBrandService.getAll(request)
                .then(response => response?.data?.list),

            productCategoryService.getAll(request)
                .then(response => response?.data?.list),

            productColorService.getAll(request)
                .then(response => response?.data?.list),

            productVariationService.getAll(request)
                .then(response => response?.data?.list),

            productTypeService.getAll(request)
                .then(response => response?.data?.list)
        ]).then(response => {
            response.forEach(list => {

                // get product categories
                if (list[0].hasOwnProperty("productCategoryId")) {
                    if (!list.find(x => x.productCategoryId === args?.data?.productCategoryId)) {
                        list.push({
                            productCategoryId: args?.data?.productCategoryId,
                            productCategoryName: args?.data?.productCategory
                        });
                    }
                    setProductCategories(list);
                    productCategoryDropDownFieldObserver?.current?.addToStore(list);
                }

                // get product brands
                if (list[0].hasOwnProperty("productBrandId")) {
                    if (!list.find(x => x.productBrandId === args?.data?.productBrandId)) {
                        list.push({
                            productBrandId: args?.data?.productBrandId,
                            productBrand: args?.data?.productBrand
                        });
                    }
                    setProductBrands(list);
                    productBrandDropDownFieldObserver?.current?.addToStore(list);
                }

                // get product colors
                if (list[0].hasOwnProperty("productColorId")) {
                    if (!list.find(x => x.productColorId === args?.data?.productColorId)) {
                        list.push({
                            productColorId: args?.data?.productColorId,
                            productColorName: args?.data?.productColor
                        });
                    }
                    setProductColors(list);
                    productColorDropDownFieldObserver?.current?.addToStore(list);
                }

                // get product types
                if (list[0].hasOwnProperty("productTypeId")) {
                    if (!list.find(x => x.productTypeId === args?.data?.productTypeId)) {
                        list.push({
                            productTypeId: args?.data?.productTypeId,
                            productType: args?.data?.productType
                        });
                    }
                    setProductTypes(list);
                    productTypeDropDownFieldObserver?.current?.addToStore(list);
                }

                // get product variations
                if (list[0].hasOwnProperty("productVariationId")) {
                    if (!list.find(x => x.productVariationId === args?.data?.productVariationId)) {
                        list.push({
                            productVariationId: args?.data?.productVariationId,
                            productVariation: args?.data?.productVariation
                        });
                    }
                    setProductVariations(list);
                    productVariationDropDownFieldObserver?.current?.addToStore(list);
                }
            });
        }).catch(error => {
            //
        });

        productFrontImageUploaderObserver.current?.loadImage(args?.data);
        productBackImageUploaderObserver.current?.loadImage(args?.data);

        if (args?.data !== null) {
            //
            productObserver.current = await args?.data;

            // select category
            productCategoryDropDownFieldObserver.current.selectItem({
                productCategoryId: args?.data?.productCategoryId,
                productCategoryName: args?.data?.productCategory
            });

            // clear product attribute list
            productSelectedBrandsObserver.current = [];
            productSelectedColorsObserver.current = [];
            productSelectedTypesObserver.current = [];
            productSelectedVariationsObserver.current = [];

            // create a promise list of product attributes
            const productSelectedRequest = {
                productId: productObserver.current?.productId,
                queryString: "",
                pageNumber: queryConfigurations.default.pageNumber,
                rowsPerPage: queryConfigurations.default.rowsPerPage
            };
            const promiseSelectedList = [
                productService.getProductBrands(productSelectedRequest)
                    .then(response => response?.data?.list),

                productService.getProductColors(productSelectedRequest)
                    .then(response => response?.data?.list),

                productService.getProductVariations(productSelectedRequest)
                    .then(response => response?.data?.list),

                productService.getProductTypes(productSelectedRequest)
                    .then(response => response?.data?.list)
            ];

            // get all product details attributes
            Promise.all(promiseSelectedList).then(response => {

                response.forEach(list => {
                    if (Array.isArray(list) && list.length > 0) {

                        // get product selected brands
                        if (list[0].hasOwnProperty("productBrandSelectionId")) {
                            productSelectedBrandsObserver.current = list;
                            productBrandDropDownFieldObserver?.current?.selectItems(list);
                        }

                        // get product selected colors
                        if (list[0].hasOwnProperty("productColorSelectionId")) {
                            productSelectedColorsObserver.current = list;
                            productColorDropDownFieldObserver?.current?.selectItems(list);
                        }

                        // get product selected types
                        if (list[0].hasOwnProperty("productTypeSelectionId")) {
                            productSelectedTypesObserver.current = list;
                            productTypeDropDownFieldObserver?.current?.selectItems(list);
                        }

                        // get product selected variations
                        if (list[0].hasOwnProperty("productVariationSelectionId")) {
                            productSelectedVariationsObserver.current = list;
                            productVariationDropDownFieldObserver?.current?.selectItems(list);
                        }
                    }
                });
            }).catch(error => {
                //
            });

            // set form data
            form.setData(args?.data);
        } else {
            form.clear();

            //
            productObserver.current = await {};
        }
    }, [form])

    // services
    const productCategoryService = ProductCategoryService();
    const productBrandService = ProductBrandService();
    const productVariationService = ProductVariationService();
    const productColorService = ProductColorService();
    const productTypeService = ProductTypeService();
    const productService = ProductService();
    const productImageService = ProductImageService();

    const getProductCategories = useCallback(async (search) => {
        await productCategoryService.getAll({
            queryString: search,
            pageNumber: 1,
            rowsPerPage: 1000000
        }).then(response => {
            setProductCategories(response.data.list);
            // console.log(product);
        }).catch(error => {
        });
    }, []);

    const getProductBrands = useCallback(async (search) => {
        await productBrandService.getAll({
            queryString: search,
            pageNumber: queryConfigurations.productBrands.pageNumber,
            rowsPerPage: queryConfigurations.productBrands.rowsPerPage
        }).then(response => {
            // console.log(response.data.list);
            setProductBrands(response.data.list);
            productBrandDropDownFieldObserver?.current?.selectItems(productSelectedBrandsObserver.current);
        }).catch(error => {
        });

    }, []);

    const getProductVariations = useCallback(async (search) => {
        await productVariationService.getAll({
            queryString: search,
            pageNumber: queryConfigurations.productVariations.pageNumber,
            rowsPerPage: queryConfigurations.productVariations.rowsPerPage
        })
            .then(response => {
                setProductVariations(response.data.list);

                //
                productVariationDropDownFieldObserver?.current?.selectItems(productSelectedVariationsObserver.current);
            })
            .catch(error => {
            });

    }, []);

    const getProductColors = useCallback(async (search) => {
        await productColorService.getAll({
            queryString: search,
            pageNumber: queryConfigurations.productColors.pageNumber,
            rowsPerPage: queryConfigurations.productColors.rowsPerPage
        }).then(response => {
            setProductColors(response.data.list);

            //
            productColorDropDownFieldObserver?.current?.selectItems(productSelectedColorsObserver.current);
        }).catch(error => {
        });

    }, []);

    const getProductTypes = useCallback(async (search) => {
        await productTypeService.getAll({
            queryString: search,
            pageNumber: queryConfigurations.productTypes.pageNumber,
            rowsPerPage: queryConfigurations.productTypes.rowsPerPage
        }).then(response => {
            setProductTypes(response.data.list);

            //
            productTypeDropDownFieldObserver?.current?.selectItems(productSelectedTypesObserver.current);
        }).catch(error => {
        });

    }, []);

    const getProductImages = useCallback(async (request) => {

        productImageGalleryDatagrid?.showLoader(messages?.loader?.fetchingMessage);
        productImageGalleryDatagrid.clearSelection();

        await productImageService.getAll(request)
            .then(response => {
                productImageGalleryDatagrid?.hideLoader();

                // populate datagrid 
                setProductImages(response?.data?.list);

                // set page
                setProductImagePageNumber(response?.data?.page?.pageNumber);
                setProductImageRowsPerPage(response?.data?.page?.rowsPerPage);
                setProductImageTotalPages(response?.data?.page?.totalPages);
                setProductImageTotalRows(response?.data?.page?.totalRows);

                productImageGalleryToolbar.button({
                    name: productImageGalleryToolbarButtons.pagesLabelName
                }).setText("Page " + response?.data?.page?.pageNumber + " of " + response?.data?.page?.totalPages);

            })
            .catch(error => {
                productImageGalleryDatagrid?.hideLoader();
            });

    }, []);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        setProduct({
            ...product,
            [e.id]: e.value
        })
    }

    const handleProductImage1Upload = async (args) => {
        //
    }

    const handleProductImage2Upload = async (args) => {
        //
    }

    const handleSearchCategoryFieldChange = (e) => {
        getProductCategories(e.value);
    }

    const handleSearchBrandFieldChange = (e) => {
        getProductBrands(e.value);
    }

    const handleSearchVariationFieldChange = (e) => {
        getProductVariations(e.value);
    }

    const handleSearchColorFieldChange = (e) => {
        getProductColors(e.value);
    }

    const handleSearchTypeFieldChange = (e) => {
        getProductTypes(e.value);
    }

    const handleSaveOrUpdate = async (args) => {
        console.log(args?.data);
        // return;

        pageLoader.show({
            message: "Loading, please wait...",
        });
        if (args?.isNew) {
            await productService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                pageLoader.hide();
                toastr.success("Product successfully created");
            }).catch((error) => {
                pageLoader.hide();
                toastr.error("Oops! something went wrong.");
            });
        } else {
            await productService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                pageLoader.hide();
                toastr.success("Product successfully updated");
            }).catch((error) => {
                pageLoader.hide();
                toastr.error("Oops! something went wrong.");
            });
        }
    }

    return {
        product: product,
        media: productObserver.current,

        componentObserver: componentObserver,
        reactiveForm: reactiveFormObserver,

        sectionHorizontalSeparatorAttributes: {
            style: themes?.horizontalSeparator
        },

        // product category
        productCategoryDropDownFieldAttributes: {
            name: "category-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select",
            observer: productCategoryDropDownFieldObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "productCategoryId",
            title: "productCategoryName",
            value: "productCategoryId",
            items: productCategories,
            listPlaceholder: "None",
            isSearchable: true,
            model: productObserver?.current,
            onFilter: handleSearchCategoryFieldChange
        },

        // sort column
        productSortColumnDropDownFieldAttributes: {
            name: "sort-column-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            observer: productBrandDropDownFieldObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "sortColumnId",
            title: "sortColumnName",
            value: "sortColumnId",
            items: [
                { sortColumnId: "none", sortColumnName: "None" },
                { sortColumnId: "productName", sortColumnName: "Product" },
                { sortColumnId: "productCategory", sortColumnName: "Category" },
                { sortColumnId: "productBrand", sortColumnName: "Brand" },
                { sortColumnId: "productType", sortColumnName: "Type" },
                { sortColumnId: "productVariation", sortColumnName: "Size or Weight" },
                { sortColumnId: "dateCreated", sortColumnName: "Date Created" }
            ],
            listPlaceholder: "None",
            isSearchable: false,
            model: productObserver?.current,
            onLookupChange: handleSearchBrandFieldChange
        },

        // sort order
        productSortOrderDropDownFieldAttributes: {
            name: "sort-order-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            observer: productColorDropDownFieldObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "sortOrderId",
            title: "sortOrderName",
            value: "sortOrderId",
            items: [
                { sortOrderId: "asc", sortOrderName: "Ascending" },
                { sortOrderId: "desc", sortOrderName: "Descending" }
            ],
            listPlaceholder: "None",
            isSearchable: false,
            model: productObserver?.current,
            onLookupChange: handleSearchColorFieldChange
        },

        themes: themes,
    }
}

export default useProductFilterHook;