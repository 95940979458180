import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useToastr } from '../../../../shared/components/toastr/toastr.component';
import { DataGrid, useDataGrid } from '../../../../shared/components/datagrid';
import { useAlertDialog } from '../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../shared/components/loader/page.loader.component';
import MenuList from '../../../../shared/components/menu/menu.list.component';
import { Toolbar, useToolbar } from '../../../../shared/components/toolbar/toolbar.component';
import { COLUMNTYPE, DOCKTYPE, GRIDPANE, MENUALIGNMENTTYPE, MENUITEMTYPE, SLIDEDIRECTION, TOOLBARITEMTYPE } from '../../../../shared/enums/enums';
import { useIconsThemes } from '../../../../shared/hooks/ui.icons.hook';
import { usePrimaryUIThemes } from '../../../../shared/hooks/ui.themes';
import { ProductImageService } from '../../configuration.settings/settings/product.images/product.image.service';
import CreateOrEditProductView from './createoredit.product.deal/createoredit.product.deal.view';
import { ProductService } from './../product/product.service';
import { ProductDealService } from './product.deal.service';
import useFlags from '../../../../shared/hooks/flags.hook';
import CreateOrUploadProductSheetView from './create.bulk.product.sheet/create.bulk.product.sheet.view';
import global from '../../../../shared/helpers/global.variable.helper';
import ProductFilterView from './product.filter/product.filter.view';
import { CalloutWindow } from '../../../../shared/components/callout/callout.window.component';
import { Grid } from '../../../../shared/components/grid/grid.component';
import ProductDetailsView from './product.details.deal/product.details.view';
import OrderDetailsView from '../../order.management/orders/order.details/order.details.view';
import CreateOrEditProductDealView from './createoredit.product.deal/createoredit.product.deal.view';

const useProductDealsHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // flags
    const flags = useFlags();

    // state objects
    const [productDeals, setProductDeals] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No product selected!",
            message: "Please select an product from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New deal",
            successMessage: {
                title: "Deal created successfully!",
                message: "Deal was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit deal",
            alertMessage: {
                title: "Multiple deals selected!",
                message: "Oops!, you selected multiple deals. Please select a deal from the list to continue"
            },
            successMessage: {
                title: "Deal updated successfully!",
                message: "Deal was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Delete deal",
            confirmMessage: (count) => {
                return {
                    title: (productDealsDatagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these deals?" : "this deal?")
                }
            },
            successMessage: {
                title: "Deal deleted successfully!",
                message: "Deal was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (productDealsDatagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these deals?" : "this deal?")
                }
            },
            successMessage: {
                title: "Deal published successfully!",
                message: "Deal was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (productDealsDatagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these deals?" : "this deal?")
                }
            },
            successMessage: {
                title: "Deal published successfully!",
                message: "Deal was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (productDealsDatagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these deals?" : "this deal?")
                }
            },
            successMessage: {
                title: "Product archived successfully!",
                message: "Product was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (productDealsDatagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these deals?" : "this deal?")
                }
            },
            successMessage: {
                title: "Product restored successfully!",
                message: "Product was successfully restored."
            }
        },

        handleFilter: {
            dialogTitle: "Filter",
            successMessage: {
                title: "Product filtered",
                message: "Products were successfully filtered"
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available deals, please wait..."
        }
    };

    const publishActionMenuItems = {
        publishSelectedToMarketsMenuItem: "publish-selected-to-markets-menuitem",
        publishAllToMarketsMenuItem: "publish-all-to-markets-menuitem",
        publishSelectedToMarketMenuItem: "publish-selected-to-market-menuitem",
        unpublishSelectedFromMarketMenuItem: "unpublish-selected-menuitem",
        unpublishAllMenuItem: "unpublish-all-menuitem"
    };

    // observers
    const componentObserver = useRef();
    const createOrEditProductObserver = useRef();
    const createOrUploadProductSheetDialogObserver = useRef();
    const productFilterObserver = useRef();
    const productDealDetailsViewObserver = useRef();
    const productDealsDatagridObserver = useRef();


    // components
    const productDealsDatagridName = "product-deals-datagrid";
    const productDealsDatagrid = useDataGrid({
        name: productDealsDatagridName
    });

    const leftPaneToolbarName = "product-deals-left-pane-toolbar";
    const leftPaneToolbar = useToolbar({
        name: leftPaneToolbarName
    });
    const leftToolbarButtons = {
        addProductButton: "add-product-button",
        editProductButton: "edit-product-button",
        deleteProductButton: "delete-product-button",
        reloadProductButton: "reload-product-button",
    }

    const rightPaneToolbarName = "product-deals-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButton: "filter-button",
        previousButton: "previous-button",
        pagesButton: "pages-label",
        nextButton: "next-button",
    }

    const createOrEditProductDialogName = "createoredit-product-modal";
    const createOrEditProductDialog = useModalDialog({
        name: createOrEditProductDialogName
    });

    const createOrUploadProductSheetDialogName = "createorupload-product-modal";
    const createOrUploadProductSheetDialog = useModalDialog({
        name: createOrUploadProductSheetDialogName
    });

    const productFilterDialogName = "product-filter-modal";
    const productFilterDialog = useModalDialog({
        name: productFilterDialogName
    });

    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // toastr
    const toastr = useToastr();

    // loader
    const pageLoader = usePageLoader();

    // services
    const productDealService = ProductDealService();
    const productService = ProductService();
    const productImageService = ProductImageService();


    const showPagingInfo = (pageNumber = 0, totalPages = 0) => {
        try {
            rightPaneToolbar.button({
                name: rightToolbarButtons.pagesButton
            }).setText("Page " + pageNumber + " of " + totalPages);
        } catch (e) {
            //
        }
    }

    const getProductDeals = useCallback(async (request) => {

        pageLoader.show();
        productDealsDatagrid.clearSelection();

        await productDealService.getAll(request)
            .then(response => {
                productDealsDatagrid?.hideLoader();

                // populate datagrid 
                setProductDeals(response?.data?.list);

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

                showPagingInfo(response?.data?.page?.pageNumber, response?.data?.page?.totalPages);
                pageLoader.hide();

            })
            .catch(error => {
                pageLoader.hide();
                productDealsDatagrid?.hideLoader();
            });

    }, []);

    const getProducts = useCallback(async (request) => {

        pageLoader.show();
        productDealsDatagrid.clearSelection();

        await productService.getAll(request)
            .then(response => {
                productDealsDatagrid?.hideLoader();

                // populate datagrid 
                setProductDeals(response?.data?.list);

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

                showPagingInfo(response?.data?.page?.pageNumber, response?.data?.page?.totalPages);
                pageLoader.hide();

            })
            .catch(error => {
                pageLoader.hide();
                productDealsDatagrid?.hideLoader();
            });

    }, []);

    useEffect(() => {
        getProductDeals({
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }, []);

    // event handlers
    const handleCreate = (e) => {
        // clear selection
        setSelectedProduct({});

        // show callout
        createOrEditProductObserver.current.show({
            title: messages?.handleCreate?.dialogTitle,
            data: {},
            isNew: true
        });

    }

    const handleUpload = async (e) => {
        // clear selection
        setSelectedProduct({});

        // show dialog
        await createOrUploadProductSheetDialog.show({
            title: "Create Bulk Products",
            showCloseButton: true,
            isNew: true
        });

        // listen to dialog for changes
        createOrUploadProductSheetDialogObserver.current({
            data: null
        });
    }

    const handleEdit = (e) => {
        if (productDealsDatagrid.selectedRowsCount() > 1) {
            alertDialog.show({
                title: "Multiple deals selected!",
                message: "Oops!, you selected multiple deals. Please select a deal from the list to continue"
            });
        } else {
            if (productDealsDatagrid.selectedRowsCount() > 0) {
                const selectedProduct = productDeals.find(product => product.productId === productDealsDatagrid.getSelectedRowKeyValue());
                setSelectedProduct(selectedProduct);

                // listen to dialog for changes
                createOrEditProductObserver.current.show({
                    title: messages?.handleEdit?.dialogTitle,
                    data: selectedProduct,
                    isNew: false,
                    callback: () => {
                        getProductDeals({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show({
                    title: "No product selected!",
                    message: "Please select a product from the list to continue"
                });
            }
        }
    }

    const handleDelete = (e) => {
        if (productDealsDatagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (productDealsDatagrid.selectedRowsCount() + ((productDealsDatagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((productDealsDatagrid.selectedRowsCount() > 1) ? "these products?" : "this product?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    productService.remove(productDealsDatagrid.getSelectedRowsKeyValue()).then(response => {
                        getProductDeals({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                        toastr.success("Product successfully deleted");
                    }).catch(error => {
                        pageLoader.hide();
                        toastr.error("Oops! something went wrong.");
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No product selected!",
                message: "Please select a product from the list to continue"
            });
        }
    }

    const handleRefresh = (e) => {
        getProductDeals({
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handleSearchRecords = (e, args) => {
        setQueryString(args?.value);
        getProductDeals({
            queryString: args?.value,
            pageNumber: 1,
            rowsPerPage: rowsPerPage
        });
    }

    const handleFirstPage = (e, args) => {
        if (pageNumber > 1)
            getProductDeals({
                queryString: queryString,
                pageNumber: 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getProductDeals({
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (pageNumber < totalPages)
            getProductDeals({
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleRowsPerPageChange = (e, args) => {
        if (pageNumber < totalPages) {
            setRowsPerPage(args?.value);
            getProductDeals({
                queryString: queryString,
                pageNumber: pageNumber,
                rowsPerPage: args?.value
            });
        }
    }

    const handlePageNumberChange = (e, args) => {
        setPageNumber(args?.value);
        getProductDeals({
            queryString: queryString,
            pageNumber: args?.value,
            rowsPerPage: rowsPerPage
        });
    }

    const handleDatagridRowSelected = async (args) => {
        productDealDetailsViewObserver.current({
            data: args.selectedRow
        });
    }

    const handleDatagridRowDoubleClicked = async (args) => {
        const selectedProduct = productDeals.find(product => product.productId === args.selectedRow?.productId);
        setSelectedProduct(selectedProduct);

        createOrEditProductObserver.current.show({
            title: messages?.handleEdit?.dialogTitle,
            data: selectedProduct,
            isNew: false,
            callback: () => {
                getProductDeals({
                    queryString: queryString,
                    pageNumber: pageNumber,
                    rowsPerPage: rowsPerPage
                });
            }
        });
    }

    const handlePublish = (evt) => {
        if (productDealsDatagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (productDealsDatagrid.selectedRowsCount() + ((productDealsDatagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to publish " + ((productDealsDatagrid.selectedRowsCount() > 1) ? "these deals?" : "this deal?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    productService.publish(productDealsDatagrid.getSelectedRowsKeyValue()).then(response => {
                        getProducts({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No deal selected!",
                message: "Please select a deal or more from the list to publish"
            });
        }
    }

    const handleUnpublish = (evt) => {
        if (productDealsDatagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (productDealsDatagrid.selectedRowsCount() + ((productDealsDatagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to unpublish " + ((productDealsDatagrid.selectedRowsCount() > 1) ? "these deals?" : "this deal?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    productService.unpublish(productDealsDatagrid.getSelectedRowsKeyValue()).then(response => {
                        getProducts({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No deal selected!",
                message: "Please select a deal from the list to continue"
            });
        }
    }

    const handleProductFilterRecords = () => {
        productFilterObserver.current.show({
            title: messages?.handleFilter?.dialogTitle,
            data: selectedProduct,
            isNew: false,
            callback: () => {
                getProductDeals({
                    queryString: queryString,
                    pageNumber: pageNumber,
                    rowsPerPage: rowsPerPage
                });
            }
        });
    }

    return {
        componentObserver,
        productDeals,

        toolbar: (() => {
            return (
                <div className={themes?.toolbarCollectionWrapper}>
                    <div className={"w-50"}>
                        <Toolbar attributes={{
                            name: rightPaneToolbarName,
                            items: [
                                {
                                    name: rightToolbarButtons.filterButton,
                                    type: TOOLBARITEMTYPE.SEARCHFIELD,
                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                    leading: icons?.SearchRoundedIcon,
                                    placeholder: "Search",
                                    trailing: icons?.ArrowForwardRoundedIcon,
                                    observer: componentObserver,
                                    style: themes?.toolbar?.searchField,
                                    onClick: (e, args) => { handleSearchRecords(e, args) },
                                    filter: {
                                        placeholder: "Filter",
                                        leading: icons?.FilterAltRoundedIcon,
                                        onClick: (e, args) => { handleProductFilterRecords(e, args) },
                                    }
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: rightToolbarButtons.previousButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Previous",
                                    leading: icons?.ArrowBackIosRoundedIcon,
                                    onClick: (e, args) => { handlePreviousPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.startDropDownButton
                                },
                                {
                                    name: rightToolbarButtons.nextButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Next",
                                    leading: icons?.ArrowForwardIosRoundedIcon,
                                    onClick: (e, args) => { handleNextPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.endDropDownButtonNoLeftMargin
                                },
                            ]
                        }} />
                    </div>
                    <div className={"w-50 d-flex justify-content-end"}>
                        <Toolbar attributes={{
                            name: leftPaneToolbarName,
                            items: [
                                {
                                    name: leftToolbarButtons.addProductButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "Add",
                                    leading: icons?.AddRoundedIcon,
                                    onClick: (e) => handleCreate(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: leftToolbarButtons.editProductButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Edit",
                                    leading: icons?.EditRoundedIcon,
                                    onClick: (e) => handleEdit(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.deleteProductButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.DeleteRoundedIcon,
                                    onClick: (e) => handleDelete(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: leftToolbarButtons.deleteProductButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.ReplayRoundedIcon,
                                    onClick: (e) => handleRefresh(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: leftToolbarButtons.reloadProductButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    // text: "Publish",
                                    leading: icons?.ThumbUpAltIcon,
                                    onClick: (e) => handlePublish(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.reloadProductButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "Unpublish",
                                    leading: icons?.ThumbDownAltIcon,
                                    onClick: (e) => handleUnpublish(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                            ]
                        }} />
                    </div>
                </div>
            )
        })(),

        gridArea: (() => {
            return (
                <div className={"w-100 position-absolute end-0px top-53px start-0px bottom-0px "}>
                    <Grid attributes={{
                        dockType: DOCKTYPE.FILL,
                        fixedPane: GRIDPANE?.RIGHT,
                        paneWidth: themes?.minWidth440px,
                        allowAutoScroll: true,
                        pane: {
                            left: (
                                <div className={"position-absolute top-0px start-15px bottom-10px end-10px p-3 pt-1 pb-3"}>
                                    <DataGrid attributes={{
                                        name: productDealsDatagridName,
                                        observer: productDealsDatagridObserver,
                                        key: "dealId",
                                        columns: [
                                            {
                                                default: "",
                                                style: themes?.width15px
                                            },
                                            {
                                                type: COLUMNTYPE.CHECKBOX,
                                                value: "dealId",
                                                style: themes?.width30px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width5px
                                            },
                                            {
                                                type: COLUMNTYPE.IMAGETHUMBNAIL,
                                                value: "productMediaUrl",
                                                default: "",
                                                style: themes?.width35px,
                                                image: {
                                                    size: themes?.size35px,
                                                    leading: icons?.ShoppingBasketRoundedIcon,
                                                    style: themes?.image,
                                                }
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Name of deal",
                                                value: "dealName",
                                                default: "",
                                                style: themes?.width100,
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Price",
                                                value: "price",
                                                default: "",
                                                style: themes?.width120px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                type: COLUMNTYPE.FLAG,
                                                title: "",
                                                value: "status",
                                                default: "",
                                                style: themes?.width30px,
                                                flags: flags.orderFlags
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                // type: COLUMNTYPE.DATETIME,
                                                title: "Date",
                                                value: "dateCreated",
                                                default: "",
                                                style: themes?.width90px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width5px
                                            },
                                        ],
                                        isStripped: true,
                                        isSelectable: true,
                                        dockType: DOCKTYPE.FILL,
                                        onRowSelected: handleDatagridRowSelected,
                                        onRowDoubleClick: handleDatagridRowDoubleClicked,
                                        floatingBar: {
                                            page: {
                                                pageNumber: pageNumber,
                                                numberOfPages: totalPages,
                                                rowsPerPageList: global.variables.rowsPerPageList,
                                                onPageNumberChange: handlePageNumberChange,
                                                onRowsPerPageClick: handleRowsPerPageChange,
                                                onHomeClick: handleFirstPage,
                                                onPreviousClick: handlePreviousPage,
                                                onNextClick: handleNextPage
                                            }
                                        },
                                        dataset: productDeals
                                    }} />
                                </div>
                            ),

                            right: (
                                <div className={"position-absolute top-0px start-0px bottom-10px end-15px p-0 pe-0"}>
                                    <OrderDetailsView attributes={{
                                        observer: productDealDetailsViewObserver
                                    }}></OrderDetailsView>
                                </div>
                            )
                        }
                    }} />
                </div>
            )
        })(),

        // create or edit callout window
        productCreateOrEditCalloutWindowAttributes: {
            title: messages?.handleCreate?.dialogTitle,
            name: createOrEditProductDialogName,
            data: selectedProduct,
            observer: createOrEditProductObserver,
            widget: CreateOrEditProductDealView,
            slideDirection: SLIDEDIRECTION.RIGHT,
            style: {
                window: "max-width-500px"
            },
            buttons: [
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            pageLoader.close();
                            getProductDeals({
                                queryString: queryString,
                                pageNumber: pageNumber,
                                rowsPerPage: rowsPerPage
                            });

                            setSelectedProduct({});
                            createOrEditProductObserver.current.close();
                        });
                    }
                },
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                }
            ]
        },

        // product markets dialog
        productSheetCreateOrUploadDialogAttributes: {
            name: createOrUploadProductSheetDialogName,
            data: {},
            observer: createOrUploadProductSheetDialogObserver,
            widget: CreateOrUploadProductSheetView,
            style: {
                window: "max-width-900px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, async (args) => {
                            // await setCatererProductIngredientsList(args?.data);
                        });
                    }
                }
            ]
        },

    };
}

export default useProductDealsHook;