import { useHttps } from '../../../../../shared/utilities';


const WalletService = () => {
    const request = useHttps();
    const controller = "/wallets";
    
    return { 
        getAll: async (data) => await request.post(controller + "/get-all", data), 
        get: async (id) => await request.post(controller + "/get-all", id), 
        getByWalletNumber: async (number) => await request.post(controller + "/get-by-wallet-number", number),
        search: async (data) => await request.post(controller + "/get-all", data),
        save: async (data) => await request.post(controller + "/save", data), 
        update: async (data) => await request.post(controller + "/update", data),
        remove: async (data) => await request.post(controller + "/delete", data),
        publish: async (data) => await request.post(controller + "/publish", data),
        unpublish: async (data) => await request.post(controller + "/unpublish", data),
        sendToArchive: async (data) => await request.post(controller + "/archive", data),
        restoreArchive: async (data) => await request.post(controller + "/restore-archive", data),
        getWalletTransactions: async (data) => await request.post(controller + "/get-wallet-recent-transactions", data),
        getWalletRecentTransactions: async (data) => await request.post(controller + "/get-wallet-recent-transactions", data),
        fundWallet: async (data) => await request.post(controller + "/fund-wallet", data),
        transferToWallet: async (data) => await request.post(controller + "/transfer-to-wallet", data)
    }
}

export { WalletService }