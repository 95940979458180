import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useIconsThemes } from '../../shared/hooks/ui.icons.hook';
import { usePrimaryUIThemes } from '../../shared/hooks/ui.themes';
import { Toolbar, useToolbar } from '../../shared/components/toolbar/toolbar.component';
import { MENUALIGNMENTTYPE, MENUITEMTYPE, SLIDEDIRECTION, TOOLBARITEMTYPE } from '../../shared/enums/enums';
import MenuList from '../../shared/components/menu/menu.list.component';

import HomeView from './home/home.view';

import ProductView from './product.management/product/products.view';
import ContentsManagerView from './contents.management/contents.manager.view';
import ShoppersView from './market.management/shopper/shoppers.view';
import CouriersView from './market.management/courier/couriers.view';
import UserManagementView from './user.management/user.management.view';
import LoanManagementView from './finance.core/loan.management/loan.management.view';
import InvestorManagementView from './finance.core/investor.management/investor.management.view';
import SettingsView from './configuration.settings/settings/settings.view';
import MarketsView from './market.management/market/markets.view';
import CustomersView from './customer.relationship.management/customer/customers.view';
import CaterersView from './market.management/caterer/caterers.view';
import PharmaciesView from './market.management/pharmacy/pharmacies.view';
import ProductDealsView from './product.management/product.deal/product.deals.view';
import OrdersView from './order.management/orders/orders.view';
import OrderTransactionsView from './order.management/transactions/order.transactions.view';

const useAppCoreHook = () => {

    const [authUser, setAuthUser] = useState(JSON.parse(sessionStorage.getItem("auth")));
    const [selectedSidebarMenuItem, setSelectedSidebarMenuItem] = useState(null);

    // theme
    const themes = usePrimaryUIThemes();
    const icons = (useIconsThemes()).rounded;

    const navigate = useNavigate();
    // const urlQuery = useSearchParams();

    // useRefs
    const componentObserver = useRef();
    const collapsibleMenuListObserver = useRef(null);
    const toastrObserver = useRef(null);

    const topLeftMenuToolbarName = "top-left-menu-toolbar";
    const topLeftMenuToolbar = useToolbar({
        name: topLeftMenuToolbarName
    });
    const topLeftMenuToolbarItems = {
        accountNameLabelName: "logged-in-account-name-label",
        accountMenuItemButtonName: "account-menu-item-button",
    }

    // callbacks
    const handleOnItemClick = useCallback(async (args) => {
        setSelectedSidebarMenuItem(args);
        navigate(args.url);
    }, [navigate]);

    useEffect(() => {
        (async () => {
            if (!selectedSidebarMenuItem) {
                setSelectedSidebarMenuItem((typeof collapsibleMenuListObserver.current === 'function') ? collapsibleMenuListObserver.current() : null);
            }

            // set selected sidebar menu item
            setSelectedSidebarMenuItem(collapsibleMenuListObserver.current?.getSelectedMenuItem());

            // set logged in user details
            setAuthUser(JSON.parse(sessionStorage.getItem("auth")));

            // 
            topLeftMenuToolbar.label({
                name: topLeftMenuToolbarItems.accountNameLabelName
            }).setText("Welcome! Campilax");
        })();
    }, []);

    const onProfileAvatarItemClick = async (args) => {
        switch (args.url) {
            case "profile-account":
                break;
            case "settings":
                break;
            case "change-password":
                break;
            case "sign-out":
                navigate("/")
                break;
            default:
                break;
        }
    }

    // 
    const handleToolbarMoreOptionsMenuItemClick = async (args) => {
        switch (args.name) {
            case "update-market-with-new-product-menuitem":
                // updateMarketWithNewProducts();
                break;
            case "update-stock-item-menuitem":
                // updateMarketProductsInventoryStockItem();
                break;
            case "add-products-menuitem":
                // addProductsToMarket();
                break;
            case "remove-product-menuitem":
                // removeProduct();
                break;
            case "browse-product-details-menuitem":
                // browseProductDetails();
                break;
            case "manage-product-inventory-menuitem":
            default:
                // manageMarketProductsInventory();
                break;
        }
    }

    return {
        authUser,
        selectedSidebarMenuItem: selectedSidebarMenuItem,

        // 
        toolbar: <Toolbar attributes={{
            name: topLeftMenuToolbarName,
            items: [
                {
                    name: topLeftMenuToolbarItems.accountNameLabelName,
                    type: TOOLBARITEMTYPE.LABEL,
                    observer: componentObserver,
                    style: themes?.toolbar?.label?.label13px,
                },
                {
                    type: TOOLBARITEMTYPE.SPACE,
                    style: themes?.toolbar?.space?.space15
                }
            ]
        }} />,

        // 
        authNob: <Toolbar attributes={{
            name: topLeftMenuToolbarName,
            items: [
                {
                    name: topLeftMenuToolbarItems.accountMenuItemButtonName,
                    type: TOOLBARITEMTYPE.DROPDOWNBUTTON,
                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                    leading: icons?.AccountCircleIcon,
                    onClick: (e) => { },
                    observer: componentObserver,
                    style: themes?.toolbar?.flatButton,
                    widget: <MenuList attributes={{
                        items: [
                            {
                                leading: icons?.DeleteRoundedIcon,
                                name: "account-profile-menuitem",
                                title: "Account profile",
                            },
                            {
                                type: MENUITEMTYPE.SEPARATOR,
                            },
                            {
                                leading: icons?.AddRoundedIcon,
                                name: "account-settings-menuitem",
                                title: "Account settings",
                            },
                            {
                                leading: icons?.PlaylistAddRoundedIcon,
                                name: "account-signout-menuitem",
                                title: "Sign out",
                            }
                        ],
                        style: themes?.menuList,
                        onItemClick: handleToolbarMoreOptionsMenuItemClick,
                        isSelectable: false
                    }} />
                }
            ]
        }} />,

        // 
        sidebarMenuCollectionAttributes: {
            name: "startup-collapsible-sidebar-menu",
            style: themes?.collapsibleSidebarMenu,
            items: [
                {
                    title: "Getting Started",
                    text: "Getting Started",
                    url: "dashboard",
                    leading: icons.DashboardCustomizeRoundedIcon,
                    // selected: true
                },
                {
                    title: "Home",
                    text: "Home",
                    url: "home",
                    leading: icons.HomeRoundedIcon,
                    // selected: true
                },
                {},
                {
                    title: "Markets Management",
                    text: "Markets Management",
                    leading: icons.StoreMallDirectoryRoundedIcon,
                    items: [
                        {
                            leading: icons.StoreMallDirectoryRoundedIcon,
                            title: "Markets",
                            text: "Markets",
                            url: "markets",
                        },
                        {
                            leading: icons.HealthAndSafetyRoundedIcon,
                            title: "Pharmacies & Stores",
                            text: "Pharmacies & Stores",
                            url: "pharmacies",
                        },
                        {
                            leading: icons.SoupKitchenIcon,
                            title: "Food Caterers",
                            text: "Food Caterers",
                            url: "caterers",
                        },
                        {
                            leading: icons.PeopleRoundedIcon,
                            title: "Shoppers",
                            text: "Shoppers",
                            url: "market-shoppers",
                        },
                        {
                            leading: icons.PeopleRoundedIcon,
                            title: "Couriers",
                            text: "Couriers",
                            url: "market-couriers",
                        }
                    ]
                    // 
                },
                {
                    title: "Product Management",
                    text: "Product Management",
                    leading: icons.BrandingWatermarkRoundedIcon,
                    items: [
                        {
                            leading: icons.BrandingWatermarkRoundedIcon,
                            title: "Products",
                            text: "Products",
                            url: "products",
                        },
                        {
                            leading: icons.LocalOfferRoundedIcon,
                            title: "Deals & Offers",
                            text: "Deals & Offers",
                            url: "product-deals",
                        },
                    ]
                },
                {},
                // customers
                {
                    title: "Customers",
                    text: "Customers",
                    leading: icons.PeopleRoundedIcon,
                    url: "customers",
                },
                {
                    title: "Orders & Requests",
                    text: "Orders & Requests",
                    url: "orders",
                    leading: icons.ShoppingBasketRoundedIcon,
                },
                {
                    title: "Transactions",
                    text: "Transactions",
                    url: "transactions",
                    leading: icons.CompareArrowsRoundedIcon,
                },
                // {},
                // // content management
                // {
                //     title: "Content Management",
                //     text: "Content Management",
                //     url: "content-management",
                //     leading: icons.NewspaperRoundedIcon,
                // },
                // {
                //     title: "Media Gallery",
                //     text: "Media Gallery",
                //     url: "media-gallery",
                //     leading: icons.PermMediaRoundedIcon,
                // },
                // // {
                // //     title: "Design Editor",
                // //     text: "Design Editor",
                // //     url: "site-pages-editor",
                // //     leading: icons.DesignServicesRoundedIcon
                // // },
                {},
                {
                    title: "Users & Roles Management",
                    text: "Users & Roles Management",
                    leading: icons.SupervisedUserCircleRoundedIcon,
                    items: [
                        {
                            title: "Users",
                            text: "Users",
                            url: "user-management",
                        },
                        {
                            title: "Roles & Privileged",
                            text: "Roles & Privileged",
                            url: "role-management",
                        },
                    ]
                },
                {},
                {
                    title: "Settings",
                    text: "Settings",
                    leading: icons.SettingsRoundedIcon,
                    url: "settings",
                },
                // {
                //     title: "Reports & Analytics",
                //     text: "Reports & Analytics",
                //     url: "reports",
                //     leading: icons.AssessmentRoundedIcon,
                // },
                // // {
                // //     title: "Administration",
                // //     text: "Administration",
                // //     url: "settings",
                // //     leading: icons.SettingsRoundedIcon,
                // // },
                // fintech
                // {},
                // {
                //     title: "Unbankd",
                //     text: "Unbankd",
                //     leading: icons.WalletRoundedIcon,
                //     items: [
                //         {
                //             title: "Savings Wallet",
                //             text: "Savings Wallet",
                //             url: "wallet-savings",
                //             leading: icons.WalletRoundedIcon
                //         },
                //         {
                //             title: "Loan Management",
                //             text: "Loan Management",
                //             url: "loan-management",
                //             leading: icons.TollRoundedIcon,
                //         },
                //         {
                //             title: "Collateral Register",
                //             text: "Collateral Register",
                //             url: "collateral-register",
                //             leading: icons.StyleIcon,
                //         },
                //         {
                //             title: "Investors",
                //             text: "Investors",
                //             url: "investors",
                //             leading: icons.Groups2Icon,
                //         }
                //     ]
                // },
                // {},
                // logistics
                // {
                //     title: "Fleet & Logistic",
                //     text: "Fleet & Logistic",
                //     leading: icons.DeliveryDiningRoundedIcon,
                //     items: [
                //         {
                //             title: "Dispatch Riders",
                //             text: "Dispatch Riders",
                //             url: "dispatch-riders",
                //             leading: icons.DirectionsBikeIcon
                //         },
                //         {
                //             title: "Manage Motorbikes",
                //             text: "Manage Motorbikes",
                //             url: "manage-motorbikes",
                //             leading: icons.DeliveryDiningRoundedIcon
                //         },
                //         {
                //             title: "Package Tracking",
                //             text: "Package Tracking",
                //             url: "package-tracking",
                //             leading: icons.ShareLocationIcon,
                //         },
                //         {
                //             title: "Delivery History",
                //             text: "Delivery History",
                //             url: "delivery-history",
                //             leading: icons.TimelineIcon,
                //         },
                //         {
                //             title: "Manage Delivery Fee",
                //             text: "Manage Delivery Fee",
                //             url: "manage-delivery-fee",
                //             leading: icons.CreditCardIcon,
                //         },
                //         {
                //             title: "Invoices",
                //             text: "Invoices",
                //             url: "invoices",
                //             leading: icons.ReceiptRoundedIcon,
                //         },
                //         {
                //             title: "Routes Management",
                //             text: "Routes Management",
                //             url: "routes-management",
                //             leading: icons.RouteIcon,
                //         },
                //         {
                //             title: "Payment Options",
                //             text: "Payment Options",
                //             url: "payment-options",
                //             leading: icons.StyleIcon,
                //         },
                //     ]
                // }
            ],
            trigger: handleOnItemClick,
            observer: collapsibleMenuListObserver,
            showIcon: true,
            showChildIcon: true
        },

        // 
        searchField: {
            placeholder: "Find help",
            leading: icons?.SearchRoundedIcon,
            style: themes?.field?.searchField
        },

        toastrAttributes: {
            name: "toastr",
            observer: toastrObserver,
            slideDirection: SLIDEDIRECTION.TOPRIGHT,
            timeout: 4,
            buttons: [
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {

                    }
                },
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                }
            ]
        },

        infiniteLoaderAttributes: {
            name: "page-loading-bar",
            style: themes?.infiniteLoader
        },

        pageLoaderAttributes: {
            name: "page-loading-bar",
            style: themes?.pageLoader
        },

        // 
        routes: <Routes>
            <Route path='/' element={<HomeView />} />

            {/* HOME */}
            < Route path='/' element={< HomeView />} />

            {/* MARKET MANAGEMENT */}
            <Route path='/markets' element={<MarketsView />} />
            <Route path='/caterers' element={<CaterersView />} />
            <Route path='/pharmacies' element={<PharmaciesView />} />
            <Route path='/market-shoppers' element={<ShoppersView />} />
            <Route path='/market-couriers' element={<CouriersView />} />

            {/* PRODUCT MANAGEMENT */}
            <Route path='/products' element={<ProductView />} />
            <Route path='/product-deals' element={<ProductDealsView />} />

            <Route path='/customers' element={<CustomersView />} />
            <Route path='/orders' element={<OrdersView />} />
            <Route path='/transactions' element={<OrderTransactionsView />} />

            <Route path='/content-management' element={<ContentsManagerView />} />

            <Route path='/user-management' element={<UserManagementView />} />
            <Route path='/role-management' element={<UserManagementView />} />
            <Route path='/settings/*' element={<SettingsView />} />

            <Route path='/loan-management' element={<LoanManagementView />} />
            <Route path='/collateral-register' element={<LoanManagementView />} />
            <Route path='/investors' element={<InvestorManagementView />} />


        </Routes >,

        // 
        onProfileAvatarItemClick,
    };
}

export default useAppCoreHook;