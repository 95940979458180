import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIconsThemes } from '../../../../shared/hooks/ui.icons.hook';
import { usePrimaryUIThemes } from '../../../../shared/hooks/ui.themes';
import { useStackablePanel } from '../../../../shared/components/stackable.panel/stackable.panel.component';

const useSettingsHook = () => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = (useIconsThemes()).rounded;
    const customUIThemes = {
        collapsibleList: {
            leading: "icon icon-22px text-18px text-uppercase text-blue-theme line-height-19px",
            title: "text-13px text-normal line-height-22px text-blue-theme",
            items: {
                leading: "text-18px text-uppercase text-grey-theme-",
                title: "text-13px line-height-22px",
                item: "mb-0",
                selected: "selected-bg-blue-theme",
                hover: "hover-bg-blue-light-theme"
            },
        }
    };

    const [selectedMenuItem, setSelectedMenuItem] = useState(null);

    const stackablePanelComponentName = "settings-stackable-panel";

    // custom hooks
    const stackablePanelComponent = useStackablePanel({
        name: stackablePanelComponentName
    });

    const navigate = useNavigate();

    // useRef
    const componentObserver = useRef(null);

    // callbacks
    const handleStackablePanelToggle = useCallback((tabIndex) => {
        stackablePanelComponent.setPanel(1);
    }, [])

    useEffect(() => {
        (async () => {
            if (!selectedMenuItem) {
                setSelectedMenuItem(componentObserver.current);
            }
            // collapsibleSidebarMenu?.setSelectedMenuItem(componentObserver.current);
        })();
    }, []);

    const handleOnItemClick = async (args) => {
        setSelectedMenuItem(args);
        // collapsibleSidebarMenu?.setSelectedMenuItem(args);
        navigate(args.url);
    };


    return {
        sideMenuCollection: {
            name: "settings-collapsible-sidebar-menu",
            style: themes?.collapsibleSidebarMenu,
            items: [
                {
                    title: "My Account",
                    text: "My Account",
                    url: "",
                    leading: icons.DashboardCustomizeRoundedIcon,
                    // selected: true
                },
                // {
                //     title: "Market",
                //     text: "Market",
                //     leading: icons.StorefrontRoundedIcon,
                //     items: [
                //         {
                //             leading: icons.StoreMallDirectoryRoundedIcon,
                //             title: "Market Types",
                //             text: "Market Types",
                //             url: "market-types",
                //         },
                //     ]
                //     // 
                // },
                {
                    title: "Product Management",
                    text: "Product Management",
                    leading: icons.BrandingWatermarkRoundedIcon,
                    items: [
                        {
                            leading: icons.BrandingWatermarkRoundedIcon,
                            title: "Categories",
                            text: "Categories",
                            url: "product-categories",
                        },
                        {
                            leading: icons.BrandingWatermarkRoundedIcon,
                            title: "Weight & Sizes",
                            text: "Weight & Sizes",
                            url: "product-variations",
                        },
                        {
                            leading: icons.BrandingWatermarkRoundedIcon,
                            title: "Colors",
                            text: "Colors",
                            url: "product-colors",
                        },
                        {
                            leading: icons.BrandingWatermarkRoundedIcon,
                            title: "Types",
                            text: "Types",
                            url: "product-types",
                        },
                        {
                            leading: icons.BrandingWatermarkRoundedIcon,
                            title: "Brands",
                            text: "Brands",
                            url: "product-brands",
                        },
                        {
                            leading: icons.BrandingWatermarkRoundedIcon,
                            title: "Uploaded Images",
                            text: "Uploaded Images",
                            url: "product-images",
                        }
                    ]
                },
                {
                    title: "User Management",
                    text: "User Management",
                    leading: icons.BrandingWatermarkRoundedIcon,
                    items: [
                        {
                            leading: icons.BrandingWatermarkRoundedIcon,
                            title: "Roles",
                            text: "Roles",
                            url: "categories",
                        },
                        {
                            leading: icons.BrandingWatermarkRoundedIcon,
                            title: "Privileges",
                            text: "Privileges",
                            url: "product-categories",
                        }
                    ]
                },
                {
                    title: "Banks",
                    text: "Banks",
                    url: "banks",
                    leading: icons.AccountBalanceRoundedIcon,
                },
                {
                    title: "Transaction Channels",
                    text: "Transaction Channels",
                    url: "transaction-channels",
                    leading: icons.AccountTreeIcon,
                },
                {
                    title: "Currencies",
                    text: "Currencies",
                    url: "currencies",
                    leading: icons.PaymentsIcon,
                },
            ],
            trigger: handleOnItemClick,
            observer: componentObserver,
            showIcon: true,
            showChildIcon: true
        },
    };
}

export default useSettingsHook;