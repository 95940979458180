import { useCallback, useEffect, useRef, useState } from 'react';
import { Avatar } from '../../../../../shared/components/avatar';
import { CalloutWindow } from '../../../../../shared/components/callout/callout.window.component';
import { useToastr } from '../../../../../shared/components/toastr/toastr.component';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { useAlertDialog } from '../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import { useStackablePanel } from '../../../../../shared/components/stackable.panel/stackable.panel.component';
import { Toolbar, useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { ALIGNMENTTYPE, COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, MENUITEMTYPE, PAGEDIRECTION, SLIDEDIRECTION, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import useAppConfig from '../../../../../shared/hooks/app.config.hook';
import useFlags from '../../../../../shared/hooks/flags.hook';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { ProductImageService } from '../../../configuration.settings/settings/product.images/product.image.service';
import AddMarketProductView from '../add.market.product/add.market.product.view';
import ManageMarketProductListView from '../manage.market.product.list/manage.market.product.list.view';
import { MarketService } from '../market.service';
import { MarketProductService } from './market.product.service';
import CreateOrEditMarketProductView from '../createoredit.market.product/createoredit.market.product.view';
import global from '../../../../../shared/helpers/global.variable.helper';
import { useInfiniteLoader } from '../../../../../shared/components/loader/infinite.loader.component';
import MenuList from '../../../../../shared/components/menu/menu.list.component';

const useMarketProductsHook = (attributes) => {

    const appConfig = useAppConfig();

    // theme
    const icons = useIconsThemes().rounded;
    const themes = usePrimaryUIThemes();

    // flags
    const flags = useFlags();

    // state objects
    const [selectedMarket, setSelectedMarket] = useState({});
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState((global.variables.rowsPerPageList.find(x => x.default === true).rowValue));
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    // 
    const actionButtonsMenuItems = {
        deleteProductMenuItem: "delete-market-inventory-product-menuitem",
        editProductMenuItem: "caterer-product-transactions-menuitem",
        categoriesMenuItem: "caterer-product-categories-menuitem",
        variationsMenuItem: "caterer-product-variations-menuitem",
        typeMenuItem: "caterer-product-types-menuitem",
        colorMenuItem: "caterer-product-colors-menuitem",
        browseCatererPageMenuItem: "browse-caterer-products-page-menuitem",
        manageCatererAccountMenuItem: "manage-caterer-account-menuitem"
    };

    // 
    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No product selected!",
            message: "Please select an market from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New market",
            successMessage: {
                title: "Market created successfully!",
                message: "Market was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit market details",
            alertMessage: {
                title: "Multiple markets selected!",
                message: "Oops!, you selected multiple markets. Please select a market from the list to continue"
            },
            successMessage: {
                title: "Market updated successfully!",
                message: "Market was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit market details",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these markets?" : "this market?")
                }
            },
            successMessage: {
                title: "Market deleted successfully!",
                message: "Market was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these markets?" : "this market?")
                }
            },
            successMessage: {
                title: "Market published successfully!",
                message: "Market was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these markets?" : "this market?")
                }
            },
            successMessage: {
                title: "Market published successfully!",
                message: "Market was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these markets?" : "this market?")
                }
            },
            successMessage: {
                title: "Market archived successfully!",
                message: "Market was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these markets?" : "this market?")
                }
            },
            successMessage: {
                title: "Market restored successfully!",
                message: "Market was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available markets, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();
    const addMarketProductsObserver = useRef();
    const marketLogoObserver = useRef();
    const manageSelectedMarketProductCalloutWindowObserver = useRef();
    const selectedProductCalloutWindowObserver = useRef();
    const totalRowsCount = useRef(0);
    const lastRowsCount = useRef(0);

    // components
    const stackablePanelComponentName = "market-products-stackable-panel";
    const marketProductDatagridName = "products-datagrid";
    const manageSelectedMarketProductCalloutWindowName = "manage-market-product-callout-window";
    const selectedProductCalloutWindowName = "selected-market-product-inventory-item-callout-window";

    const stackablePanelComponent = useStackablePanel({
        name: stackablePanelComponentName
    });

    const datagrid = useDataGrid({
        name: marketProductDatagridName
    });

    const toolbarButtons = {
        addProductButtonName: "add-market-product-button",
        editProductButtonName: "edit-market-product-button",
        deleteProductButtonName: "delete-market-product-button"
    }

    const rightPaneToolbarName = "products-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // dialogs
    const addMarketProductDialogName = "add-market-product-modal";

    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();
    const addMarketProductDialog = useModalDialog({
        name: addMarketProductDialogName
    });

    // toastr
    const toastr = useToastr();

    // loader
    const pageLoader = usePageLoader();
    const infiniteLoader = useInfiniteLoader();

    // services
    const marketService = MarketService();
    const marketProductService = MarketProductService();
    const productImageService = ProductImageService();

    const getMarketProducts = useCallback(async (request) => {
        infiniteLoader.show();
        datagrid?.showLoader(messages?.loader?.fetchingMessage);
        await marketProductService.getMarketProducts(request)
            .then(response => {
                infiniteLoader.hide();
                datagrid?.hideLoader();

                // showing rows
                if (request.direction === PAGEDIRECTION.PREVIOUS) {
                    totalRowsCount.current -= lastRowsCount.current;
                } else if (request.direction === PAGEDIRECTION.NEXT) {
                    totalRowsCount.current += response?.data?.list?.length;
                } else {
                    totalRowsCount.current = response?.data?.list?.length;
                }

                // populate datagrid 
                setProducts(response?.data?.list);
                lastRowsCount.current = response?.data?.list?.length;

                // set page
                setQueryString(request?.queryString);
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);
                // 
            })
            .catch(error => {
                infiniteLoader.hide();
                datagrid?.hideLoader();
            });

    }, []);

    const widgetObserver = useCallback(async (args) => {
        stackablePanelComponent.setPanel(0);

        // load image
        marketLogoObserver.current.loadImage(args?.data);

        if (args?.data !== null) {

            // selected market
            setSelectedMarket(args?.data);

            // get products in market
            await getMarketProducts({
                marketId: args?.data?.marketId,
                queryString: queryString,
                pageNumber: pageNumber,
                rowsPerPage: rowsPerPage,
                sort: {}
            });

        } else {
            // form.clear();
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;
        })();
    }, []);

    const handleAddProductsToMarket = (e) => {
        addMarketProductsObserver.current.show({
            title: "Available products",
            data: selectedMarket,
            isNew: false,
            callback: () => {
                //
            }
        });
    }

    const handleReloadMarketProducts = (e) => {
        getMarketProducts({
            marketId: selectedMarket?.marketId,
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handleDeleteMarketProduct = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these products?" : "this product?"),
                onConfirmed: (e) => {
                    infiniteLoader.show();
                    marketProductService.remove(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getMarketProducts({
                            marketId: selectedMarket?.marketId,
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        infiniteLoader.hide();
                        toastr.success("Product successfully removed from market.");
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No product selected!",
                message: "Please select a product from the list to continue"
            });
        }
    }

    const handleBrowseProduct = (e) => {
        const selectedProduct = products.find(product => product.marketProductId === datagrid.getSelectedRowKeyValue());
        setSelectedProduct(selectedProduct);
        handleDatagridRowDoubleClick({ selectedRow: selectedProduct });
    }

    const handleSearchRecords = async (e, args) => {
        await getMarketProducts({
            marketId: selectedMarket?.marketId,
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getMarketProducts({
                marketId: selectedMarket?.marketId,
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage,
                direction: PAGEDIRECTION.PREVIOUS
            });
    }

    const handleNextPage = (e, args) => {
        if (pageNumber < totalPages)
            getMarketProducts({
                marketId: selectedMarket?.marketId,
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage,
                direction: PAGEDIRECTION.NEXT
            });
    }

    const handleRowsPerPageChange = (e, args) => {
        getMarketProducts({
            marketId: selectedMarket?.marketId,
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: args?.value
        });
    }

    const handlePageNumberChange = (e, args) => {
        getMarketProducts({
            marketId: selectedMarket?.marketId,
            queryString: queryString,
            pageNumber: args?.value,
            rowsPerPage: rowsPerPage
        });
    }

    const handleFirstPage = async (e, args) => {
        if (pageNumber > 1)
            await getMarketProducts({
                marketId: selectedMarket?.marketId,
                queryString: queryString,
                pageNumber: 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleSelectedMarketProductUpdate = async (args) => {
        await marketProductService.update(args).then(async (response) => {
            // await getMarketProducts({
            //     marketId: selectedMarket?.marketId,
            //     queryString: queryString,
            //     pageNumber: pageNumber,
            //     rowsPerPage: rowsPerPage
            // });
        }).catch((error) => {
            // args?.error(error);
        });
    }

    const handleDatagridRowDoubleClick = async (args, e) => {
        const selectedProduct = products.find(product => product.productId === args.selectedRow?.productId);
        setSelectedProduct(selectedProduct);
        selectedProductCalloutWindowObserver.current.show({
            title: "Product details",
            data: selectedProduct,
            isNew: false
        });
    }

    const handleProductToolbarOptionsMenuItemClick = (args) => {
        switch (args.name) {
            case actionButtonsMenuItems?.deleteProductMenuItem:
                handleDeleteMarketProduct();
                break;

            // case actionButtonsMenuItems?.typeMenuItem:
            //     marketProductTypesCalloutWindowObserver.current.show({
            //         title: messages?.manageTypes?.dialogTitle,
            //         data: {
            //             catererId: selectedCaterer?.catererId
            //         },
            //         isNew: true
            //     });
            //     break;

            // case actionButtonsMenuItems?.colorMenuItem:
            //     catererProductColorsCalloutWindowObserver.current.show({
            //         title: messages?.manageColors?.dialogTitle,
            //         data: {
            //             catererId: selectedCaterer?.catererId
            //         },
            //         isNew: true
            //     });
            //     break;

            // case actionButtonsMenuItems?.variationsMenuItem:
            //     catererProductVariationsCalloutWindowObserver.current.show({
            //         title: messages?.manageVariations?.dialogTitle,
            //         data: {
            //             catererId: selectedCaterer?.catererId
            //         },
            //         isNew: true
            //     });
            //     break;

            // case actionButtonsMenuItems?.categoriesMenuItem:
            //     catererProductCategoriesCalloutWindowObserver.current.show({
            //         title: messages?.manageCategory?.dialogTitle,
            //         data: {
            //             catererId: selectedCaterer?.catererId
            //         },
            //         isNew: true
            //     });
            //     break;

            // case actionButtonsMenuItems?.transactionsMenuItem:
            //     catererCustomersTransactionsCalloutWindowObserver.current.show({
            //         title: messages?.handleCreate?.dialogTitle,
            //         data: {
            //             catererId: selectedCaterer?.catererId
            //         },
            //         isNew: true
            //     });
            //     break;

            // case actionButtonsMenuItems.customerOrderMenuItem:
            default:
                // catererCustomersOrdersCalloutWindowObserver.current.show({
                //     title: messages?.handleCreate?.dialogTitle,
                //     data: {
                //         catererId: selectedCaterer?.catererId
                //     },
                //     isNew: true
                // });
                break;
        }
    }

    return {

        stackablePanelAttributes: {
            name: stackablePanelComponentName,
            items: [
                {
                    widget: (() => {
                        return (
                            <div className={"position-absolute top-0px start-0px bottom-0px w-100 pt-0 pb-5 border-solid border-top-0px border-bottom-0px border-start-0px border-end-0px"}>
                                {/* add market product */}
                                <CalloutWindow attributes={{
                                    title: messages?.handleCreate?.dialogTitle,
                                    name: addMarketProductDialogName,
                                    data: null,
                                    observer: addMarketProductsObserver,
                                    widget: AddMarketProductView,
                                    callback: (args) => {
                                        getMarketProducts({
                                            marketId: selectedMarket?.marketId,
                                            queryString: queryString,
                                            pageNumber: pageNumber,
                                            rowsPerPage: rowsPerPage
                                        });
                                        // infiniteLoader.hide();
                                        toastr.success("Product successfully removed from market.");
                                    },
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    dockType: DOCKTYPE.UNDOCK,
                                    style: {
                                        window: "max-width-710px"
                                    },
                                    buttons: [
                                        {
                                            text: "Cancel",
                                            style: themes?.button?.secondary,
                                            onClick: (e, winArgs) => winArgs.close(e)
                                        },
                                        {
                                            text: "Apply",
                                            style: themes?.button?.secondary,
                                            onClick: (e, winArgs) => {
                                                winArgs.apply(e, async (args) => {
                                                    await getMarketProducts({
                                                        marketId: selectedMarket?.marketId,
                                                        queryString: queryString,
                                                        pageNumber: pageNumber,
                                                        rowsPerPage: rowsPerPage
                                                    });
                                                });
                                            }
                                        },
                                        {
                                            text: "Continue",
                                            style: themes?.button?.primary,
                                            onClick: (e, winArgs) => {
                                                winArgs.submit(e, async (args) => {
                                                    await getMarketProducts({
                                                        marketId: selectedMarket?.marketId,
                                                        queryString: queryString,
                                                        pageNumber: pageNumber,
                                                        rowsPerPage: rowsPerPage
                                                    });
                                                });
                                            }
                                        }
                                    ]
                                }} />

                                {/* manage market product list */}
                                <CalloutWindow attributes={{
                                    title: messages?.handleCreate?.dialogTitle,
                                    name: manageSelectedMarketProductCalloutWindowName,
                                    data: selectedMarket,
                                    observer: manageSelectedMarketProductCalloutWindowObserver,
                                    widget: ManageMarketProductListView,
                                    callback: (args) => {
                                        getMarketProducts({
                                            marketId: selectedMarket?.marketId,
                                            queryString: queryString,
                                            pageNumber: pageNumber,
                                            rowsPerPage: rowsPerPage
                                        });
                                        // infiniteLoader.hide();
                                        toastr.success("Product successfully removed from market.");
                                    },
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    dockType: DOCKTYPE.UNDOCK,
                                    style: {
                                        window: "max-width-510px"
                                    }
                                }} />

                                {/* market product item details */}
                                <CalloutWindow attributes={{
                                    name: selectedProductCalloutWindowName,
                                    data: selectedProduct,
                                    observer: selectedProductCalloutWindowObserver,
                                    widget: CreateOrEditMarketProductView,
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    dockType: DOCKTYPE.FILL,
                                    style: {
                                        window: "max-width-500px"
                                    },
                                    buttons: [
                                        {
                                            text: "Save changes",
                                            style: themes?.button?.primary,
                                            onClick: (e, winArgs) => {
                                                winArgs.submit(e, async (args) => {
                                                    console.log("Successful");
                                                    // infiniteLoader.hide();
                                                    await getMarketProducts({
                                                        marketId: selectedMarket?.marketId,
                                                        queryString: queryString,
                                                        pageNumber: pageNumber,
                                                        rowsPerPage: rowsPerPage
                                                    });
                                                });
                                            }
                                        },
                                        {
                                            text: "Cancel",
                                            style: themes?.button?.secondary,
                                            onClick: (e, winArgs) => winArgs.close(e)
                                        }
                                    ]
                                }} />

                                {/* header view */}
                                <div className={themes?.toolbarCollectionWrapperRightPadding}>
                                    <div className={"w-50 d-flex justify-content-start align-items-center mb-1 text-truncate"}>
                                        <div className={"max-width-38px min-width-38px height-38px rounded-5px position-relative me-2 overflow-hidden"}>
                                            <Avatar attributes={{
                                                name: "caterer-product-logo",
                                                formElement: true,
                                                validate: true,
                                                id: "mediaId",
                                                key: "mediaId",
                                                url: "mediaUrl",
                                                size: themes?.sizeAbsolute,
                                                leading: icons?.PhotoSizeSelectActualIcon,
                                                style: themes?.image38,
                                                model: selectedMarket,
                                                observer: marketLogoObserver,
                                                config: {
                                                    ownerId: appConfig?.sessionAuthDetails?.userId,
                                                    uploadUrl: marketService?.uploadUrl,
                                                    downloadUrl: marketService?.downloadUrl
                                                }
                                            }} />
                                        </div>
                                        <div className={"mt-1"}>
                                            <div className={"d-flex align-items-center text-blue-theme text-13px text-bold mb-1 max-width-200px-"}>
                                                <span className={"me-2 text-truncate line-height-14px height-15px"}>{selectedMarket?.marketName}</span>
                                            </div>
                                            <div className={"d-flex text-12px m-0 opacity-8 text-truncate"}>
                                                <div className={"d-flex align-items-center justify-content-start text-12px me-3 text-truncate"}>
                                                    <span className={"d-flex text-blue-theme text-truncate w-100 me-1"}>Showing {totalRowsCount.current} of {totalRows} inventory items</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"w-70 d-flex justify-content-end"}>
                                        <Toolbar attributes={{
                                            name: rightPaneToolbarName,
                                            items: [
                                                {
                                                    type: TOOLBARITEMTYPE.SEARCHFIELD,
                                                    name: rightToolbarButtons.filterButtonName,
                                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                                    leading: icons?.SearchRoundedIcon,
                                                    placeholder: "Find a product...",
                                                    trailing: icons?.ArrowForwardRoundedIcon,
                                                    style: themes?.toolbar?.searchField250px,
                                                    onClick: (e, args) => { handleSearchRecords(e, args) },
                                                    filter: {
                                                        placeholder: "Filter",
                                                        leading: icons?.FilterAltRoundedIcon,
                                                        onClick: (e, args) => { handleProductFilterRecords(e, args) },
                                                    }
                                                },
                                                {
                                                    type: TOOLBARITEMTYPE.SPACE,
                                                    style: themes?.toolbar?.space?.space4
                                                },
                                                {
                                                    name: toolbarButtons.deleteProductButtonName,
                                                    type: TOOLBARITEMTYPE.BUTTON,
                                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                                    leading: icons?.ReplayRoundedIcon,
                                                    onClick: handleReloadMarketProducts,
                                                    observer: componentObserver,
                                                    style: themes?.toolbar?.button,
                                                },
                                                {
                                                    type: TOOLBARITEMTYPE.SPACE,
                                                    style: themes?.toolbar?.space?.space4
                                                },
                                                {
                                                    name: toolbarButtons.deleteProductButtonName,
                                                    type: TOOLBARITEMTYPE.DROPDOWNBUTTON,
                                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                                    leading: icons?.MoreVertRoundedIcon,
                                                    onClick: () => { },
                                                    observer: componentObserver,
                                                    style: themes?.toolbar?.endButton,
                                                    widget: <MenuList attributes={{
                                                        items: [
                                                            {
                                                                name: actionButtonsMenuItems.editProductMenuItem,
                                                                leading: icons?.EditRoundedIcon,
                                                                title: "Edit product",
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.deleteProductMenuItem,
                                                                leading: icons?.DeleteRoundedIcon,
                                                                title: "Delete",
                                                            },
                                                            {
                                                                type: MENUITEMTYPE.SEPARATOR
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.categoriesMenuItem,
                                                                leading: icons?.CategoryCardsRoundedIcon,
                                                                title: "Manage products",
                                                                transparent: true,
                                                            },
                                                            {
                                                                type: MENUITEMTYPE.SEPARATOR
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.customerOrderMenuItem,
                                                                leading: icons?.ShoppingBasketRoundedIcon,
                                                                title: "Customers' orders",
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.transactionsMenuItem,
                                                                leading: icons?.ReceiptRoundedIcon,
                                                                title: "Transactions dashboard",
                                                            },
                                                            {
                                                                type: MENUITEMTYPE.SEPARATOR
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.categoriesMenuItem,
                                                                leading: icons?.CategoryCardsRoundedIcon,
                                                                title: "View market activity history",
                                                                transparent: true,
                                                            }
                                                        ],
                                                        style: themes?.menuList,
                                                        onItemClick: handleProductToolbarOptionsMenuItemClick,
                                                        isSelectable: false
                                                    }} />,
                                                }
                                            ]
                                        }} />
                                    </div>
                                </div>

                                {/* datagrid view market product inventory */}
                                <div className={"position-absolute end-15px top-50px start-0px bottom-0px "}>
                                    <DataGrid attributes={{
                                        name: marketProductDatagridName,
                                        key: "marketProductId",
                                        columns: [
                                            {
                                                default: "",
                                                style: themes?.width15px
                                            },
                                            {
                                                type: COLUMNTYPE.CHECKBOX,
                                                value: "marketProductId",
                                                style: themes?.width30px
                                            },
                                            {
                                                title: "Size/Weight",
                                                value: "productVariation",
                                                default: "",
                                                style: themes?.width100px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                type: COLUMNTYPE.IMAGETHUMBNAIL,
                                                value: "media1Url",
                                                default: "",
                                                style: themes?.width35px,
                                                image: {
                                                    id: "media1Id",
                                                    key: "media1Id",
                                                    url: "media1Url",
                                                    size: themes?.size35px,
                                                    leading: icons?.BrandingWatermarkRoundedIcon,
                                                    style: themes?.image,
                                                    config: {
                                                        downloadUrl: productImageService?.downloadUrl
                                                    },
                                                }
                                            },
                                            {
                                                default: "",
                                                style: themes?.width15px
                                            },
                                            {
                                                title: "Product",
                                                value: "productName",
                                                default: "",
                                                style: themes?.width100
                                            },
                                            {
                                                default: "",
                                                style: themes?.width5px
                                            },
                                            {
                                                type: COLUMNTYPE.TOOLBAR,
                                                items: [
                                                    {
                                                        name: rightToolbarButtons.previousButton,
                                                        type: TOOLBARITEMTYPE.BUTTON,
                                                        tooltip: "Preview",
                                                        leading: icons?.SearchRoundedIcon,
                                                        onClick: (e, args) => { handleBrowseProduct(e, args) },
                                                        observer: componentObserver,
                                                        style: themes?.toolbar?.flatIconButton
                                                    },
                                                ],
                                                style: themes?.width45px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width5px
                                            },
                                            {
                                                type: COLUMNTYPE.TEXTFIELD,
                                                title: "Price",
                                                key: "price",
                                                value: "priceAlias",
                                                default: "",
                                                textAlign: ALIGNMENTTYPE.RIGHT,
                                                style: themes?.width70px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Color",
                                                value: "productColor",
                                                default: "",
                                                style: themes?.width70px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Type",
                                                value: "productType",
                                                default: "",
                                                style: themes?.width90px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                type: COLUMNTYPE.FLAG,
                                                default: "",
                                                style: themes?.width30px,
                                                flags: flags?.marketProductAvailabilityStatusFlags,
                                                label: "title",
                                                value: "availabilityStatus"
                                            },
                                            {
                                                default: "",
                                                style: themes?.width5px
                                            }
                                        ],
                                        isStripped: true,
                                        isSelectable: true,
                                        dockType: DOCKTYPE.FILL,
                                        floatingBar: {
                                            page: {
                                                pageNumber: pageNumber,
                                                numberOfPages: totalPages,
                                                rowsPerPageList: global.variables.rowsPerPageList,
                                                onHomeClick: handleFirstPage,
                                                onReloadClick: handleReloadMarketProducts,
                                                onPageNumberChange: handlePageNumberChange,
                                                onRowsPerPageClick: handleRowsPerPageChange,
                                                onPreviousClick: handlePreviousPage,
                                                onNextClick: handleNextPage
                                            }
                                        },
                                        onRowColumnCellLostFocus: handleSelectedMarketProductUpdate,
                                        onRowDoubleClick: handleDatagridRowDoubleClick,
                                        dataset: products
                                    }} />
                                </div>
                            </div>
                        );
                    })()
                },
                {
                    widget: (() => {
                        return (
                            <div className={"position-absolute top-0px start-0px bottom-0px w-100 pt-0 pb-5 border-solid border-top-0px border-bottom-0px border-start-0px border-end-0px"}>
                                <div className={"w-100 position-absolute end-0px top-50px start-0px bottom-0px "}>

                                </div>
                            </div>
                        );
                    })()
                },
            ]
        },

    };
}

export default useMarketProductsHook;