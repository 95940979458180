import { COMPONENTYPE } from "../../enums/enums";
import { useToastr } from "../toastr/toastr.component";

const useForm = (attributes) => {

    const toastr = useToastr();

    const clear = () => {
        const fields = attributes?.ref.current.querySelectorAll("input[type=\"hidden\"],input[type=\"text\"],input[type=\"password\"],textarea");
        [...fields].forEach(el => {
            el.value = "" || el.getAttribute("aria-valuetext");
        });
    }

    const getData = (data) => {
        const fields = attributes?.ref?.current.querySelectorAll("input[type=\"hidden\"],input[type=\"text\"],input[type=\"password\"],textarea");
        [...fields].forEach(el => {
            if ((el.hasAttribute("aria-controls") && el.getAttribute("aria-controls"))) {
                if (el.getAttribute("aria-roledescription") === COMPONENTYPE[Object.keys(COMPONENTYPE).find(x => COMPONENTYPE[x] === el.getAttribute("aria-roledescription"))]) {
                    if (data)
                        data[el.id] = (el.value === "") ? (((COMPONENTYPE.DATAGRID !== el?.getAttribute("aria-roledescription"))) ? "" : []) : (typeof el?.value === "string") ? el?.value : JSON.parse(el?.value);
                } else {
                    if (data)
                        data[el.id] = el.value || el.getAttribute("data-defaultvalue");
                }
            }
        });
        return data;
    };

    const getWithListData = (data) => {
        const fields = attributes?.ref?.current.querySelectorAll("input[type=\"hidden\"],input[type=\"text\"],input[type=\"password\"],textarea");
        [...fields].forEach(el => {
            if ((el.hasAttribute("aria-controls") && el.getAttribute("aria-controls"))) {
                if (el.getAttribute("aria-roledescription") === COMPONENTYPE.DATAGRID) {
                    data[el.id] = (el.value === "") ? [] : JSON.parse(el.value);
                } else {
                    data[el.id] = el.value;
                }
            }
        });
        return data;
    };

    const setData = (data) => {
        clear();
        if (data)
            Object.keys(data).forEach(property => {
                if (document.getElementById(property)) {
                    if (document.getElementById(property).getAttribute("aria-roledescription") === COMPONENTYPE.DATAGRID) {
                        const checkboxes = attributes?.ref?.current.querySelectorAll("input[type=\"checkbox\"]");
                        checkboxes.forEach(checkbox => !checkbox.checked);

                        // 
                        const checkbox = attributes?.ref?.current.querySelector("input[id=\"" + data[property] + "\"]");
                        if (checkbox) {
                            // checkbox.checked = true;
                            checkbox.click();
                        }
                    } else {
                        if (attributes?.ref?.current) {
                            const el = attributes.ref.current.querySelector("input[id=\"" + property + "\"],textarea[id=\"" + property + "\"]");
                            switch (el?.getAttribute("type")) {
                                case "number":
                                case "hidden":
                                case "text":
                                    el.value = data[property];
                                    break;
                                default:
                                    if (attributes.ref.current[property])
                                        attributes.ref.current[property].value = data[property];
                                    break;
                            }
                        }

                    }
                }
            });
    }

    const validate = (data) => {
        const formData = getData(data);

        // validate form and continue to save
        let emptyFields = null;
        try {
            emptyFields = Object.keys(formData).filter(x => (formData[x] === "") && (document.getElementById(x) !== null) && document.getElementById(x).hasAttribute("aria-required") && JSON.parse(document.getElementById(x).getAttribute("aria-required")));
        } catch (e) { }

        if (emptyFields && emptyFields.length > 0) {
            // set focus on form field
            const field = document.getElementById(emptyFields[0]);
            if ((field.hasAttribute("aria-controls") && JSON.parse(field.getAttribute("aria-controls"))) &&
                (field.hasAttribute("aria-required") && JSON.parse(field.getAttribute("aria-required"))) &&
                field.value.length <= 0) {
                field.focus();

                // display form element indication, alert or notification message
                toastr.info(field?.getAttribute("aria-errormessage"));
                return true;
            }
        }
        return false;
    };

    return {
        clear,
        getData,
        getWithListData,
        setData,
        validate
    }
}
export { useForm };