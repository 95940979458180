import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE, GRIDPANE, MENUALIGNMENTTYPE, MENUITEMTYPE, SLIDEDIRECTION, TOOLBARITEMTYPE } from '../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../shared/components/confirm/confirm.dialog.component';
import { usePageLoader } from '../../../../shared/components/loader/page.loader.component';
import { usePrimaryUIThemes } from '../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../shared/hooks/ui.icons.hook';
import CreateOrEditCatererView from './createoredit.caterer/createoredit.caterer.view';
import { CatererService } from './caterer.service';
import { Grid } from '../../../../shared/components/grid/grid.component';
import { CalloutWindow } from '../../../../shared/components/callout/callout.window.component';
import CatererProductsView from './caterer.product/caterer.products.view';
import { useInfiniteLoader } from '../../../../shared/components/loader/infinite.loader.component';

const useCaterersHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [caterers, setCaterers] = useState([]);
    const [selectedCaterer, setSelectedCaterer] = useState({});
    const [catererQueryString, setCatererQueryString] = useState("");
    const [catererPageNumber, setCatererPageNumber] = useState(1);
    const [catererRowsPerPage, setCatererRowsPerPage] = useState(25);
    const [catererTotalPages, setCatererTotalPages] = useState(0);
    const [catererTotalRows, setCatererTotalRows] = useState(0);

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No caterer selected!",
            message: "Please select an caterer from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New caterer",
            successMessage: {
                title: "Caterer created successfully!",
                message: "Caterer was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit caterer details",
            alertMessage: {
                title: "Multiple caterers selected!",
                message: "Oops!, you selected multiple caterers. Please select a caterer from the list to continue"
            },
            successMessage: {
                title: "Caterer updated successfully!",
                message: "Caterer was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit caterer details",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these caterers?" : "this caterer?")
                }
            },
            successMessage: {
                title: "Caterer deleted successfully!",
                message: "Caterer was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these caterers?" : "this caterer?")
                }
            },
            successMessage: {
                title: "Caterer published successfully!",
                message: "Caterer was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these caterers?" : "this caterer?")
                }
            },
            successMessage: {
                title: "Caterer published successfully!",
                message: "Caterer was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these caterers?" : "this caterer?")
                }
            },
            successMessage: {
                title: "Caterer archived successfully!",
                message: "Caterer was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these caterers?" : "this caterer?")
                }
            },
            successMessage: {
                title: "Caterer restored successfully!",
                message: "Caterer was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available caterers, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();
    const createOrEditCatererObserver = useRef();
    const catererDetailsViewObserver = useRef();
    const catererDatagridObserver = useRef([]);

    // components
    const datagridName = "product-caterers-datagrid";
    const datagrid = useDataGrid({
        name: datagridName
    });

    const leftPaneToolbarName = "product-caterers-left-pane-toolbar";
    const leftToolbarButtons = {
        addCatererButtonName: "add-caterer-button",
        editCatererButtonName: "edit-caterer-button",
        deleteCatererButtonName: "delete-caterer-button",
        reloadCatererButtonName: "reload-caterer-button",
    }

    const rightPaneToolbarName = "product-caterers-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // services
    const catererService = CatererService();

    // dialogs & callouts
    const createOrEditCatererDialogName = "createoredit-caterer-modal";
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();
    const infiniteLoader = useInfiniteLoader();

    // services
    const getCaterers = useCallback(async (request) => {
        infiniteLoader.show();
        datagrid?.showLoader(messages?.loader?.fetchingMessage);

        await catererService.getAll(request)
            .then(response => {
                datagrid?.hideLoader();
                infiniteLoader.hide();

                // populate datagrid 
                setCaterers(response?.data?.list);
                catererDatagridObserver.current?.selectDefault();

                // set page
                setCatererPageNumber(response?.data?.page?.pageNumber);
                setCatererRowsPerPage(response?.data?.page?.rowsPerPage);
                setCatererTotalPages(response?.data?.page?.totalPages);
                setCatererTotalRows(response?.data?.page?.totalRows);

                // 
                rightPaneToolbar.button({
                    name: rightToolbarButtons.pagesLabelName
                }).setText("Page " + response?.data?.page?.pageNumber + " of " + response?.data?.page?.totalPages);

                // // 
                // catererDatagridObserver.current.selectDefault();

            })
            .catch(error => {
                datagrid?.hideLoader();
                infiniteLoader.hide();
            });

    }, []);

    useEffect(() => {
        (async () => {
            await getCaterers({
                queryString: catererQueryString,
                pageNumber: catererPageNumber,
                rowsPerPage: catererRowsPerPage
            });
        })();
    }, []);

    // caterer
    const handleCreate = (e) => {
        createOrEditCatererObserver.current.show({
            title: messages?.handleCreate?.dialogTitle,
            data: {},
            isNew: true
        });
    }

    const handleUpload = (e) => {
        // createOrEditCatererObserver.current.show({
        //     title: messages?.handleCreate?.dialogTitle,
        //     data: {},
        //     isNew: true
        // });
    }

    const handleEdit = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show(messages?.handleEdit?.alertMessage);
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedCaterer = caterers.find(caterer => caterer.catererId === datagrid.getSelectedRowKeyValue());
                setSelectedCaterer(selectedCaterer);

                // listen to dialog for changes
                createOrEditCatererObserver.current.show({
                    title: messages?.handleEdit?.dialogTitle,
                    data: selectedCaterer,
                    isNew: false,
                    callback: () => {
                        getCaterers({
                            queryString: catererQueryString,
                            pageNumber: catererPageNumber,
                            rowsPerPage: catererRowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show(messages?.noRecordSelectedErrorMessage);
            }
        }
    }

    const handleDelete = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: messages?.handleDelete?.confirmMessage()?.title,
                message: messages?.handleDelete?.confirmMessage(datagrid.selectedRowsCount())?.message,
                onConfirmed: (e) => {
                    catererService.remove(datagrid.getSelectedRowsKeyValue()).then(async (response) => {
                        await getCaterers({
                            queryString: catererQueryString,
                            pageNumber: catererPageNumber,
                            rowsPerPage: catererRowsPerPage
                        });
                    });
                }
            });
        } else {
            alertDialog.show(messages?.noRecordSelectedErrorMessage);
        }
    }

    const handleReload = (e) => {
        getCaterers({
            queryString: catererQueryString,
            pageNumber: catererPageNumber,
            rowsPerPage: catererRowsPerPage
        });
    }

    const handlePublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: messages?.handlePublish?.confirmMessage()?.title,
                message: messages?.handlePublish?.confirmMessage(datagrid.selectedRowsCount())?.message,
                onConfirmed: (e) => {
                    infiniteLoader.show();
                    catererService.publish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getCaterers({
                            queryString: catererQueryString,
                            pageNumber: catererPageNumber,
                            rowsPerPage: catererRowsPerPage
                        });
                        infiniteLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show(messages?.noRecordSelectedErrorMessage);
        }
    }

    const handleUnpublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: messages?.handleUnpublish?.confirmMessage()?.title,
                message: messages?.handleUnpublish?.confirmMessage(datagrid.selectedRowsCount())?.message,
                onConfirmed: (e) => {
                    infiniteLoader.show();
                    catererService.unpublish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getCaterers({
                            queryString: catererQueryString,
                            pageNumber: catererPageNumber,
                            rowsPerPage: catererRowsPerPage
                        });
                        infiniteLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show(messages?.noRecordSelectedErrorMessage);
        }
    }

    const handleSearchRecords = (e, args) => {
        getCaterers({
            queryString: args?.value,
            pageNumber: catererPageNumber,
            rowsPerPage: catererRowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (catererPageNumber > 1)
            getCaterers({
                queryString: catererQueryString,
                pageNumber: catererPageNumber - 1,
                rowsPerPage: catererRowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (catererPageNumber < catererTotalPages)
            getCaterers({
                queryString: catererQueryString,
                pageNumber: catererPageNumber + 1,
                rowsPerPage: catererRowsPerPage
            });
    }

    const handleDatagridRowSelected = (args) => {
        catererDetailsViewObserver.current({
            data: args.selectedRow
        });
    }

    return {
        toolbar: (() => {
            return (
                <div className={themes?.toolbarCollectionWrapper}>
                    <div className={"w-50"}>
                        <Toolbar attributes={{
                            name: rightPaneToolbarName,
                            items: [
                                {
                                    name: rightToolbarButtons.filterButtonName,
                                    type: TOOLBARITEMTYPE.SEARCHFIELD,
                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                    leading: icons?.SearchRoundedIcon,
                                    placeholder: "Search",
                                    trailing: icons?.ArrowForwardRoundedIcon,
                                    clear: icons?.ArrowForwardRoundedIcon,
                                    observer: componentObserver,
                                    style: themes?.toolbar?.searchField292px,
                                    onClick: (e, args) => { handleSearchRecords(e, args) },
                                    filter: {
                                        placeholder: "Filter",
                                        leading: icons?.FilterAltRoundedIcon,
                                        onClick: (e, args) => { handleProductFilterRecords(e, args) },
                                    }
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: rightToolbarButtons.previousButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Previous",
                                    leading: icons?.ArrowBackIosRoundedIcon,
                                    onClick: (e, args) => { handlePreviousPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.startDropDownButton
                                },
                                {
                                    name: rightToolbarButtons.nextButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Next",
                                    leading: icons?.ArrowForwardIosRoundedIcon,
                                    onClick: (e, args) => { handleNextPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.endDropDownButton
                                },
                            ]
                        }} />
                    </div>
                    <div className={"w-50 d-flex justify-content-end"}>
                        <Toolbar attributes={{
                            name: leftPaneToolbarName,
                            items: [
                                {
                                    name: leftToolbarButtons.addCatererButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "Add Caterer",

                                    leading: icons?.AddRoundedIcon,
                                    onClick: (e) => handleCreate(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.editCatererButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Edit",
                                    leading: icons?.EditRoundedIcon,
                                    onClick: (e) => handleEdit(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.deleteCatererButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.DeleteRoundedIcon,
                                    onClick: (e) => handleDelete(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: leftToolbarButtons.reloadMarketButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.ReplayRoundedIcon,
                                    onClick: (e) => handleReload(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.ThumbUpAltIcon,
                                    onClick: (e) => handlePublish(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "Unpublish",
                                    leading: icons?.ThumbDownAltIcon,
                                    onClick: (e) => handleUnpublish(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                }
                            ]
                        }} />
                    </div>
                </div>
            )
        })(),

        // datagrid,
        gridViewAttributes: {
            dockType: DOCKTYPE.FILL,
            fixedPane: GRIDPANE?.LEFT,
            paneWidth: themes?.width380px,
            allowAutoScroll: true,
            pane: {
                left: (
                    <div className='position-absolute top-0px start-15px bottom-15px end-10px overflow-hidden'>
                        <div className='position-absolute top-0px start-0px bottom-0px end--10px'>
                            <DataGrid attributes={{
                                name: datagridName,
                                key: ["catererId"],
                                observer: catererDatagridObserver,
                                columns: [
                                    {
                                        default: "",
                                        style: themes?.width15px
                                    },
                                    {
                                        type: COLUMNTYPE.CHECKBOX,
                                        value: "catererId",
                                        style: themes?.width30px
                                    },
                                    {
                                        type: COLUMNTYPE.IMAGETHUMBNAIL,
                                        value: "mediaUrl",
                                        default: "",
                                        style: themes?.width35px,
                                        image: {
                                            id: "mediaId",
                                            key: "mediaId",
                                            url: "mediaUrl",
                                            size: themes?.size35px,
                                            leading: icons?.StorefrontRoundedIcon,
                                            style: themes?.image,
                                            config: {
                                                downloadUrl: catererService?.downloadUrl
                                            }
                                        }
                                    },
                                    {
                                        default: "",
                                        style: themes?.width15px
                                    },
                                    {
                                        title: "Name",
                                        value: "businessName",
                                        default: "",
                                        style: themes?.width100
                                    },
                                    {
                                        default: "",
                                        style: themes?.width5px
                                    },
                                    {
                                        type: COLUMNTYPE.TOOLBAR,
                                        items: [
                                            {
                                                name: rightToolbarButtons.previousButton,
                                                type: TOOLBARITEMTYPE.BUTTON,
                                                tooltip: "Preview",
                                                leading: icons?.SearchRoundedIcon,
                                                onClick: (e, args) => { handleEdit(e, args) },
                                                observer: componentObserver,
                                                style: themes?.toolbar?.flatIconButton
                                            },
                                        ],
                                        style: themes?.width45px
                                    }
                                ],
                                rowHeight: 35,
                                isStripped: true,
                                isSelectable: true,
                                dockType: DOCKTYPE.FILL,
                                onRowSelected: handleDatagridRowSelected,
                                dataset: caterers
                            }} />
                        </div>
                    </div>
                ),

                right: (
                    <div className='position-absolute top-3px start-15px bottom-1px end-0px'>
                        <CatererProductsView attributes={{
                            observer: catererDetailsViewObserver
                        }} />
                    </div>
                )
            }
        },

        calloutWindowAttributes: {
            title: messages?.handleCreate?.dialogTitle,
            name: createOrEditCatererDialogName,
            data: selectedCaterer,
            observer: createOrEditCatererObserver,
            widget: CreateOrEditCatererView,
            slideDirection: SLIDEDIRECTION.RIGHT,
            style: {
                window: "max-width-430px"
            },
            buttons: [
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            infiniteLoader.hide();
                            getCaterers({
                                queryString: catererQueryString,
                                pageNumber: catererPageNumber,
                                rowsPerPage: catererRowsPerPage
                            });

                            setSelectedCaterer({});
                            createOrEditCatererObserver.current.close();
                        });
                    }
                },
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                }
            ]
        }

    };
}

export default useCaterersHook;