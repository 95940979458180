import { useHttps } from '../../../../../shared/utilities';


const BankService = () => {
    const request = useHttps();
    const controller = "/banks";
    
    return { 
        getAll: async (data) => await request.post(controller + "/get-all", data), 
        get: async (id) => await request.post(controller + "/get-all", id), 
        getByBankNumber: async (number) => await request.post(controller + "/get-by-wallet-number", number),
        search: async (data) => await request.post(controller + "/get-all", data),
        save: async (data) => await request.post(controller + "/save", data), 
        update: async (data) => await request.post(controller + "/update", data),
        remove: async (data) => await request.post(controller + "/delete", data),
        publish: async (data) => await request.post(controller + "/publish", data),
        unpublish: async (data) => await request.post(controller + "/unpublish", data),
        sendToArchive: async (data) => await request.post(controller + "/archive", data),
        restoreArchive: async (data) => await request.post(controller + "/restore-archive", data),
        getBankTransactions: async (data) => await request.post(controller + "/get-wallet-recent-transactions", data),
        getBankRecentTransactions: async (data) => await request.post(controller + "/get-wallet-recent-transactions", data),
        fundBank: async (data) => await request.post(controller + "/fund-wallet", data),
        transferToBank: async (data) => await request.post(controller + "/transfer-to-wallet", data)
    }
}

export { BankService }