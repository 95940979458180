import React, { useRef } from "react";

const useInfiniteLoader = () => {

    const show = async (attributes) => {
        const loader = document.body.querySelector("*[aria-owns=\"infinite-loader\"]");
        loader.classList.remove("display-none");
    }

    const hide = async () => {
        const loader = document.body.querySelector("*[aria-owns=\"infinite-loader\"]");
        loader.classList.add("display-none");
    }

    return { show, hide }
}

const InfiniteLoader = ({ attributes }) => {

    const pageLoaderObserver = useRef();

    return (
        <div ref={pageLoaderObserver} aria-owns={"infinite-loader"} className={"loader " + attributes?.style?.loader + " " + attributes?.style?.loaderBefore} aria-hidden="true">
            <div className={attributes?.style?.loaderBar}></div>
        </div>
    )
}
export { InfiniteLoader, useInfiniteLoader }