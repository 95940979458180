import { useHttps } from '../../../../../shared/utilities';


const PharmacyProductCategoryService = () => {
    const request = useHttps();
    const controller = "/pharmacy-product-categories";

    return { 
        getAll: async (data) => await request.post(controller + "/get-all", data), 
        getAllByPharmacy: async (data) => await request.post(controller + "/get-by-pharmacy", data),
        get: async (id) => await request.post(controller + "/get-all", id), 
        search: async (data) => await request.post(controller + "/get-all", data),
        save: async (data) => await request.post(controller + "/save", data), 
        saveSelectedPharmacyProductCategory: async (data) => await request.post(controller + "/save-by-pharmacy", data), 
        saveSelectedPharmacyBulkProductCategory: async (data) => await request.post(controller + "/save-bulk-by-pharmacy", data), 
        update: async (data) => await request.post(controller + "/update", data),
        updateSelectedPharmacyProductCategory: async (data) => await request.post(controller + "/update-by-pharmacy", data), 
        remove: async (data) => await request.post(controller + "/delete", data),
        removeByPharmacy: async (data) => await request.post(controller + "/delete-by-pharmacy", data),
    }
}

export { PharmacyProductCategoryService }