import React from 'react';
import useCreateOrEditMarketProductHook from './createoredit.market.product.hook';
import { MultiTextField, TextField } from '../../../../../shared/components/field';
import { ImageUploader } from '../../../../../shared/components/image/image.uploader.component';
import { SingleSelectDropDownField } from '../../../../../shared/components/field/single.select.dropdown.field.component';
import { MultiSelectDropDownField } from '../../../../../shared/components/field/multi.select.dropdown.field.component';

const CreateOrEditMarketProductView = ({ attributes }) => {

    const hook = useCreateOrEditMarketProductHook(attributes);

    return (
        <div className={"w-100 position-relative p-3 pt-1 pb-5"}>
            <div className={"w-100 position-relative pb-0"}>
                <form ref={hook?.reactiveForm}
                    method={attributes?.method}
                    autoComplete={"false"}
                    className={"w-100 text-left pt-2"} noValidate>
                    <div className={"w-100 flex-column"}>

                        {/* product images */}
                        <div className={"position-relative w-100 min-height-130px mb-5 mt-0 p-0 d-flex justify-content-between align-items-center"}>
                            <div className={"w-100 max-width-200px min-height-200px mb-2 mt-0 me-2 p-0 position-relative"}>
                                <ImageUploader attributes={hook?.frontImageUploaderAttributes} />
                            </div>
                            <div className={"w-100 max-width-200px min-height-200px mb-2 mt-0 p-0 position-relative"}>
                                <ImageUploader attributes={hook?.backImageUploaderAttributes} />
                            </div>
                        </div>

                        {/* product name field */}
                        <div className={"w-100 mb-4"}>
                            <div className={"mb-2 " + hook?.themes?.label?.text13px}>Product name:</div>
                            <TextField
                                attributes={hook?.productNameFieldAttributes} />
                        </div>

                        <div className={"w-100 mb-4 d-flex justify-content-between align-items-center"}>
                            {/* category field */}
                            <div className={"w-100 me-3"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Choose a category:</div>
                                <SingleSelectDropDownField
                                    attributes={hook?.productCategoryDropDownFieldAttributes} />
                            </div>

                            {/* price field */}
                            <div className={"w-100 max-width-150px min-width-150px"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Market price:</div>
                                <TextField
                                    attributes={hook?.productPriceFieldAttributes} />
                            </div>
                        </div>

                        {/* product description field */}
                        <div className={"w-100 mb-4"}>
                            <div className={"mb-2 " + hook?.themes?.label?.text13px}>Description:</div>
                            <MultiTextField
                                attributes={hook?.productDescriptionFieldAttributes} />
                        </div>

                        {/* product description field */}
                        <div className={"w-100 mb-4"}>
                            <div className={"mb-2 " + hook?.themes?.label?.text13px}>Benefits/Composition:</div>
                            <MultiTextField
                                attributes={hook?.productCompositionBenefitsFieldAttributes} />
                        </div>

                        {/* brand & variation field */}
                        <div className={"w-100 mb-4 d-flex"}>
                            <div className={"w-100 me-3"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Select a brand:</div>
                                <MultiSelectDropDownField
                                    attributes={hook?.productBrandDropDownFieldAttributes} />
                            </div>

                            <div className={"w-100"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Select a variation:</div>
                                <MultiSelectDropDownField
                                    attributes={hook?.productVariationDropDownFieldAttributes} />
                            </div>
                        </div>

                        {/* color & type field */}
                        <div className={"w-100 mb-4 d-flex"}>
                            <div className={"w-100 me-3"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Select a color:</div>
                                <MultiSelectDropDownField
                                    attributes={hook?.productColorDropDownFieldAttributes} />
                            </div>

                            <div className={"w-100"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Select a type:</div>
                                <MultiSelectDropDownField
                                    attributes={hook?.productTypeDropDownFieldAttributes} />
                            </div>
                        </div>

                        {/* seo */}
                        <div className={"w-100 mb-4 d-flex"}>
                            <div className={"w-100"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Set product availability flag:</div>
                                {/* <MultiTextField
                                    attributes={hook?.productTagsFieldAttributes} /> */}
                            </div>

                        </div>

                    </div>
                </form>
            </div >
        </div >
    );
}

export default CreateOrEditMarketProductView;