import { useCallback, useEffect, useRef, useState } from 'react';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { PharmacyService } from '../pharmacy.service';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useForm } from '../../../../../shared/components/form/form.component';
import { CountryService } from '../../../configuration.settings/settings/location/country/country.service';
import { StateService } from '../../../configuration.settings/settings/location/state/state.service';
import { CityService } from '../../../configuration.settings/settings/location/city/city.service';
import useAppConfig from '../../../../../shared/hooks/app.config.hook';
import { ResourceService } from '../../../configuration.settings/settings/resource/resource.service';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';

const useCreateOrEditPharmacyHook = (attributes) => {

    // config
    const appConfig = useAppConfig();

    // theme
    const icons = useIconsThemes()?.rounded;
    const themes = usePrimaryUIThemes();


    // state objects
    const [pharmacy, setPharmacy] = useState({
        pharmacyName: '',
    });

    const countries = useRef([]);
    const states = useRef([]);
    const cities = useRef([]);
    const gender = useRef([]);

    // refs
    const pharmacyObserver = useRef(null);
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);
    const pharmacyLogoObserver = useRef(null);
    const pharmacyBannerObserver = useRef(null);
    const profilePhotoObserver = useRef(null);
    const countryObserver = useRef(null);
    const stateObserver = useRef(null);
    const cityObserver = useRef(null);
    const genderObserver = useRef(null);
    const dobObserver = useRef(null);

    // components
    const form = useForm({
        ref: reactiveForm
    });
    const pageLoader = usePageLoader();

    const toggleToolbarName = "toggle-toolbar";
    const toggleToolbarButtons = {
        profileButtonName: "profile-button",
        bankDetailsButtonName: "bank-details-button",
        guarantorButtonName: "guarantor-details-button"
    }

    // callbacks
    const widgetReactiveFormModule = useCallback(async (args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        setPharmacy(args?.data);
        pharmacyObserver.current = args?.data;

        // load logo image
        pharmacyBannerObserver.current.loadImage(pharmacyObserver.current);
        pharmacyLogoObserver.current.loadImage(pharmacyObserver.current);
        // profilePhotoObserver.current.loadImage(pharmacyObserver.current);

        // 
        dobObserver.current.setValue(pharmacyObserver.current);
        genderObserver.current.selectItem(pharmacyObserver.current);
        
        // get countries
        await getCountries({
            queryString: "",
            pageNumber: 1,
            rowsPerPage: 1000
        });

        if (args?.data !== null) {
            form.setData(pharmacyObserver.current);
        } else {
            form.clear();
        }
    }, [form])


    // services
    const pharmacyService = PharmacyService();
    const countryService = CountryService();
    const stateService = StateService();
    const cityService = CityService();
    const resourceService = ResourceService();

    const getGender = useCallback(async () => {
        await resourceService.getGender()
            .then(response => {
                gender.current = response?.data?.list;
                genderObserver.current.updateList(gender.current);
                genderObserver.current.selectItem();
            })
            .catch(error => { });
    }, [])

    const getCountries = useCallback(async (request) => {
        await countryService.getAll(request)
            .then(response => {
                countries.current = response?.data?.list;
                countryObserver.current.updateList(countries.current);
                countryObserver.current.selectItem();
            })
            .catch(error => {
                //
            });
    }, []);

    const getStates = useCallback(async (request) => {
        await stateService.getAll(request)
            .then(response => {
                states.current = response?.data?.list;
                stateObserver.current.updateList(states.current);
                stateObserver.current.selectItem();
            })
            .catch(error => {
                //
            });

    }, []);

    const getCities = useCallback(async (request) => {
        await cityService.getAll(request)
            .then(response => {
                cities.current = response?.data?.list;
                cityObserver.current.updateList(cities.current);
                cityObserver.current.selectItem();
            })
            .catch(error => {
                //
            });

    }, []);

    useEffect(() => {
        (async () => {

            // get gender
            await getGender();

            // get countries
            await getCountries({
                queryString: "",
                pageNumber: 1,
                rowsPerPage: 1000
            });

            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;

        })();
    }, []);

    const handleDOBFieldChange = (e) => {
        //
    }

    const handleCountryChange = (e) => {
        getStates({
            countryId: e.value,
            queryString: "",
            pageNumber: 1,
            rowsPerPage: 50
        });
    }

    const handleStateChange = (e) => {
        getCities({
            stateId: e.value,
            queryString: "",
            pageNumber: 1,
            rowsPerPage: 50
        });
    }

    const handleSaveOrUpdate = async (args) => {
        args?.close();
        pageLoader.show({});
        if (args?.isNew) {
            await pharmacyService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
                pageLoader.close();
            }).catch((error) => {
                args?.error(error);
                pageLoader.close();
            });
        } else {
            await pharmacyService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
                pageLoader.close();
            }).catch((error) => {
                args?.error(error);
                pageLoader.close();
            });
        }
    }

    const handlePharmacyLogoUpload = (args) => {
        if (Object.keys(args).length > 0) {
            pharmacyObserver.current = args?.data;
        }
    }

    const handlePharmacyBannerUpload = (args) => {
        if (Object.keys(args).length > 0) {
            pharmacyObserver.current = args?.data;
        }
    }

    const handlePharmacyProfileUpload = (args) => {
        if (Object.keys(args).length > 0) {
            pharmacyObserver.current = args?.data;
        }
    }

    const handleToggleCreateOrEditForm = () => {
        console.log("Hello Code");
    }

    return {
        pharmacy: pharmacy,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,


        // toggle toolbar
        toggleToolbarAttributes: {
            name: toggleToolbarName,
            items: [
                {
                    name: toggleToolbarButtons.profileButtonName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    text: "About",
                    leading: icons?.PersonIcon,
                    onClick: (e) => handleToggleCreateOrEditForm(e),
                    observer: componentObserver,
                    style: themes?.toolbar?.button
                },
                {
                    name: toggleToolbarButtons.bankDetailsButtonName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    leading: icons?.AccountBalanceRoundedIcon,
                    onClick: (e) => handleToggleCreateOrEditForm(e),
                    observer: componentObserver,
                    style: themes?.toolbar?.button
                },
                // {
                //     name: toggleToolbarButtons.guarantorButtonName,
                //     type: TOOLBARITEMTYPE.BUTTON,
                //     leading: icons?.PersonIcon,
                //     onClick: (e) => handleToggleCreateOrEditForm(e),
                //     observer: componentObserver,
                //     style: themes?.toolbar?.button
                // }
            ]
        },

        // media
        pharmacyBannerUploaderAttributes: {
            formElement: true,
            validate: true,
            id: "mediaId",
            key: "mediaId",
            url: "mediaUrl",
            caption: "*File supported .png, .jpg, .webp",
            size: themes?.sizeAbsolute,
            observer: pharmacyBannerObserver,
            leading: icons?.BrandingWatermarkRoundedIcon,
            style: themes?.image,
            model: pharmacyObserver.current,
            isImageAvailable: pharmacyObserver.current ? true : false,
            onUploadCompleted: handlePharmacyBannerUpload,
            config: {
                key: "mediaId",
                url: "mediaUrl",
                imageSize: 500,
                ownerId: appConfig?.sessionAuthDetails?.userId,
                uploadUrl: pharmacyService?.uploadUrl,
                downloadUrl: pharmacyService?.downloadUrl
            }
        },

        // pharmacy logo media
        pharmacyLogoUploaderAttributes: {
            formElement: true,
            validate: true,
            id: "mediaId",
            key: "mediaId",
            url: "mediaUrl",
            caption: "*.png, .jpg",
            size: themes?.sizeAbsolute,
            observer: pharmacyLogoObserver,
            leading: icons?.BrandingWatermarkRoundedIcon,
            style: themes?.image,
            model: pharmacyObserver.current,
            isImageAvailable: pharmacyObserver.current ? true : false,
            onUploadCompleted: handlePharmacyLogoUpload,
            config: {
                ownerId: appConfig?.sessionAuthDetails?.userId,
                uploadUrl: pharmacyService?.uploadUrl,
                downloadUrl: pharmacyService?.downloadUrl
            }
        },

        //pharmacy name
        pharmacyNameLabel: {
            text: "Business name:",
            style: themes?.label?.text13px
        },
        pharmacyNameField: {
            formElement: true,
            validate: true,
            placeholder: "Type business name here",
            id: "businessName",
            value: pharmacy?.businessName,
            style: themes?.field?.textField,
            observer: componentObserver,
        },

        // description
        pharmacyDescriptionLabel: {
            text: "Description:",
            style: themes?.label?.text13px
        },
        pharmacyDescriptionField: {
            formElement: true,
            validate: false,
            placeholder: "Write something about this pharmacy",
            id: "description",
            isMultiline: true,
            value: pharmacy?.description,
            style: themes?.field?.multiTextField200Hpx,
            observer: componentObserver,
        },

        // first name
        firstNameFieldAttributes: {
            formElement: true,
            validate: false,
            placeholder: "First name",
            id: "firstName",
            value: pharmacy?.firstName,
            style: themes?.field?.textField,
            observer: componentObserver,
        },

        // last name
        lastNameFieldAttributes: {
            formElement: true,
            validate: false,
            placeholder: "Last name",
            id: "lastName",
            value: pharmacy?.lastName,
            style: themes?.field?.textField,
            observer: componentObserver,
        },

        // address
        contactAddressLabel: {
            text: "Contact address:",
            style: themes?.label?.text13px
        },
        contactAddressField: {
            formElement: true,
            validate: true,
            placeholder: "Type the address of the pharmacy here",
            id: "contactAddress",
            value: pharmacy?.contactAddress,
            style: themes?.field?.textField,
            observer: componentObserver,
        },

        // country
        countrySelectDropDownFieldAttributes: {
            name: "country-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Country",
            onClick: (e, args) => { },
            observer: countryObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "countryId",
            title: "countryName",
            value: "countryId",
            items: countries.current,
            model: pharmacy,
            onChange: handleCountryChange
        },

        // state
        stateSelectDropDownFieldAttributes: {
            name: "state-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "State",
            onClick: (e, args) => { },
            observer: stateObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "stateId",
            title: "stateName",
            value: "stateId",
            items: states.current,
            model: pharmacy,
            onChange: handleStateChange
        },

        // city
        citySelectDropDownFieldAttributes: {
            name: "city-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "City",
            onClick: (e, args) => { },
            observer: cityObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "cityId",
            title: "cityName",
            value: "cityId",
            items: cities.current,
            model: pharmacy
        },

        // postal code
        postalCodeField: {
            formElement: true,
            validate: false,
            placeholder: "Postal code",
            id: "postalCode",
            value: pharmacy?.pharmacyPostalCode,
            style: themes?.field?.textField,
            observer: componentObserver,
        },

        // gender
        genderSelectDropDownFieldAttributes: {
            name: "gender-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select",
            onClick: (e, args) => { },
            observer: genderObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "gender",
            title: "title",
            value: "value",
            items: gender.current,
            model: pharmacy
        },

        // dob
        dobDropDownFieldAttributes: {
            observer: dobObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "dob",
            title: "title",
            value: "value",
            model: pharmacy,
            day: {
                name: "dob-day-selectable-dropdownlist-field"
            },
            month: {
                name: "dob-month-selectable-dropdownlist-field"
            },
            year: {
                name: "dob-year-selectable-dropdownlist-field"
            },
            onChange: handleDOBFieldChange
        },

        // email address
        emailAddressFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "e.g. anything@domain.com",
            id: "emailAddress",
            value: pharmacy?.emailAddress,
            leading: icons?.MailIcon,
            style: themes?.field?.textField,
            observer: componentObserver,
        },

        // phone number
        phoneNumberFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "XXXX-XXXX-XXXX",
            id: "phoneNumber",
            value: pharmacy?.phoneNumber,
            leading: icons?.LocalPhoneIcon,
            style: themes?.field?.textField,
            observer: componentObserver,
        },

        // national id
        nationalIdLabel: {
            text: "National identification number (NIN):",
            style: themes?.label?.text13px
        },
        nationalIdField: {
            formElement: true,
            validate: false,
            placeholder: "Type your NIN here",
            id: "nationalId",
            value: pharmacy?.description,
            style: themes?.field?.textField,
            observer: componentObserver,
        },

        themes: themes,
    }
}

export default useCreateOrEditPharmacyHook;