import {  useHttps } from "../../shared/utilities";

const useAuthService = () => {
    const request = useHttps();

    const authenticate = async (data) => {
        return await request.post("/auth/verify", data)
            .then(response => response.data)
            .catch(error => error);
    }

    return {
        authenticate
    }
}
export { useAuthService }