import { useCallback, useEffect, useRef, useState } from 'react';
import { useToastr } from '../../../../shared/components/toastr/toastr.component';
import { DataGrid, useDataGrid } from '../../../../shared/components/datagrid';
import { useAlertDialog } from '../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../shared/components/loader/page.loader.component';
import { Toolbar } from '../../../../shared/components/toolbar/toolbar.component';
import {
    COLUMNTYPE,
    DOCKTYPE,
    GRIDPANE,
    MENUALIGNMENTTYPE,
    SLIDEDIRECTION,
    TOOLBARITEMTYPE,
} from '../../../../shared/enums/enums';
import { useIconsThemes } from '../../../../shared/hooks/ui.icons.hook';
import { usePrimaryUIThemes } from '../../../../shared/hooks/ui.themes';
import CreateOrEditProductView from './createoredit.product/createoredit.product.view';
import { ProductService } from './product.service';
import useFlags from '../../../../shared/hooks/flags.hook';
import CreateOrUploadProductSheetView from './create.bulk.product.sheet/create.bulk.product.sheet.view';
import global from '../../../../shared/helpers/global.variable.helper';
import ProductFilterView from './product.filter/product.filter.view';
import { useInfiniteLoader } from '../../../../shared/components/loader/infinite.loader.component';

const useProductsHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // flags
    const flags = useFlags();

    // state objects
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [productQueryString, setProductQueryString] = useState("");
    const [productPageNumber, setProductPageNumber] = useState(1);
    const [productRowsPerPage, setProductRowsPerPage] = useState((global.variables.rowsPerPageList.find(x => x.default === true).rowValue));
    const [productTotalPages, setProductTotalPages] = useState(0);
    const [productTotalRows, setProductTotalRows] = useState(0);

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No product selected!",
            message: "Please select an product from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New product",
            successMessage: {
                title: "Product created successfully!",
                message: "Product was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit product details",
            alertMessage: {
                title: "Multiple products selected!",
                message: "Oops!, you selected multiple products. Please select a product from the list to continue"
            },
            successMessage: {
                title: "Product updated successfully!",
                message: "Product was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit product details",
            confirmMessage: (count) => {
                return {
                    title: (productDatagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these products?" : "this product?")
                }
            },
            successMessage: {
                title: "Product deleted successfully!",
                message: "Product was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (productDatagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these products?" : "this product?")
                }
            },
            successMessage: {
                title: "Product published successfully!",
                message: "Product was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (productDatagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these products?" : "this product?")
                }
            },
            successMessage: {
                title: "Product published successfully!",
                message: "Product was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (productDatagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these products?" : "this product?")
                }
            },
            successMessage: {
                title: "Product archived successfully!",
                message: "Product was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (productDatagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these products?" : "this product?")
                }
            },
            successMessage: {
                title: "Product restored successfully!",
                message: "Product was successfully restored."
            }
        },

        handleFilter: {
            dialogTitle: "Filter",
            successMessage: {
                title: "Product filtered",
                message: "Products were successfully filtered"
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available products, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();
    const createOrEditProductObserver = useRef();
    const createOrUploadProductSheetDialogObserver = useRef();
    const productFilterObserver = useRef();


    // components
    const productDatagridName = "product-markets-datagrid";
    const productDatagrid = useDataGrid({
        name: productDatagridName
    });

    const leftPaneToolbarName = "products-left-pane-toolbar";
    const leftToolbarButtons = {
        addProductButton: "add-product-button",
        editProductButton: "edit-product-button",
        deleteProductButton: "delete-product-button",
        reloadProductButton: "reload-product-button",
    }

    const rightPaneToolbarName = "product-products-right-pane-toolbar";
    const rightToolbarButtons = {
        filterButton: "filter-button",
        previousButton: "previous-button",
        pagesButton: "pages-label",
        nextButton: "next-button",
    }

    const createOrEditProductDialogName = "createoredit-product-modal";

    const createOrUploadProductSheetDialogName = "createorupload-product-modal";
    const createOrUploadProductSheetDialog = useModalDialog({
        name: createOrUploadProductSheetDialogName
    });

    const productFilterDialogName = "product-filter-modal";

    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // toastr
    const toastr = useToastr();

    // loader
    const pageLoader = usePageLoader();
    const infiniteLoader = useInfiniteLoader();

    // services
    const productService = ProductService();


    const getProducts = useCallback(async (request) => {
        infiniteLoader.show();
        productDatagrid.clearSelection();

        await productService.getAll(request)
            .then(response => {
                infiniteLoader?.hide();
                productDatagrid?.hideLoader();

                // populate datagrid 
                setProducts(response?.data?.list);

                // set page
                setProductPageNumber(response?.data?.page?.pageNumber);
                setProductRowsPerPage(response?.data?.page?.rowsPerPage);
                setProductTotalPages(response?.data?.page?.totalPages);
                setProductTotalRows(response?.data?.page?.totalRows);
            })
            .catch(error => {
                infiniteLoader.hide();
                productDatagrid?.hideLoader();
            });
    }, [infiniteLoader, productDatagrid, productService]);

    useEffect(() => {
        getProducts({
            queryString: productQueryString,
            pageNumber: productPageNumber,
            rowsPerPage: productRowsPerPage
        });
    }, []);

    // event handlers
    const handleCreate = (e) => {
        // clear selection
        setSelectedProduct({});

        // show callout
        createOrEditProductObserver.current.show({
            title: messages?.handleCreate?.dialogTitle,
            data: {},
            isNew: true
        });

    }

    const handleBulkUpload = async (e) => {
        // clear selection
        setSelectedProduct({});

        // show dialog
        await createOrUploadProductSheetDialog.show({
            title: "Create Bulk Products",
            showCloseButton: true,
            isNew: true
        });

        // listen to dialog for changes
        createOrUploadProductSheetDialogObserver.current({
            data: null
        });
    }

    const handleEdit = (e) => {
        if (productDatagrid.selectedRowsCount() > 1) {
            alertDialog.show({
                title: "Multiple products selected!",
                message: "Oops!, you selected multiple products. Please select a product from the list to continue"
            });
        } else {
            if (productDatagrid.selectedRowsCount() > 0) {
                handleDatagridRowDoubleClick({
                    selectedRow: products.find(product => product.productId === productDatagrid.getSelectedRowKeyValue())
                });
            } else {
                alertDialog.show({
                    title: "No product selected!",
                    message: "Please select a product from the list to continue"
                });
            }
        }
    }

    const handleBrowse = (e) => {
        handleDatagridRowDoubleClick({
            selectedRow: products.find(product => product.productId === productDatagrid.getSelectedRowKeyValue())
        });
    }

    const handleDelete = (e) => {
        if (productDatagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (productDatagrid.selectedRowsCount() + ((productDatagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((productDatagrid.selectedRowsCount() > 1) ? "these products?" : "this product?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    productService.remove(productDatagrid.getSelectedRowsKeyValue()).then(response => {
                        getProducts({
                            queryString: productQueryString,
                            pageNumber: productPageNumber,
                            rowsPerPage: productRowsPerPage
                        });
                        pageLoader.hide();
                        toastr.success("Product successfully deleted");
                    }).catch(error => {
                        pageLoader.hide();
                        toastr.error("Oops! something went wrong.");
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No product selected!",
                message: "Please select a product from the list to continue"
            });
        }
    }

    const handleRefresh = (e) => {
        getProducts({
            queryString: productQueryString,
            pageNumber: productPageNumber,
            rowsPerPage: productRowsPerPage
        });
    }

    const handleSearchRecords = (e, args) => {
        setProductQueryString(args?.value);
        getProducts({
            queryString: args?.value,
            pageNumber: 1,
            rowsPerPage: productRowsPerPage
        });
    }

    const handleFirstPage = (e, args) => {
        if (productPageNumber > 1)
            getProducts({
                queryString: productQueryString,
                pageNumber: 1,
                rowsPerPage: productRowsPerPage
            });
    }

    const handlePreviousPage = (e, args) => {
        if (productPageNumber > 1)
            getProducts({
                queryString: productQueryString,
                pageNumber: productPageNumber - 1,
                rowsPerPage: productRowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (productPageNumber < productTotalPages)
            getProducts({
                queryString: productQueryString,
                pageNumber: productPageNumber + 1,
                rowsPerPage: productRowsPerPage
            });
    }

    const handleRowsPerPageChange = (e, args) => {
        if (productPageNumber < productTotalPages) {
            setProductRowsPerPage(args?.value);
            getProducts({
                queryString: productQueryString,
                pageNumber: productPageNumber,
                rowsPerPage: args?.value
            });
        }
    }

    const handlePageNumberChange = (e, args) => {
        setProductPageNumber(args?.value);
        getProducts({
            queryString: productQueryString,
            pageNumber: args?.value,
            rowsPerPage: productRowsPerPage
        });
    }

    const handleDatagridRowDoubleClick = (args) => {
        const selectedProduct = products.find(product => product.productId === args.selectedRow?.productId);
        setSelectedProduct(selectedProduct);

        if (selectedProduct && (!selectedProduct?.productDescription || !selectedProduct?.productCompositionBenefits)) {
            infiniteLoader.show();
            productService.generateAndUpdateProductDetails(selectedProduct)
                .then(async (response) => {
                    infiniteLoader.hide();
                    const data = response?.data;
                    if (data?.statusCode === 200 && data?.data) {

                        // update datagridview list
                        await getProducts({
                            queryString: productQueryString,
                            pageNumber: productPageNumber,
                            rowsPerPage: productRowsPerPage
                        });

                        // Open product details callout
                        createOrEditProductObserver.current.show({
                            title: messages?.handleEdit?.dialogTitle,
                            data: data?.data,
                            isNew: false,
                            callback: () => {
                                getProducts({
                                    queryString: productQueryString,
                                    pageNumber: productPageNumber,
                                    rowsPerPage: productRowsPerPage
                                });
                            }
                        });
                    } else {
                        toastr.error("Oops! something went wrong. Could not generate product description, uses, benefits and compositions.");

                        // Open product details callout
                        createOrEditProductObserver.current.show({
                            title: messages?.handleEdit?.dialogTitle,
                            data: selectedProduct,
                            isNew: false,
                            callback: () => {
                                getProducts({
                                    queryString: productQueryString,
                                    pageNumber: productPageNumber,
                                    rowsPerPage: productRowsPerPage
                                });
                            }
                        });
                    }
                }).catch(error => {
                    infiniteLoader.hide();
                    toastr.error("Oops! something went wrong. Could not generate product description, uses, benefits and compositions.");
                });
        } else {
            // 
            // Open product details callout
            createOrEditProductObserver.current.show({
                title: messages?.handleEdit?.dialogTitle,
                data: selectedProduct,
                isNew: false,
                callback: () => {
                    getProducts({
                        queryString: productQueryString,
                        pageNumber: productPageNumber,
                        rowsPerPage: productRowsPerPage
                    });
                }
            });
        }
    }

    const handlePublish = (evt) => {
        if (productDatagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (productDatagrid.selectedRowsCount() + ((productDatagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to publish " + ((productDatagrid.selectedRowsCount() > 1) ? "these products?" : "this product?"),
                onConfirmed: (e) => {
                    infiniteLoader.show();
                    productService.publish(productDatagrid.getSelectedRowsKeyValue()).then(response => {
                        getProducts({
                            queryString: productQueryString,
                            pageNumber: productPageNumber,
                            rowsPerPage: productRowsPerPage
                        });
                        infiniteLoader.hide();
                    }).catch(error => {
                        infiniteLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No product selected!",
                message: "Please select a product or more from the list to publish"
            });
        }
    }

    const handleUnpublish = (evt) => {
        if (productDatagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (productDatagrid.selectedRowsCount() + ((productDatagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to unpublish " + ((productDatagrid.selectedRowsCount() > 1) ? "these products?" : "this product?"),
                onConfirmed: (e) => {
                    infiniteLoader.show();

                    productService.unpublish(productDatagrid.getSelectedRowsKeyValue()).then(response => {
                        getProducts({
                            queryString: productQueryString,
                            pageNumber: productPageNumber,
                            rowsPerPage: productRowsPerPage
                        });
                        infiniteLoader.hide();
                    }).catch(error => {
                        infiniteLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No product selected!",
                message: "Please select a product from the list to continue"
            });
        }
    }

    const handleProductFilterRecords = () => {
        productFilterObserver.current.show({
            title: messages?.handleFilter?.dialogTitle,
            data: selectedProduct,
            isNew: false,
            callback: () => {
                getProducts({
                    queryString: productQueryString,
                    pageNumber: productPageNumber,
                    rowsPerPage: productRowsPerPage
                });
            }
        });
    }

    return {
        componentObserver,
        products,

        toolbar: (() => {
            return (
                <div className={themes?.toolbarCollectionWrapper}>
                    <div className={"w-50"}>
                        <Toolbar attributes={{
                            name: rightPaneToolbarName,
                            items: [
                                {
                                    name: rightToolbarButtons.filterButton,
                                    type: TOOLBARITEMTYPE.SEARCHFIELD,
                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                    leading: icons?.SearchRoundedIcon,
                                    placeholder: "Search",
                                    trailing: icons?.ArrowForwardRoundedIcon,
                                    clear: icons?.ArrowForwardRoundedIcon,
                                    observer: componentObserver,
                                    style: themes?.toolbar?.searchField,
                                    onClick: (e, args) => { handleSearchRecords(e, args) },
                                    filter: {
                                        placeholder: "Filter",
                                        leading: icons?.FilterAltRoundedIcon,
                                        onClick: (e, args) => { handleProductFilterRecords(e, args) },
                                    }
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: rightToolbarButtons.previousButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Previous",
                                    leading: icons?.ArrowBackIosRoundedIcon,
                                    onClick: (e, args) => { handlePreviousPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.startDropDownButton
                                },
                                {
                                    name: rightToolbarButtons.nextButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Next",
                                    leading: icons?.ArrowForwardIosRoundedIcon,
                                    onClick: (e, args) => { handleNextPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.endDropDownButtonNoLeftMargin
                                },
                            ]
                        }} />
                    </div>
                    <div className={"w-50 d-flex justify-content-end"}>
                        <Toolbar attributes={{
                            name: leftPaneToolbarName,
                            items: [
                                {
                                    name: leftToolbarButtons.addProductButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "Add",
                                    leading: icons?.AddRoundedIcon,
                                    onClick: (e) => handleCreate(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.addProductButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "Upload CSV",
                                    // leading: icons?.FileUploadRoundedIcon,
                                    onClick: (e) => handleBulkUpload(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: leftToolbarButtons.editProductButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Edit",
                                    leading: icons?.EditRoundedIcon,
                                    onClick: (e) => handleEdit(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.deleteProductButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.DeleteRoundedIcon,
                                    onClick: (e) => handleDelete(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: leftToolbarButtons.deleteProductButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.ReplayRoundedIcon,
                                    onClick: (e) => handleRefresh(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: leftToolbarButtons.reloadProductButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    // text: "Publish",
                                    leading: icons?.ThumbUpAltIcon,
                                    onClick: (e) => handlePublish(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.reloadProductButton,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "Unpublish",
                                    leading: icons?.ThumbDownAltIcon,
                                    onClick: (e) => handleUnpublish(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                // {
                                //     type: TOOLBARITEMTYPE.SPACE,
                                //     style: themes?.toolbar?.space?.space15
                                // },
                                // {
                                //     name: leftToolbarButtons.reloadProductButton,
                                //     type: TOOLBARITEMTYPE.BUTTON,
                                //     leading: icons?.DriveFolderUploadRoundedIcon,
                                //     onClick: (e) => handleArchive(e),
                                //     observer: componentObserver,
                                //     style: themes?.toolbar?.button
                                // },
                                // {
                                //     name: leftToolbarButtons.reloadProductButton,
                                //     type: TOOLBARITEMTYPE.BUTTON,
                                //     text: "Restore",
                                //     leading: icons?.ReplyRoundedIcon,
                                //     onClick: (e) => handleRestoreArchive(e),
                                //     observer: componentObserver,
                                //     style: themes?.toolbar?.button
                                // },
                            ]
                        }} />
                    </div>
                </div>
            )
        })(),

        gridViewAttributes: {
            dockType: DOCKTYPE.FILL,
            fixedPane: GRIDPANE?.NONE,
            allowAutoScroll: true,
            pane: {
                left: (
                    <div className={"position-absolute top-1px start-15px bottom-0px end-15px"}>
                        <DataGrid attributes={{
                            name: productDatagridName,
                            key: "productId",
                            columns: [
                                {
                                    default: "",
                                    style: themes?.width15px
                                },
                                {
                                    type: COLUMNTYPE.CHECKBOX,
                                    value: "productId",
                                    style: themes?.width30px
                                },
                                {
                                    type: COLUMNTYPE.IMAGETHUMBNAIL,
                                    default: "",
                                    style: themes?.width35px,
                                    image: {
                                        id: "media1Id",
                                        key: "media1Id",
                                        url: "media1Url",
                                        size: themes?.size35px,
                                        leading: icons?.PhotoSizeSelectActualRoundedIcon,
                                        style: themes?.image,
                                        config: {
                                            downloadUrl: productService?.downloadUrl,
                                            size: 300
                                        }
                                    }
                                },
                                {
                                    default: "",
                                    style: themes?.width10px
                                },
                                {
                                    title: "Product",
                                    value: "productName",
                                    default: "",
                                    style: themes?.width100
                                },
                                {
                                    default: "",
                                    style: themes?.width5px
                                },
                                {
                                    type: COLUMNTYPE.TOOLBAR,
                                    items: [
                                        {
                                            name: rightToolbarButtons.previousButton,
                                            type: TOOLBARITEMTYPE.BUTTON,
                                            tooltip: "Preview",
                                            leading: icons?.SearchRoundedIcon,
                                            onClick: (e, args) => { handleEdit(e, args) },
                                            observer: componentObserver,
                                            style: themes?.toolbar?.flatIconButton
                                        },
                                    ],
                                    style: themes?.width45px
                                },
                                {
                                    default: "",
                                    style: themes?.width5px
                                },
                                {
                                    title: "Price",
                                    value: "priceAlias",
                                    default: "",
                                    style: themes?.width100px
                                },
                                {
                                    default: "",
                                    style: themes?.width10px
                                },
                                {
                                    title: "Category",
                                    value: "productCategoryName",
                                    default: "",
                                    style: themes?.width180px
                                },
                                {
                                    default: "",
                                    style: themes?.width10px
                                },
                                {
                                    title: "Brand",
                                    value: "productBrand",
                                    default: "",
                                    style: themes?.width170px
                                },
                                {
                                    default: "",
                                    style: themes?.width10px
                                },
                                {
                                    title: "Type",
                                    value: "productType",
                                    default: "",
                                    style: themes?.width120px
                                },
                                {
                                    default: "",
                                    style: themes?.width10px
                                },
                                {
                                    type: COLUMNTYPE.FLAG,
                                    default: "",
                                    style: themes?.width30px,
                                    flags: flags?.productVisibilityStatusFlags,
                                    label: "title",
                                    value: "isPublished",
                                    showLabel: false
                                },
                                {
                                    default: "",
                                    style: themes?.width10px
                                },
                                {
                                    type: COLUMNTYPE.DATETIME,
                                    title: "Created",
                                    value: "dateCreatedAlias",
                                    default: "",
                                    style: themes?.width100px
                                }
                            ],
                            isStripped: true,
                            isSelectable: true,
                            dockType: DOCKTYPE.FILL,
                            onRowDoubleClick: handleDatagridRowDoubleClick,
                            dataset: products,
                            floatingBar: {
                                page: {
                                    title: productTotalRows + " product" + ((productTotalRows <= 0) ? "" : "s") + " found",
                                    pageNumber: productPageNumber,
                                    numberOfPages: productTotalPages,
                                    rowsPerPageList: global.variables.rowsPerPageList,
                                    onPageNumberChange: handlePageNumberChange,
                                    onRowsPerPageClick: handleRowsPerPageChange,
                                    onReloadClick: handleRefresh,
                                    onHomeClick: handleFirstPage,
                                    onPreviousClick: handlePreviousPage,
                                    onNextClick: handleNextPage
                                }
                            },
                        }} showPaneOnAction={false} />
                    </div>
                ),

            }
        },

        // create or edit callout window
        productCreateOrEditCalloutWindowAttributes: {
            title: messages?.handleCreate?.dialogTitle,
            name: createOrEditProductDialogName,
            data: selectedProduct,
            observer: createOrEditProductObserver,
            widget: CreateOrEditProductView,
            slideDirection: SLIDEDIRECTION.RIGHT,
            style: {
                window: "max-width-500px"
            },
            buttons: [
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getProducts({
                                queryString: productQueryString,
                                pageNumber: productPageNumber,
                                rowsPerPage: productRowsPerPage
                            });

                            setSelectedProduct({});
                            createOrEditProductObserver.current.close();
                        });
                    }
                },
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                }
            ]
        },

        // product markets dialog
        productSheetCreateOrUploadDialogAttributes: {
            name: createOrUploadProductSheetDialogName,
            data: {},
            observer: createOrUploadProductSheetDialogObserver,
            widget: CreateOrUploadProductSheetView,
            style: {
                window: "max-width-900px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, async (args) => {
                            pageLoader.close();
                            getProducts({
                                queryString: "",
                                pageNumber: 1,
                                rowsPerPage: productRowsPerPage
                            });

                            setSelectedProduct({});
                            createOrUploadProductSheetDialogObserver.current.close();
                        });
                    }
                }
            ]
        },

        // product filter callout window
        productFilterCalloutWindowAttributes: {
            title: messages?.handleFilter?.dialogTitle,
            name: productFilterDialogName,
            data: selectedProduct,
            observer: productFilterObserver,
            widget: ProductFilterView,
            slideDirection: SLIDEDIRECTION.LEFT,
            style: {
                window: "max-width-620px"
            },
            buttons: [
                {
                    text: "Filter",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            pageLoader.close();
                            getProducts({
                                queryString: productQueryString,
                                pageNumber: productPageNumber,
                                rowsPerPage: productRowsPerPage
                            });

                            setSelectedProduct({});
                            createOrEditProductObserver.current.close();
                        });
                    }
                },
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                }
            ]
        },
    };
}

export default useProductsHook;