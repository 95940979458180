import React, { useEffect } from 'react';
import AppRouterModule from "./app.router.module"
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Set the initial language based on user preferences or browser settings
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  return (
    <div className="App">
      <AppRouterModule></AppRouterModule>
    </div>
  );
}

export default App;
