import { useHttps } from '../../../../../shared/utilities';


const MarketProductService = () => {
    const request = useHttps();
    const controller = "/market-products";

    return { 
        getMarketProducts: async (data) => await request.post(controller + "/get-all", data), 
        get: async (id) => await request.post(controller + "/get", id), 
        save: async (data) => await request.post(controller + "/save", data), 
        update: async (data) => await request.post(controller + "/update", data),
        remove: async (data) => await request.post(controller + "/delete", data)
    }
}

export { MarketProductService }