import { useHttps } from '../../../../../shared/utilities';


const CatererProductCategoryService = () => {
    const request = useHttps();
    const controller = "/caterer-product-categories";

    return { 
        getAll: async (data) => await request.post(controller + "/get-all", data), 
        getAllByCaterer: async (data) => await request.post(controller + "/get-by-caterer", data),
        get: async (id) => await request.post(controller + "/get-all", id), 
        search: async (data) => await request.post(controller + "/get-all", data),
        save: async (data) => await request.post(controller + "/save", data), 
        saveSelectedCatererProductCategory: async (data) => await request.post(controller + "/save-by-caterer", data), 
        saveSelectedCatererBulkProductCategory: async (data) => await request.post(controller + "/save-bulk-by-caterer", data), 
        update: async (data) => await request.post(controller + "/update", data),
        updateSelectedCatererProductCategory: async (data) => await request.post(controller + "/update-by-caterer", data), 
        remove: async (data) => await request.post(controller + "/delete", data),
        removeByCaterer: async (data) => await request.post(controller + "/delete-by-caterer", data),
    }
}

export { CatererProductCategoryService }